<template>
    <div>
        <h1>Create New Roster</h1>
        <p>
            As a fun tool, anyone can build a roster
            <br />
            (team managers can build and assign to games)
        </p>
        <p>
            First, tap or click to select a player.
            <br />
            Then tap or click where to assign the selected player.
        </p>

        <div class="roster-layout">
            <div class="line-up">
                <h3 @click="preview">Line Up<br/>
                    (tap for field view)
                </h3>
                

                <!-- Line Up -->

                <button type="button" class="line-up-position" v-for="i in 15" :key="i" @click="selectAndMovePlayer(i)">
                    <label :for="getPosition(i)">{{ i }}</label>
                    <input disabled style="pointer-events: none;"
                        :value="lineUp[i] ? this.formattedName(lineUp[i]) : ''" class="player-position" type="text"
                        :id="getPosition(i)" :class="{ 'selected-border': lineUp[i] && selectedPlayer === lineUp[i] }">

                </button>



                <!-- Subs -->
                <div class="subs">
                    <h3>Subs</h3>
                    <div class="subs-select" @click="movePlayerTo('subs')">
                        <div class="player-option" v-for="player in subsPlayers" :key="player._id"
                            @click="selectPlayer(player, 'subs')" :class="{
                                'player-selected': selectedSub === player,
                                'selected-border': selectedPlayer === player,
                            }">
                            {{ this.formattedName(player) }}
                        </div>
                    </div>
                </div>

                <div class="positions">
                    <div class="forwards">
                        <h3>Forwards:</h3>
                        <ol>
                            <li>Loosehead Prop</li>
                            <li>Hooker</li>
                            <li>Tighthead Prop</li>
                            <li>Lock (Second Row)</li>
                            <li>Lock (Second Row)</li>
                            <li>Blindside Flanker</li>
                            <li>Openside Flanker</li>
                            <li>Number Eight</li>
                        </ol>
                    </div>
                    <div class="backs">

                        <h3>Backs:</h3>
                        <ol start="9">
                            <li>Scrum-half</li>
                            <li>Fly-half</li>
                            <li>Left Wing</li>
                            <li>Inside Centre</li>
                            <li>Outside Centre</li>
                            <li>Right Wing</li>
                            <li>Fullback</li>
                        </ol>
                    </div>

                </div>
            </div>

            <!-- Pool -->
            <div class="player-pool">
                <h3 class="sticky-header">Player Pool</h3>
                <div class="player-pool-content" @click="movePlayerTo('pool')">
                    <div class="player-option" v-for="player in availablePlayers" :key="player._id"
                        @click="selectPlayer(player, 'pool')" :class="{
                            'player-selected': selectedPlayer === player,
                            'selected-border': selectedPlayer === player,
                        }">
                        {{ this.formattedName(player) }}
                    </div>



                </div>
            </div>

        </div>

        <button @click="save">Save to My Rosters</button>
        <button @click="clear">Clear Roster</button>
        <button @click="reset">Reset from Last Save</button>
        <button @click="preview">Field View of Roster</button>

    </div>
</template>


<script>
import { apiGet, apiPut } from '@/api';
import { displayName } from '@/common';

export default {
    data() {
        return {
            teams: [],
            selectedTeam: this.$store.state.currentMember.team,
            players: [],
            selectedPlayer: null,
            selectedLocation: null,
            lineUp: {
                1: null,
                2: null,
                3: null,
                4: null,
                5: null,
                6: null,
                7: null,
                8: null,
                9: null,
                10: null,
                11: null,
                12: null,
                13: null,
                14: null,
                15: null,
            },
            roster: {
                team: null,
                memberPool: [],
                one: null,
                two: null,
                three: null,
                four: null,
                five: null,
                six: null,
                seven: null,
                eight: null,
                nine: null,
                ten: null,
                eleven: null,
                twelve: null,
                thirteen: null,
                fourteen: null,
                fifteen: null,
                subs: [],
            },
        };
    },
    methods: {
        displayName,

        clear() {
            this.lineUp = Array(16).fill(null);
            this.roster = {
                team: null,
                memberPool: null,
                one: null,
                two: null,
                three: null,
                four: null,
                five: null,
                six: null,
                seven: null,
                eight: null,
                nine: null,
                ten: null,
                eleven: null,
                twelve: null,
                thirteen: null,
                fourteen: null,
                fifteen: null,
                subs: [],
            };
        },

        reset() {
            this.loadRoster();

        },

        async loadRoster() {
            try {
                // Check if the roster exists and has a valid structure
                const rosterData = this.$store.state.currentUser.roster;

                if (!rosterData || typeof rosterData !== 'object') {
                    // Set default values if the roster is empty or invalid
                    this.roster = {
                        team: null,
                        memberPool: [],
                        one: null,
                        two: null,
                        three: null,
                        four: null,
                        five: null,
                        six: null,
                        seven: null,
                        eight: null,
                        nine: null,
                        ten: null,
                        eleven: null,
                        twelve: null,
                        thirteen: null,
                        fourteen: null,
                        fifteen: null,
                        subs: [],
                    };
                } else {
                    // Clone the existing roster data if it's valid
                    this.roster = JSON.parse(JSON.stringify(rosterData));
                }

                // Map the roster positions to the lineup
                this.lineUp[1] = this.roster.one;
                this.lineUp[2] = this.roster.two;
                this.lineUp[3] = this.roster.three;
                this.lineUp[4] = this.roster.four;
                this.lineUp[5] = this.roster.five;
                this.lineUp[6] = this.roster.six;
                this.lineUp[7] = this.roster.seven;
                this.lineUp[8] = this.roster.eight;
                this.lineUp[9] = this.roster.nine;
                this.lineUp[10] = this.roster.ten;
                this.lineUp[11] = this.roster.eleven;
                this.lineUp[12] = this.roster.twelve;
                this.lineUp[13] = this.roster.thirteen;
                this.lineUp[14] = this.roster.fourteen;
                this.lineUp[15] = this.roster.fifteen;

            } catch (error) {
                console.error("Error loading roster:", error);
            }
        }
        ,

        async save() {
            try {
                this.$store.commit('updateCurrentUserRoster', this.roster);
                // console.log(this.roster.subs.length)
                // console.log(this.$store.state.currentUser.roster.subs.length)
                await apiPut('users/' + this.$store.state.currentUser._id, this.$store.state.currentUser);
            } catch (error) {
                console.error("Error updating user", error);
            }
        },

        async preview() {
            this.save();
            this.$router.push({ path: 'field-positions' });
        },

        formattedName(player) {

            return this.displayName(player, { lastNameFirst: false, showNickname: true, showAlias: false });
        },

        async fetchTeams() {
            const teamsResponse = await apiGet('teams');
            this.teams = teamsResponse.teams;
        },

        getPosition(number) {
            switch (number) {
                case 1: return 'one';
                case 2: return 'two';
                case 3: return 'three';
                case 4: return 'four';
                case 5: return 'five';
                case 6: return 'six';
                case 7: return 'seven';
                case 8: return 'eight';
                case 9: return 'nine';
                case 10: return 'ten';
                case 11: return 'eleven';
                case 12: return 'twelve';
                case 13: return 'thirteen';
                case 14: return 'fourteen';
                case 15: return 'fifteen';
                default: return null;
            }
        },

        async loadPlayers() {
            const members = await apiGet(`members`);
            this.players = members;     // TODO: where playerStatus = "active"
        },

        selectPlayer(player, location) {
            if (this.selectedPlayer === player) {
                this.selectedPlayer = null;
                this.selectedLocation = null;
            }
            else {
                if (!this.selectedPlayer) {
                    this.selectedPlayer = player;
                    this.selectedLocation = location;
                }
                else {
                    if (this.selectedLocation === location) {
                        this.selectedPlayer = player;
                        this.selectedLocation = location;
                    }
                }
            }

        },

        getPlayerById(id) {
            return this.players.find(player => player._id === id);
        },

        async createRoster() {
            // const roster = {
            //     name: 'some name',
            //     playerPool: this.players.map(player => player._id),
            //     lineUp: this.lineUp
            // };
            // await apiPost('rosters', roster);
            // TODO: apiPost('rosters', " + roster)
        },

        movePlayerTo(targetLocation) {
            // console.log("movePlayerTo: " + targetLocation)
            if (!this.selectedPlayer) return;
            if (this.selectedLocation === targetLocation) return;

            if (this.selectedLocation === 'pool') {
                this.players = this.players.filter(player => player !== this.selectedPlayer);
            } else if (this.selectedLocation === 'subs') {
                this.roster.subs = this.roster.subs.filter(player => player !== this.selectedPlayer);
            } else {
                this.lineUp[this.selectedLocation] = null;
                this.roster[this.getPosition(this.selectedLocation)] = null;
            }

            if (targetLocation === 'pool') {
                this.players.push(this.selectedPlayer);
            } else if (targetLocation === 'subs') {
                this.roster.subs.push(this.selectedPlayer);
            } else {
                this.lineUp[targetLocation] = this.selectedPlayer;
                this.roster[this.getPosition(targetLocation)] = this.selectedPlayer;
            }

            this.selectedPlayer = null;
            this.selectedLocation = null;
        },

        selectAndMovePlayer(i) {

            // console.log("selectAndMovePlayer " + i);
            // if there is a player selected to move...
            if (this.selectedPlayer) {

                // if there is already a player in the lineup position moved to...
                if (this.lineUp[i]) {


                    // if the player is coming from pool...
                    if (this.selectedLocation == "pool") {
                        this.players.push(this.lineUp[i]);
                        this.movePlayerTo(i);

                    }
                    // if the player is coming from subs...
                    else if (this.selectedLocation == "subs") {
                        this.roster.subs.push(this.lineUp[i]);
                        this.movePlayerTo(i);

                    }
                    // if the player is coming from another position...
                    else if (this.lineUp[this.selectedLocation]) {
                        const swapPlayer = this.lineUp[this.selectedLocation];
                        const withPlayer = this.lineUp[i];

                        this.lineUp[i] = swapPlayer;
                        this.lineUp[this.selectedLocation] = withPlayer;

                        this.roster[this.getPosition(i)] = swapPlayer;
                        this.roster[this.getPosition(this.selectedLocation)] = withPlayer;

                        this.selectedPlayer = null;
                        this.selectedLocation = null;
                    }
                }
                this.movePlayerTo(i);
            }
            else {
                if (this.lineUp[i]) {
                    this.selectPlayer(this.lineUp[i], i);
                }
            }
        },


    },
    async created() {
        await this.fetchTeams();
        await this.loadPlayers();
        await this.loadRoster();
    },

    computed: {

        availablePlayers() {
            let playersList = [];

            if (this.selectedTeam === "") {
                // playersList = this.players;
            } else {
                playersList = this.players.filter(player => {
                    // Filter by team id
                    const isSameTeam = player.team?._id === this.selectedTeam._id;

                    // Exclude players already in lineup or subs
                    const isInLineUp = Object.values(this.lineUp).some(p => p?._id === player._id);
                    const isInSubs = this.roster.subs.some(p => p?._id === player._id);

                    return isSameTeam && !isInLineUp && !isInSubs;
                });
            }

            return playersList.sort((a, b) => {
                const nameA = this.formattedName(a).toUpperCase();
                const nameB = this.formattedName(b).toUpperCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
        },

        subsPlayers() {
            return this.roster.subs;
        },
    },

    // mounted() {
    //     document.querySelectorAll('.line-up-position').forEach(el => {
    //         el.addEventListener('click', this.selectAndMovePlayer);
    //     });
    // }

};
</script>



<style scoped>
.roster-layout {
    display: flex;
    /* height: calc(100vh - 200px); */
}


.line-up,
.player-pool {
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    width: 50%;
    /* height: calc(100vh - 200px); */
    /* overflow-y: auto; */
    max-width: 300px;
}

.player-pool-content {
    height: calc(100vh - 220px);
    overflow-y: auto;
}

.line-up h3,
.player-pool h3 {
    margin-bottom: 10px;
}

.line-up-position {
    cursor: pointer;
    display: inline-block;
    align-items: center;
    gap: 15px;
}

.player-position {
    width: 170px !important;
    height: 20px !important;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.player-select {
    min-height: 490px;

}

.subs-select {
    min-height: 100px;
    /* max-height: 200px; */

}

.player-select,
.subs-select {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    overflow-y: auto;
    flex-grow: 1;
}

.player-option {
    padding: 5px;
}

.player-option:hover {
    cursor: pointer;
}


.select-team {
    align-items: center;
    text-align: center;
}

.selected-border {
    border: 2px solid blue;
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1;
    /* optional, to ensure the header is above other elements */
}
</style>
