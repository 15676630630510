<template>
  <div>
    <h1>Reset Password</h1>
    <form @submit.prevent="submitForm">
      <label for="password">New Password:</label>
      <br />
      <input type="password" id="password" v-model="password" required />
      <br />
      <label for="confirmPassword">Confirm New Password:</label>
      <br />
      <input type="password" id="confirmPassword" v-model="confirmPassword" required />
      <br />
      <button type="submit">Submit</button>
    </form>

    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex'
import { apiPut } from '@/api';

export default {
  data() {
    return {
      password: '',
      confirmPassword: '',
      message: ''
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    async submitForm() {
      if (this.password !== this.confirmPassword) {
        this.message = "Passwords do not match!";
        return;
      }

      try {
        const token = this.$route.params.token; // get the token from the URL
        let response;
        if (!token) {
          if (this.isAuthenticated) {
            const user = this.$store.state.currentUser;
            if (user) {
              const updatedUser = { ...user, password: this.password };
              response = await apiPut('users/' + user._id, updatedUser)
              if (response)
              {
                this.message = 'password updated';
              }
            }
          }
        }
        else {
          response = await axios.post(`${process.env.VUE_APP_API_URL}/api/mail/reset-password/${token}`, { password: this.password });
          this.message = response.data.message;
        }
        this.password = '';
        this.confirmPassword = '';

      } catch (error) {
        // Handle the error, display error message
        this.message = error.response.data.message || 'An error occurred.';
      }
    }
  }
};
</script>
