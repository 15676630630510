<template>
    <div v-if="isLoading">Loading...</div>
    <div v-else class="page-container">
        <div class="payment-container">
            <h1>Club Membership Dues</h1>
            <hr />

            <h3>My Current Balances:</h3>
            <div v-for="designation in dues" :key="designation._id" class="amountdue-container">
                <template v-if="calculateDueAmount(payments, designation) > 0">
                    <input type="radio" :id="designation._id" :value="designation.name" v-model="selectedOption"
                        @change="selectDuesAmount(designation, calculateDueAmount(payments, designation))">
                    <label :for="designation._id">
                        ${{ this.calculateDueAmount(payments, designation) }} : {{ designation.name }}
                    </label>
                </template>
            </div>
            <hr />

            <h3>Submit Payment:</h3>
            <div class="pay">
                <div class="line-container">
                    <input id="amount-input" type="number" step="1.00" min="0" v-model="enteredAmount"
                        placeholder="enter $ amount" class="text-input" />
                </div>

                <div class="line-container">
                    <select id="designation-select" v-model="selectedDesignation">
                        <option value="" selected disabled>what are you paying?</option>
                        <option v-for="designation in designations" :key="designation._id" :value="designation._id">
                            {{ designation.name }}
                        </option>
                    </select>
                </div>

                <div class="line-container">
                    <textarea id="note-input" v-model="enteredNote" placeholder="Add a note (optional)"
                        class="textarea-input" rows="3"></textarea>
                </div>

                <div class="line-container">
                    <button class="pay-btn" @click="payWithVenmo">
                        <img class="pay-btn-img" :src="venmobutton" alt="Pay with Venmo" />
                    </button>
                </div>
            </div>
            <hr />
        </div>
    </div>
</template>

<script>
import { apiGet } from '@/api';
import store from '../store';
import venmobutton from '@/../images/venmo.png';
import { calculateDueAmount } from '@/common';

export default {
    data() {
        return {
            isLoading: true,
            enteredAmount: null,
            enteredNote: null,
            selectedDesignation: "",
            selectedMember: null,
            designations: [],
            venmobutton: venmobutton,
            payments: [],
            selectedOption: null,
            dues: []
        }
    },
    async created() {
        try {
            const designationsResponse = await apiGet('paymentDesignations');
            this.designations = designationsResponse;
            this.dues = designationsResponse.filter(designation => designation.type === 'dues');
            await this.fetchPayments(this.$store.state.currentMember._id);
            this.isLoading = false;
        } catch (error) {
            console.error("Error fetching data", error);
        }
    },
    methods: {
        selectDuesAmount(designation, amount) {
            this.selectedDesignation = designation._id;
            this.enteredAmount = amount;
        },
        calculateDueAmount,
        generateVenmoUrl() {
            const baseVenmoUrl = 'https://venmo.com/severn-river';
            const amount = this.enteredAmount;
            const selectedDesignationObject = this.designations.find(d => d._id === this.selectedDesignation);
            let note = store.state.currentMember.firstName + " " + store.state.currentMember.lastName + " - " + (selectedDesignationObject ? selectedDesignationObject.name : '');

            if (this.enteredNote) {
                note += " - " + this.enteredNote;
            }

            note = encodeURIComponent(note).replace(/%20/g, ' ');

            return `${baseVenmoUrl}?txn=pay&amount=${amount}&note=${note}`;
        },
        async payWithVenmo() {
            const venmoUrl = this.generateVenmoUrl();
            window.location.href = venmoUrl; 
        },
        async fetchPayments(memberId) {
            try {
                const data = await apiGet(`payments/member/${memberId}`);
                if (data.payments) {
                    this.payments = data.payments;
                } else {
                    console.error('Error fetching payments:', data.message);
                }
            } catch (error) {
                console.error('Error fetching payments:', error);
            }
        },
    }
}
</script>

<style>
.amountdue-container {
    margin-bottom: 40px;
}

.line-container {
    margin-bottom: 10px;
}

.textarea-input {
    width: 200px;
}

.text-input {
    width: 200px;
    text-align: center;
}

.pay-btn {
    width: 200px;
    height: 60px;
}

.pay-btn-img {
    width: 156px;
}

#designation-select option {
    text-align: center;
}

#designation-select option:disabled {
    color: gray;
}

.text-input::placeholder {
    text-align: center;
}

#designation-select option:first-child {
    color: gray;
}

.page-container {
    align-items: center;
}

.payment-container {
    text-align: center;
}
</style>
