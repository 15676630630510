<template>
  <div id="register-member">
    <h1>Register Member</h1>

    <div class="form-container">
      <form @submit.prevent="submitForm">
        <table style="margin: 0 auto;">
          <tr v-if="isAuthenticated">
            <td>Notify to login?</td>
            <td>
              <input type="checkbox" id="createUser" v-model="createUser" /> (notified via email)
            </td>
          </tr>
          <tr v-if="isAuthenticated">
            <td>Team *</td>
            <td>
              <select v-model="selectedTeam" required>
                <option disabled value="">select a team</option>
                <option v-for="team in teams" :key="team._id" :value="team._id">{{ team.name }}</option>
              </select>
            </td>
          </tr>
          <tr v-if="!isAuthenticated">
            <td>Team *</td>
            <td>
              <select v-model="requestedTeam" required>
                <option disabled value="">select a team</option>
                <option value="646e2f335eebe6170c89b54c">Men's</option>
                <option value="646e2f335eebe6170c89b54d">Honey Badgers</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Email *</td>
            <td><input type="email" v-model="emailValue" placeholder="Enter email address" required /></td>
          </tr>
          <tr>
            <td>First name *</td>
            <td><input type="text" v-model="firstNameValue" placeholder="First name" required /></td>
          </tr>
          <tr>
            <td>Last name *</td>
            <td><input type="text" v-model="lastNameValue" placeholder="Last name" required /></td>
          </tr>
          <tr v-if="!isAuthenticated">
            <td>Password *</td>
            <td>
              <div class="password-container">
                <input ref="passwordInput" :type="passwordVisible ? 'text' : 'password'" v-model="passwordValue"
                  placeholder="Set password" @keydown.enter="attemptLogin" />
                <img v-if="!passwordVisible" :src="showPasswordImage" class="show-password-toggle"
                  @click="passwordVisible = !passwordVisible" />
                <img v-else :src="hidePasswordImage" class="show-password-toggle"
                  @click="passwordVisible = !passwordVisible" />
              </div>
            </td>
          </tr>
          <tr v-if="isAuthenticated">
            <td>Membership Status *</td>
            <td>
              <select v-model="selectedStatus" required>
                <option disabled value="">membership status</option>
                <option>good standing</option>
                <option>delinquent</option>
                <option>sanctioned</option>
                <option>inactive</option>
              </select>
            </td>
          </tr>
          <tr v-if="isAuthenticated">
            <td>Player Status *</td>
            <td>
              <select v-model="selectedPlayerStatus" required>
                <option disabled value="">player status</option>
                <option>active</option>
                <option>ir</option>
                <option>inactive</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Birthdate *</td>
            <td>
              <input type="date" v-model="birthDateValue" />
            </td>
          </tr>
          <tr>
            <td>Phone Number *</td>
            <td><input type="text" v-model="phoneNumberValue" placeholder="Phone Number" required /></td>
          </tr>
          <tr>
            <td>Nickname</td>
            <td><input type="text" v-model="nicknameValue" placeholder="Nickname" /></td>
          </tr>
          <tr>
            <td>Alias</td>
            <td><input type="text" v-model="aliasValue" placeholder="Alias" /></td>
          </tr>
          <tr>
            <td>CIPP</td>
            <td><input type="text" v-model="cippValue" placeholder="CIPP" /></td>
          </tr>
          <tr>
            <td>Pronoun</td>
            <td>
              <select v-model="pronounValue">
                <option disabled value="">Please select one</option>
                <option value="None">None</option>
                <option value="She/Her">She/Her</option>
                <option value="He/Him">He/Him</option>
                <option value="They/Them">They/Them</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Notes</td>
            <td><textarea v-model="notesValue" placeholder="Notes"></textarea></td>
          </tr>
          <tr v-if="!isAuthenticated">
            <td></td>
            <td>
              <div class="terms">
                <input type="checkbox" v-model="termsAccepted" id="terms">
                <label for="terms">I accept the </label><a :href="`${webUrl}/terms-conditions`" target="_blank"
                  rel="noopener noreferrer">Terms and Conditions</a>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td><button type="submit">Register</button></td>
          </tr>

          <tr>
            <td></td>
            <td>
              <p class="success-message">{{ successMessage }}</p>
              <p class="info-message" v-if="infoMessage">{{ infoMessage }}</p>
              <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
            </td>
          </tr>

          <tr>
            <td></td>
            <td><button v-if="!isAuthenticated" @click="goToLoginPage">Back To Sign In</button></td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { apiGet, apiPost } from '@/api';
import store from '../store';
import showPasswordImage from '@/../images/icons/show.svg';
import hidePasswordImage from '@/../images/icons/hide.svg';
const currentTermsVersion = process.env.VUE_APP_CURRENT_TERMS;

export default {
  data() {
    return {
      selectedTeam: "",
      requestedTeam: "",
      emailValue: "",
      firstNameValue: "",
      lastNameValue: "",
      aliasValue: "",
      passwordValue: "",
      birthDateValue: "",
      cippValue: "",
      phoneNumberValue: "",
      notesValue: "",
      pronounValue: "",
      user: null,
      teams: [],
      selectedPlayerStatus: "",
      selectedStatus: "",
      selectedMembership: "",
      successMessage: null,
      infoMessage: null,
      errorMessage: null,
      createUser: true,
      passwordVisible: false,
      showPasswordImage: showPasswordImage,
      hidePasswordImage: hidePasswordImage,
      termsAccepted: false,
    };
  },
  async mounted() {
    const emailParam = this.$route.query.email;

    if (emailParam) {
      this.emailValue = emailParam;
    }

  },
  methods: {
    async showMessage(message, messageType = "info") {
      if (messageType == "success") {
        this.successMessage = message;
        this.infoMessage = "";
        this.errorMessage = "";
      }
      if (messageType == "info") {
        this.successMessage = "";
        this.infoMessage = message;
        this.errorMessage = "";
      }
      if (messageType == "error") {
        this.successMessage = "";
        this.infoMessage = "";
        this.errorMessage = message;
      }

    },

    async submitForm() {
      this.clearMessages();
      const userData = await apiPost(`auth/check-user`, { email: this.emailValue });
      const memberData = await apiPost(`auth/check-member`, { email: this.emailValue });

      if (memberData.exists) {
        this.showMessage('A member with this email already exists.', 'error');
        return;
      }

      if (userData.exists) {
        this.showMessage('A user with this email already exists.', 'error');
        return;
      }

      if (this.isAuthenticated) {
        await this.handleAuthenticatedUser();
      } else {
        await this.handleNonAuthenticatedUser();
      }
    },

    // async registerUser(member) {
    //   if (!this.termsAccepted) {
    //     this.showMessage("You must accept the Terms and Conditions.", "error");
    //     return;
    //   }

    //   const registerUserResponse = await apiPost('auth/register-user', {
    //     club: this.user.club,
    //     member,
    //     email: this.emailValue,
    //     password: this.passwordValue,
    //     status: "pending",
    //     termsAcceptedVersion: this.termsAcceptedVersion,
    //     termsAcceptedDate: this.termsAcceptedDate,
    //   });

    //   if (registerUserResponse.success) {
    //     this.$router.push({ name: 'LoginPage', query: { customMessage: 'User and Member Registration successful. You will be notified via email when your account is reviewed and activated.' } });
    //   }
    //   else {
    //     console.error("Error registering user:", registerUserResponse.message);
    //     this.showMessage("Error registering user.", "error")
    //   }
    // },

    emailValueChanged(newEmail) {
      if (newEmail !== this.emailValue) {
        this.clearMessages();
      }
      this.emailValue = newEmail;
    },

    clearMessages() {
      this.successMessage = null;
      this.infoMessage = null;
      this.errorMessage = null;
    },

    async handleAuthenticatedUser() {
      
      const newMemberData = {
        club: store.state.currentClubId,
        team: this.selectedTeam,
        email: this.emailValue,
        firstName: this.firstNameValue,
        lastName: this.lastNameValue,
        birthDate: this.birthDateValue,
        phoneNumber: this.phoneNumberValue,
        nickname: this.nicknameValue,
        alias: this.aliasValue,
        cipp: this.cippValue,
        pronoun: this.pronounValue,
        notes: this.notesValue,
        registeredBy: store.state.currentUser._id,
        status: this.status,
        membership: this.membership,
        playerStatus: this.playerStatus,
      };

      // update member
      const createMemberResponse = await apiPost('members', newMemberData);
      if (!createMemberResponse.success) {
        throw new Error(createMemberResponse.message);
      }

      this.showMessage('Registration successful.', 'success');

      // email member
      const mailResponse = await apiPost(`mail/register-member`, { email: this.emailValue });
      if (mailResponse.success) {
        this.showMessage(this.successMessage + '  ' + mailResponse.message, 'success');
      }
      if (!mailResponse.success) {
        this.errorMessage(mailResponse.message, 'error');
      }

      this.refreshForm();
    },

    async handleNonAuthenticatedUser() {
      if (!this.termsAccepted) {
        this.showMessage("You must accept the Terms and Conditions.", "error");
        return;
      }

      const newMemberData = {
        clubId: process.env.VUE_APP_CLUB_ID,
        requestedTeam: this.requestedTeam,
        email: this.emailValue,
        firstName: this.firstNameValue,
        lastName: this.lastNameValue,
        phoneNumber: this.phoneNumberValue,
        nickname: this.nicknameValue,
        alias: this.aliasValue,
        cipp: this.cippValue,
        pronoun: this.pronounValue,
        notes: this.notesValue,
        birthDate: this.birthDateValue
      };

      const createMemberResponse = await apiPost('auth/register-member', newMemberData);

      if (!createMemberResponse.success) {
        throw new Error(createMemberResponse.message);
      }

      const newMember = createMemberResponse.member;
      const registerUserResponse = await apiPost('auth/register-user', {
        memberId: newMember._id,
        email: this.emailValue,
        password: this.passwordValue,
        notes: this.notesValue,
        termsAcceptedVersion: this.termsAcceptedVersion,
        termsAcceptedDate: this.termsAcceptedDate,
      });

      if (!registerUserResponse.success) {
        throw new Error(registerUserResponse.message);
      }
      this.showMessage('Registration successful. This account is pending review.', 'success');

      // email admin
      await apiPost(`mail/email-support`, { subject: `pending registration`, message: `There is a new pending user registration request for ${this.emailValue}. ${this.notesValue}` });

      this.refreshForm();
    },


    async refreshForm() {
      this.emailValue = null
      this.firstNameValue = null
      this.lastNameValue = null
      this.birthDateValue = null
      this.phoneNumberValue = null
      this.nicknameValue = null
      this.aliasValue = null
      this.cippValue = null
      this.pronounValue = null
      this.notesValue = null
    },

    goToLoginPage() {
      this.$router.push('/login-page');
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isAuthorized']),
    webUrl() {
      return process.env.VUE_APP_WEB_URL;
    },
    termsAcceptedVersion() {
      return this.termsAccepted ? currentTermsVersion : null;
    },
    termsAcceptedDate() {
      return this.termsAccepted ? new Date() : null;
    },
  },
  async created() {
    if (this.isAuthenticated) {
      const teamsResponse = await apiGet('teams');
      this.teams = teamsResponse.teams;
    }
  }
};
</script>

<style>
.form-container {
  margin: 0 auto;
  padding: 20px;
}

.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
  width: 200px;
}

.success-message {
  color: green;
  text-align: center;
  margin-bottom: 20px;
  width: 200px;
}

.info-message {
  color: black;
  text-align: center;
  margin-bottom: 20px;
  width: 200px;
}

.show-password-toggle {
  cursor: pointer;
  height: 20px;
  vertical-align: middle;
  margin-left: -20px;
}

/* .select-roles {
  height:auto
} */
</style>