<template>
  <div id="log-payments">
    <h1>Log Payments</h1>
    <form @submit.prevent="submitPayments">
      <div class="payment-form">
        <table class="payment-table responsive-table">
          <tbody>
            <tr v-for="(payment, index) in payments" :key="index">
              <td>
                <div class="dropdown">
                  <input type="text" v-model="payment.member" @input="searchMembers(index)" autocomplete="off"
                    placeholder="select member" :class="{ 'missing-field': formSubmitted && !payment.member }" />
                  <div class="dropdown-content" v-if="payment.filteredMembers.length > 0">
                    <div v-for="member in payment.filteredMembers" :key="member._id" @click="selectMember(index, member)">
                      {{ member.lastName }}, {{ member.firstName }}<span v-if="member.alias"> ({{ member.alias }})</span>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <input type="date" v-model="payment.date" />
              </td>
              <td>
                <input class="amount-input" type="number" step="1.00" v-model="payment.amount" placeholder="enter amount"
                  :class="{ 'missing-field': formSubmitted && !payment.amount }" />
              </td>
              <td>
                <select v-model="payment.source" :class="{ 'missing-field': !payment.source }">
                  <option disabled value="">Select Source</option>
                  <option value="Venmo">Venmo</option>
                  <option value="Paypal">Paypal</option>
                  <option value="Cash">Cash</option>
                  <option value="Adjustment">Adjustment</option>
                </select>
              </td>
              <td>
                <select v-model="payment.designation" :class="{ 'missing-field': formSubmitted && !payment.designation }">
                  <option value="" disabled>select designation</option>
                  <option v-for="designation in paymentDesignations" :key="designation._id" :value="designation._id">
                    {{ designation.name }}
                  </option>
                </select>
              </td>
              <td>
                <input v-model="payment.notes" placeholder="Notes (required)" v-if="payment.source === 'Adjustment'"
                  :class="{ 'missing-field': payment.source === 'Adjustment' && formSubmitted && !payment.notes }" />
                <input v-model="payment.notes" placeholder="Notes (optional)" v-else />
              </td>
              <td>
                <input v-model="payment.transaction" placeholder="transaction no. (recommended)" />
              </td>
              <td class="addButton">
                <button type="button" @click="addPayment">add more</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="submit-area">
        <button type="submit">Submit payment(s)</button><br />
        <div class="success-message" v-if="successMessage">{{ successMessage }}</div>
        <div class="info-message" v-if="infoMessage">{{ infoMessage }}</div>
        <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
      </div>

    </form>

  </div>
</template>

<script>
import { apiGet, apiPost } from '@/api';

export default {
  data() {
    return {
      members: [],
      payments: [
        {
          member: "",
          memberId: null,
          filteredMembers: [],
          date: this.getCurrentDate(),
          amount: null,
          source: "Venmo",
          designation: "",
          notes: "",
          transaction: "",
          dateEntered: this.getCurrentDate(),
          enteredBy: this.$store.state.currentUser._id,
        },
      ],
      paymentDesignations: [],
      formSubmitted: false,
      successMessage: null,
      infoMessage: null,
      errorMessage: null,
    };
  },
  methods: {
    async fetchMembers() {
      try {
        const response = await apiGet('members');
        if (response) {
          this.members = response
            .sort((a, b) => a.lastName.localeCompare(b.lastName)); // Sort members by last name
        } else {
          console.error("Error fetching members:", response.message);
        }
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    },
    async fetchPaymentDesignations() {
      try {
        const response = await apiGet('paymentDesignations', { status: "active" });
        if (response) {
          this.paymentDesignations = response
            .filter(designation => designation.status === 'active')
            .sort((a, b) => {
              return new Date(b.date) - new Date(a.date);
            });
        } else {
          console.error("Error fetching payment designations:", response.message);
        }
      } catch (error) {
        console.error("Error fetching payment designations:", error);
      }
    },
    searchMembers(index) {
      const searchTerm = this.payments[index].member.toLowerCase();
      // If the input is empty, clear the filteredMembers
      if (searchTerm === "") {
        this.payments[index].filteredMembers = [];
        return; // exit the function early
      }
      this.payments[index].filteredMembers = this.members.filter(
        (member) =>
        (member.lastName.toLowerCase().includes(searchTerm) ||
          member.firstName.toLowerCase().includes(searchTerm) ||
          (member.alias && member.alias.toLowerCase().includes(searchTerm)))
      );
    },
    addPayment() {
      this.payments.push({
        member: "",
        memberId: "",
        filteredMembers: [],
        date: this.getCurrentDate(),
        amount: null,
        source: "Venmo",
        designation: "",
        notes: "",
        transaction: "",
        dateEntered: "",
        enteredBy: this.$store.state.currentUser._id,
      });
    },
    async submitPayments() {
      this.formSubmitted = true;

      const paymentData = [];

      for (const payment of this.payments) {

        if (!payment.memberId || !payment.amount || !payment.designation ||
          !payment.source) {
          this.errorMessage = "Please fill in all required fields.";
          return;
        }

        paymentData.push({
          member: payment.memberId,
          date: payment.date,
          amount: payment.amount,
          source: payment.source,
          designation: payment.designation,
          notes: payment.notes,
          transaction: payment.transaction,
          dateEntered: payment.dateEntered,
          enteredBy: payment.enteredBy,
        });
      }

      try {
        await apiPost('payments', { paymentData });
        this.errorMessage = "";
        this.successMessage = "Payment(s) logged successfully.";
      }
      catch (error) {
        this.errorMessage = "Error logging payments. Refresh data and check payment activity to see what was logged.";
        this.successMessage = "";
      }

      this.payments = [
        {
          member: "",
          memberId: null,
          filteredMembers: [],
          date: "",
          amount: 0,
          source: "Venmo",
          designation: "",
          notes: "",
          transaction: "",
          dateEntered: null,
          enteredBy: this.$store.state.currentUser._id,
        },
      ];
    },
    selectMember(index, member) {
      this.payments[index].member = `${member.lastName}, ${member.firstName}${member.alias ? ' (' + member.alias + ')' : ''}`;
      this.payments[index].memberId = member._id;
      this.payments[index].filteredMembers = [];
    },
    getCurrentDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const MM = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();

      return yyyy + '-' + MM + '-' + dd;
    },
  },
  created() {
    this.fetchMembers();
    this.fetchPaymentDesignations();
  },
  watch: {
    payments: {
      handler: function () {
        this.formSubmitted = false;
        this.errorMessage = "";
      },
      deep: true,
    },
  },
};
</script>

<style scoped>  .payment-table {
    width: auto;
    margin: 0 auto;
    border-collapse: collapse;
  }

  .amount-input {
    width: 100px;
  }

  .payment-table th,
  .payment-table td {
    padding: 4px;
    text-align: center;
  }

  .payment-table th {
    font-size: 1em;
    font-weight: bold;
    color: #000;
  }

  .payment-form {
    position: relative;
    margin: auto;
  }

  input,
  select,
  button {
    min-width: 200px;
  }

  .amount-input input {
    width: 200px;
    text-align: left;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown input {
    width: 200px;
  }

  .dropdown-content {
    display: block;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    background-color: #f6f6f6;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }

  .dropdown-content div {
    padding: 12px 16px;
    font-size: 0.8em;
    white-space: nowrap;
    text-align: left;
  }

  .dropdown-content div:hover {
    background-color: #f1f1f1;
  }

  .submit-area {
    position: sticky;
    width: 100%;
    height: 50px;
    text-align: center;
    align-items: center;
  }

  .responsive-table,
  .responsive-table thead,
  .responsive-table tbody,
  .responsive-table th,
  .responsive-table td,
  .responsive-table tr {
    display: block;
  }

  .responsive-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .responsive-table tr {
    margin: 0 0 1rem 0;
  }

  .responsive-table td {
    border: none;
    position: relative;
  }

  .responsive-table td:before {
    font-size: 1em;
    font-weight: bold;
    color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 48%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-label);
    text-align: right;
  }

  .error-message {
    display: contents;
    color: red;

  }

  .success-message {
    display: contents;
    color: green;
  }

  .info-message {
    display: contents;
    color: black;
  }

  .missing-field {
    border-color: red;
  }
</style>
