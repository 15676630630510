<template>
    <div v-if="loading">Loading...</div>

    <div v-else id="register-member">
        <h1>Edit Profile</h1>

        <div class="form-container">
            <form @submit.prevent="submitForm">
                <table style="margin: 0 auto;">
                    <!-- <tr>
                        <td>Team *</td>
                        <td>
                            <select v-model="member.team" required>
                                <option disabled value="">select a team</option>
                                <option v-for="team in teams" :key="team._id" :value="team">{{ team.name }}</option>
                            </select>
                        </td>
                    </tr> -->
                    <tr>
                        <td>Email *</td>
                        <td><input type="email" v-model="member.email" placeholder="enter email address" required />
                        </td>
                    </tr>
                    <tr>
                        <td>First name *</td>
                        <td><input type="text" v-model="member.firstName" placeholder="enter first name" required /></td>
                    </tr>
                    <tr>
                        <td>Last name *</td>
                        <td><input type="text" v-model="member.lastName" placeholder="enter last name" required /></td>
                    </tr>
                    <tr>
                        <td>Club name</td>
                        <td><input type="text" v-model="member.nickname" placeholder='"pass it here ---- !"' /></td>
                    </tr>
                    <tr v-if="isAuthorized(['MANAGE_MEMBERS'])">
                        <td>Membership Status*</td>
                        <td>
                            <select v-model="member.status" required>
                                <option disabled value="">membership status</option>
                                <option>good standing</option>
                                <!-- <option>delinquent</option> -->
                                <option>sanctioned</option>
                                <option>inactive</option>
                            </select>
                        </td>
                        <!-- tooltip text: 
                            only club managers have access to a member's status.  
                            see bylaws for definition 
                                           <dt>Delinquent / Sanctioned Member</dt>
                            <dd>Overdue on payments or has been sanctioned. Cannot vote, hold office, or represent the club
                                in matches.</dd>         
                        
                        
                        -->


                    </tr>
                    <tr v-if="!isCippExpiredOrEmpty">
                        <td>Player Status*</td>
                        <td>
                            <select v-model="member.playerStatus" required>
                                <option disabled value="">player status</option>
                                <option>active</option>
                                <option>ir</option>
                                <option>inactive</option>
                            </select>
                        </td>


                    </tr>
                    <tr v-else>
                        <td>Player Status</td>
                        <td class="no-cipp">ineligable (CIPP)</td>
                    </tr>
                    <tr>
                        <td>Birthdate *</td>
                        <td>
                            <input type="date" v-model="memberBirthDate" />
                        </td>
                    </tr>
                    <tr>
                        <td>Phone Number *</td>
                        <td><input type="text" v-model="member.phoneNumber" placeholder="Phone Number" required /></td>
                    </tr>


                    <tr>
                        <td>CIPP</td>
                        <td class="cipp-number">
                            <input type="text" v-model="member.cipp" placeholder="CIPP"
                                :class="{ 'error-border': isCippEmpty }" />
                        </td>
                    </tr>

                    <tr>
                        <td>CIPP Expiry</td>
                        <td class="cipp-expiry">
                            <input type="date" v-model="memberCippExpiration"
                                :class="{ 'error-border': isCippExpiredOrEmpty }" />
                        </td>
                    </tr>

                    <tr>
                        <td>Pronoun</td>
                        <td>
                            <select v-model="member.pronoun">
                                <option disabled value="">Please select one</option>
                                <option value="None">None</option>
                                <option value="She/Her">She/Her</option>
                                <option value="He/Him">He/Him</option>
                                <option value="They/Them">They/Them</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Notes</td>
                        <td><textarea v-model="member.notes" placeholder="Notes"></textarea></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><button type="submit">Save</button></td>
                    </tr>


                    <tr>
                        <td></td>
                        <td>
                            <p class="success-message">{{ successMessage }}</p>
                            <p class="info-message" v-if="infoMessage">{{ infoMessage }}</p>
                            <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { getMemberStatus } from '@/common';

import { apiGet, apiPost, apiPut } from '@/api';
//   import store from '../store';

export default {
    data() {
        return {
            memberBirthDate: null,
            memberCippExpiration: null,

            // teams: [],
            selectedStatus: "",
            selectedMembership: "",

            successMessage: null,
            infoMessage: null,
            errorMessage: null,

            loading: true,

            memberId: null,
            selectedTeam: "",

            originalEmail: '',

            memberStatus: '',

            member: {
                email: '',
                firstName: '',
                lastName: '',
                nickname: '',
                birthDate: '',
                phoneNumber: '',
                cipp: '',
                pronoun: '',
                notes: '',
            },

        };
    },
    methods: {
        getMemberStatus,

        async showMessage(message, messageType = "info") {
            if (messageType == "success") {
                this.successMessage = message;
                this.infoMessage = "";
                this.errorMessage = "";
            }
            if (messageType == "info") {
                this.successMessage = "";
                this.infoMessage = message;
                this.errorMessage = "";
            }
            if (messageType == "error") {
                this.successMessage = "";
                this.infoMessage = "";
                this.errorMessage = message;
            }

        },

        async submitForm() {
            this.clearMessages();

            // Update member object with the CIPP expiration date and birthdate before saving
            this.member.cippExpiration = this.memberCippExpiration;
            this.member.birthDate = this.memberBirthDate;


            if (this.member.email !== this.originalEmail) {
                const memberData = await apiPost(`auth/check-member`, { email: this.member.email });

                if (memberData.exists) {
                    this.showMessage('A member with this email already exists.', 'error');
                    return;
                }
            }

            try {
                await apiPut(`members/${this.member._id}`, this.member);
                this.showMessage("Member updated successfully", "success");
            }
            catch (error) {
                this.showMessage("An error occured", "error");

            }
        },

        clearMessages() {
            this.successMessage = null;
            this.infoMessage = null;
            this.errorMessage = null;
        },

        async loadMember() {

            this.memberId = this.$route.params.memberId || this.$store.state.currentMember._id;

            // const teamsResponse = await apiGet('teams');
            // this.teams = teamsResponse.teams;

            const memberResponse = await apiGet('members/' + this.memberId)
            this.member = memberResponse;
            this.memberBirthDate = this.$formatDate(this.member.birthDate, "yyyy-MM-dd");
            this.memberCippExpiration = this.$formatDate(this.member.cippExpiration, "yyyy-MM-dd");

            this.originalEmail = this.member.email;

            this.loading = false;
        }

    },
    computed:
    {
        ...mapGetters(['isAuthenticated', 'isAuthorized']),
        isCippExpiredOrEmpty() {
            // Check if CIPP is expired
            const today = new Date();
            const expiryDate = new Date(this.memberCippExpiration);

            // Compare today's date to expiry date and check if CIPP or expiry is empty
            return (today > expiryDate || !this.member.cipp || !this.memberCippExpiration);
        },
        isCippEmpty() {
            // Check if CIPP is empty
            return !this.member.cipp;
        },
        isCippExpired() {
            // Check if CIPP is expired
            const today = new Date();
            const expiryDate = new Date(this.memberCippExpiration);
            return today > expiryDate;
        },

    },
    async created() {
        this.self = this.memberId === this.$store.state.currentMember._id;

        await this.loadMember();

        this.memberStatus = await this.getMemberStatus(this.member)

    }
};
</script>

<style>
.form-container {
    margin: 0 auto;
    padding: 20px;
}

.error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    width: 200px;
}

.success-message {
    color: green;
    text-align: center;
    margin-bottom: 20px;
    width: 200px;
}

.info-message {
    text-align: center;
    margin-bottom: 20px;
    width: 200px;
}

.show-password-toggle {
    cursor: pointer;
    height: 20px;
    vertical-align: middle;
    margin-left: -20px;
}

.member-status {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.no-cipp {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: red;
}


.error-border {
    border: 1px solid red;
}

/* .select-roles {
    height:auto
  } */
</style>