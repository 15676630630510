<template>
    <div v-if="isLoading">Loading...</div>
    <div v-else class="page-container">
        <h1>Manage Payment Designations</h1>
        <hr />

        <div class="line-container">
            <select id="designation-select" v-model="selectedType" @change="addNewPD">
                <option disabled value="" selected>Add New</option>
                <option value="dues">Dues</option>
                <option value="fundraiser">Fundraiser</option>
                <option value="donations">Donations</option>
                <option value="trip">Trip</option>
            </select>
        <!-- </div>
        <hr />

        <div class="line-container"> -->
            <select id="designation-select" v-model="selectedDesignation" @change="populateFields">
                <option disabled value="" selected>Edit Existing</option>
                <option v-for="pd in paymentDesignations" :key="pd._id" :value="pd._id">
                    {{ pd.name }}
                </option>
            </select>
        </div>

        <div v-if="selectedDesignationObject">
            <hr />
            <table v-if="isType('dues')">
                <tr>
                    <td>Unique Name</td>
                    <td><input id="name-input" type="text" v-model="selectedDesignationObject.name" placeholder="Name"
                            class="text-input" autocomplete="off" /></td>
                </tr>
                <tr>
                    <td>Season</td>
                    <td><input id="season-input" type="text" v-model="selectedDesignationObject.season" placeholder="Season"
                            class="text-input" autocomplete="off" /></td>
                </tr>
                <tr>
                    <td>Amount</td>
                    <td><input id="full-amount-input" type="number" v-model="selectedDesignationObject.amount"
                            placeholder="Full season amount" class="text-input" autocomplete="off" /></td>
                </tr>
                <tr>
                    <td>Deadline</td>
                    <td><input id="deadline-input" type="date" v-model="selectedDesignationObject.deadline"
                            placeholder="Deadline" class="text-input" autocomplete="off" /></td>
                </tr>

            </table>

            <table v-if="isType('fundraiser')">
                <tr>
                    <td>Unique Name</td>
                    <td><input id="name-input" type="text" v-model="selectedDesignationObject.name" placeholder="Name"
                            class="text-input" autocomplete="off" /></td>
                </tr>
                <tr>
                    <td>Fundraiser Date</td>
                    <td><input id="date-input" type="date" v-model="selectedDesignationObject.fundraisingDate"
                            placeholder="Fundraising date" class="text-input" autocomplete="off" /></td>
                </tr>
            </table>

            <table v-if="isType('donations')">
                <tr>
                    <td>Unique Name</td>
                    <td><input id="name-input" type="text" v-model="selectedDesignationObject.name" placeholder="Name"
                            class="text-input" autocomplete="off" /></td>
                </tr>
            </table>

            <table v-if="isType('trip')">
                <tr>
                    <td>Unique Name</td>
                    <td><input id="name-input" type="text" v-model="selectedDesignationObject.name" placeholder="Name"
                            class="text-input" autocomplete="off" /></td>
                </tr>
                <tr>
                    <td>Trip Amount</td>
                    <td><input id="trip-amount-input" type="number" v-model="selectedDesignationObject.tripAmount"
                            placeholder="Trip amount" class="text-input" autocomplete="off" /></td>
                </tr>
            </table>

            <div>
                <input type="radio" id="active" value="active" v-model="selectedDesignationObject.status">
                <label for="active">Active</label>
                <input type="radio" id="inactive" value="inactive" v-model="selectedDesignationObject.status">
                <label for="inactive">Inactive</label>
            </div>

            <button class="save-btn" @click="save">Save</button>
        </div>

        <div class="line-container">
            <div v-if="messageInfo" class="message-info">{{ this.messageInfo }}</div>
            <div v-if="messageError" class="message-error">{{ this.messageError }}</div>
        </div>
        <hr />

        <div class="definition">
            <b>What is a payment designation?</b>
            <p>A Payment Designation is a label for classifying incoming payments in an organization.</p>
            <p>Types include: dues, fundraisers, donations, trips - with each having specific attributes.</p>
            <p>Designations help in record-keeping and reporting and are named clearly for easy understanding (e.g., '2024 Club Dues'). </p>
            <p>Designations cannot be deleted if payments are assigned but can be deactivated to stop further allocation of payments.</p>
        </div>
        <hr />
    </div>
</template>

<script>
import { apiGet, apiPut, apiPost } from '@/api';

export default {
    data() {
        return {
            isLoading: true,
            paymentDesignations: [],
            selectedDesignation: "",
            selectedDesignationObject: null,
            messageError: "",
            messageInfo: "",
            isNewPaymentDesignation: false,
            selectedType: "",
        };
    },
    async created() {
        try {
            const paymentDesignations = await apiGet('paymentDesignations');
            this.paymentDesignations = paymentDesignations;
            this.isLoading = false;
        } catch (error) {
            console.error('Error fetching data', error);
        }
    },
    methods: {
        async populateFields() {
            let selectedDesignationObject = this.paymentDesignations.find(
                (pd) => pd._id === this.selectedDesignation
            );

            // format dates
            if (selectedDesignationObject.deadline) {
                selectedDesignationObject.deadline = this.$formatDate(selectedDesignationObject.deadline, "yyyy-MM-dd");
            }

            if (selectedDesignationObject.fundraisingDate) {
                selectedDesignationObject.fundraisingDate = this.$formatDate(selectedDesignationObject.fundraisingDate, "yyyy-MM-dd");
            }

            this.selectedDesignationObject = selectedDesignationObject;
        },
        isType(type) {
            return this.selectedDesignationObject.type === type;
        },
        async save() {

            if (this.selectedDesignationObject._id) {
                this.savePD();
            }
            else {
                this.saveNewPD();
            }
            this.$store.commit('clearCache');

        },
        async savePD() {
            try {
                this.selectedDesignationObject.dateUpdated = new Date();
                this.selectedDesignationObject.updatedBy = this.$store.state.currentUser._id;

                await apiPut(`paymentDesignations/${this.selectedDesignationObject._id}`, this.selectedDesignationObject);

                this.messageInfo = "Payment Designation saved successfully";
                this.messageError = "";
                this.$store.commit('clearCache');

            } catch (error) {
                this.messageInfo = "";
                this.messageError = error;
            }
        },
        async saveNewPD() {
            try {
                await apiPost('paymentDesignations', this.selectedDesignationObject);

                this.messageInfo = "New Payment Designation created successfully";
                this.messageError = "";
                this.$store.commit('clearCache');
                this.isNewPaymentDesignation = false;
                this.selectedDesignation = "";

            } catch (error) {
                this.messageInfo = "";
                this.messageError = error;
            }
        },
        addNewPD() {
            this.isNewPaymentDesignation = true;
            this.selectedDesignationObject = {
                type: this.selectedType
            };
        },
    },
    watch: {
        selectedDesignationObject: {
            handler() {
                this.messageError = "";
                this.messageInfo = "";
                this.selectedDesignation = "";
                this.selectedType = "";
            },
            deep: true,
        },
    },
};
</script>


<style>
/* .line-container {
    margin-bottom: 10px;
}

#designation-select option {
    text-align: center;
} */

#designation-select option:disabled {
    color: gray;
}

.text-input::placeholder {
    text-align: center;
}

#designation-select option:first-child {
    color: gray;
}

/* .page-container {
    align-items: center;
}

.payment-container {
    text-align: center;
} */


.message-info {
    color: green;

}

.message-error {
    color: red;
}

.definition {
    font-size: 12px;
    padding: 5px;
}
</style>

  