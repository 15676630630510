<template>
    <div class="faq-container">
        <h1>Club Membership FAQs</h1>
        <div class="faqs">
            <v-expansion-panels multiple accordion>
                <v-expansion-panel v-for="(faq, i) in faqs" :key="i">
                    <v-expansion-panel-header>Q: {{ faq.question }}</v-expansion-panel-header>
                    <v-expansion-panel-content v-for="(answer, j) in faq.answers" :key="j">
                        <p v-html="answer.richText"></p>
                    </v-expansion-panel-content>
                    <hr />
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            faqs: [
                {
                    question: 'How much are club membership dues each year?',
                    answers: [
                        {
                            richText: '<p>Club membership dues are <strong>$250</strong> annually.</p>'
                        },
                    ]
                },
                {
                    question: 'When is the deadline each year?',
                    answers: [
                        {
                            richText: '<p>By the date of the annual banquet <em>(usually around late January)</em></p>'
                        },
                    ]
                },
                {
                    question: 'What is Delinquent Club Membership Status?',
                    answers: [
                        {
                            richText: '<p>Dues are not paid in full by 2 weeks after due date (per by laws)</p>'
                        },
                    ]
                },
                {
                    question: 'What is Ineligible Player Status?',
                    answers: [
                        {
                            richText: '<p>Club Membership status that is not Active</p>'
                        },
                        {
                            richText: '<p>CIPP is missing / expired</p>'
                        }
                    ]
                },
                {
                    question: 'Does being an active member mean I can play in games?',
                    answers: [
                        {
                            richText: '<p>No. You must also have an active CIPP registration.</p>'
                        },
                    ]
                },
                {
                    question: 'What is CIPP?',
                    answers: [
                        {
                            richText: `
        <p>The Club Individualized Participation Program (CIPP) is USA Rugby's third-party liability insurance coverage that protects you, the team, and the club from lawsuits that may arise from accidents that occur throughout the season.</p>
        <p>Current cost: $88.36</p>
        <p><strong>Order Summary:</strong></p>
        <ul>
          <li>Player, Senior Club, Season: $85.00</li>
          <li>National Fee: $40.00</li>
          <li>Senior Club Council: $20.00</li>
          <li>Capital: $25.00</li>
          <li>RX Systems: $3.36</li>
          <li><strong>Total: $88.36</strong></li>
        </ul>
        <p><a href="https://usa.rugby/insurance">https://usa.rugby/insurance</a></p>`
                        }
                    ]
                }
                ,
                {
                    question: 'Does it cover club gear?',
                    answers: [
                        {
                            richText: '<p>It covers practice and game-day t-shirts and socks.</p>'
                        },
                        {
                            richText: '<p>More club gear can be purchased at <a href="https://www.steamrollerrugby.com/severn-river-rugby/">https://www.steamrollerrugby.com/severn-river-rugby/</a></p>'
                        },
                    ]
                },
                {
                    question: 'What is the difference between club membership and player eligibility?',
                    answers: [
                        {
                            richText: '<p>An eligible player is an active club member in good standing with an active CIPP number assigned to them.</p>'
                        },
                    ]
                },
                {
                    question: 'What are other statuses?',
                    answers: [
                        {
                            richText: '<dl><dt><i>Active Member</i></dt><dd>Attends practices, represents club, votes, serves on boards, and participates in club functions.</dd><dt><i>Alumni Member</i></dt><dd>Former team members who pay dues. Can vote, serve on the board, but can be terminated for violating club rules.</dd><dt><i>Social Member</i></dt><dd>Participates in social functions, cannot vote, represent the club in matches, or hold office.</dd><dt><i>Delinquent / Sanctioned Member</i></dt><dd>Overdue on payments or has been sanctioned. Cannot vote, hold office, or represent the club in matches.</dd><dt><i>Member in Good Standing</i></dt><dd>Active or Alumni member who is current on dues and not under any sanctions.</dd></dl>'
                        },
                    ]
                }
            ]
        };
    }
};
</script>

<style scoped>
.faq-container {
    padding: 20px;
}

.faqs {
    text-align: left;
}

.v-expansion-panel-header {
    font-weight: bold;
}

.v-expansion-panel-content p {
    margin: 0;
    padding: 10px 0;
}

hr {
    border: none;
    height: 1px;
    background-color: #ddd;
}
</style>