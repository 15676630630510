import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';

const getDefaultState = () => {
  return {
    authToken: null,
    currentUser: null,
    currentMember: null,
    currentClubId: null,
    cache: {},
    darkMode: false,
    trustDevice: false,
    // accessedEndpoints: [],
  };
};

export default createStore({
  state: getDefaultState(),
  mutations: {
    // addAccessedEndpoint(state, endpoint) {
    //   if (!state.accessedEndpoints.includes(endpoint)) {
    //     state.accessedEndpoints.push(endpoint);
    //   }
    // },
    setDarkMode(state, mode) {
      state.darkMode = mode;
    },
    setAuthToken(state, token) {
      state.authToken = token;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setCurrentMember(state, member) {
      state.currentMember = member;
    },
    setCurrentClubId(state, clubId) {
      state.currentClubId = clubId;
    },
    setCache(state, { key, value }) {
      state.cache[key] = value;
    },
    clearCache(state) {
      state.cache = {};
    },
    resetState(state) {
      const newState = getDefaultState();
      newState.darkMode = state.darkMode;
      Object.assign(state, newState);
    },
    setTrustDevice(state, trust) {
      state.trustDevice = trust;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    clearCacheForEndpoint(state, endpoint) {
      delete state.cache[endpoint];
    },
    updateCurrentUserRoster(state, roster) {
      if (state.currentUser) {
        state.currentUser.roster = roster;
      }
    },
  },
  actions: {
    async login({ commit }, { email, password, trustDevice }) {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/auth/login`, { email, password });
      const { authToken, user } = response.data;
      commit('setAuthToken', authToken);
      commit('setCurrentUser', user);
      commit('setCurrentMember', user.member);
      commit('setCurrentClubId', user.member.club);
      commit('setTrustDevice', trustDevice);
    },
    logout({ commit }) {
      commit('resetState');
      sessionStorage.clear();
      localStorage.removeItem('authState');
    },
    toggleDarkMode({ commit, state }) {
      commit('setDarkMode', !state.darkMode);
    },
    // clearAccessedEndpointsCache({ state, commit }) {
    //   state.accessedEndpoints.forEach(endpoint => {
    //     commit('clearCacheForEndpoint', endpoint);
    //   });
    //   state.accessedEndpoints = []; 
    // },
  },
  getters: {
    darkMode: state => state.darkMode,
    isAuthenticated: state => !!state.authToken && !!state.currentUser,
    isAuthorized: (state) => (requiredRoles, matchAll = false) => {
      if (!!state.authToken && !!state.currentUser) {
        if (matchAll) {
          // User must have all the roles in requiredRoles
          return requiredRoles.every(role => state.currentUser.roles.includes(role));
        } else {
          // User must have at least one of the roles in requiredRoles
          return requiredRoles.some(role => state.currentUser.roles.includes(role));
        }
      }
      return false;
    }
  },
  plugins: [
    // Use localStorage for darkMode
    createPersistedState({
      key: 'darkMode',
      paths: ['darkMode'],
      storage: window.localStorage,
    }),
    // Persist critical state variables based on trustDevice state
    createPersistedState({
      key: 'authState',
      paths: ['authToken', 'currentUser', 'currentMember', 'currentClubId'],
      storage: {
        getItem: key => {
          return JSON.parse(window.localStorage.getItem(key) || window.sessionStorage.getItem(key));
        },
        setItem: (key, value) => {
          // const data = JSON.parse(value);
          const useLocalStorage = JSON.parse(sessionStorage.getItem('restOfState') || '{}').trustDevice || JSON.parse(localStorage.getItem('restOfState') || '{}').trustDevice;
          (useLocalStorage ? localStorage : sessionStorage).setItem(key, value);
        },
        removeItem: key => {
          window.localStorage.removeItem(key);
          window.sessionStorage.removeItem(key);
        },
      },
    }),
    // Persist other state variables using sessionStorage
    createPersistedState({
      key: 'restOfState',
      paths: ['cache', 'trustDevice'],
      storage: window.sessionStorage,
    }),
  ],
});

// Clear sessionStorage when the app starts
if (process.env.NODE_ENV !== 'production') {
  sessionStorage.clear();
}
