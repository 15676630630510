<template>
    <div>
        <h1>Roll Call! (your team)</h1>

        <div>
            <select v-model="selectedPractice" required>
                <option disabled value="">select a practice</option>
                <option v-for="practice in practices" :key="practice.id" :value="practice">
                    {{ this.$formatDate(practice.start.dateTime, "MM/dd/yyyy") ||
                        this.$formatDate(practice.start.date, "MM/dd/yyyy") }} -
                    {{ practice.summary }}
                </option>
            </select>
        </div>

        <div v-if="this.selectedPractice" class="summary">
            {{ this.presentPlayers.length }} of {{ this.filteredPlayers.length }} players in attendance.
        </div>

        <div>
            <label class="eligible">eligible players in green</label><br>
        </div>

        <hr>

        <div v-if="this.selectedPractice" class="players-container">
            <div class="players">
                <div v-for="player in filteredPlayers" :key="player.id"
                    :class="{ 'eligible': isEligible(player), 'ineligible': !isEligible(player) }" class="player">
                    <label>
                        <input type="checkbox" :value="player" v-model="presentPlayers">
                        {{ formattedName(player) }}
                    </label>
                </div>
            </div>
        </div>

        <hr>

    </div>
</template>


<script>
import { apiGet } from '@/api';
import { displayName } from '@/common';

export default {
    data() {
        return {
            attendances: [],
            players: [],
            practices: [],
            selectedTeam: this.$store.state.currentMember.team,
            selectedPractice: "",
            presentPlayers: [],
            showEligibility: false,
        };
    },
    methods: {
        displayName,
        formattedName(player) {
            return this.displayName(player, { lastNameFirst: true, showNickname: true, showAlias: false });
        },

        async fetchMembers() {
            const allMembers = await apiGet('members');
            this.players = allMembers.filter(member => member.team?._id === this.selectedTeam?._id);

            // Refresh member status in the Vuex store if needed
            this.$store.commit('updateMembers', this.players);

            this.players.sort((a, b) => {
                if (a.lastName < b.lastName) {
                    return -1;
                }
                if (a.lastName > b.lastName) {
                    return 1;
                }
                return 0;
            });
        },

        async fetchEvents() {
            let allEvents = await apiGet('calendar/events');

            this.events = allEvents.sort((a, b) => {
                const dateA = new Date(a.start.dateTime || a.start.date);
                const dateB = new Date(b.start.dateTime || b.start.date);

                if (isNaN(dateA)) {
                    return -1;
                }

                if (isNaN(dateB)) {
                    return 1;
                }

                return dateA - dateB;
            });
        },

        async fetchAttendances() {
            const res = await apiGet('eventAttendances');
            this.attendances = res.map(attendance => {
                const memberObject = this.players.find(player => player._id === attendance.player);
                const eventObject = this.events.find(event => event.id === attendance.event);
                return {
                    ...attendance,
                    member: memberObject,
                    event: eventObject,
                }
            });
        },

        filterEvents() {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            this.practices = this.events.filter(event =>
                event.summary.toLowerCase().includes("practice") &&
                new Date(event.start.dateTime || event.start.date) >= today &&
                event.team === this.selectedTeam._id
            );
        },

        toggleShowEligibility() {
            this.showEligibility = !this.showEligibility;
        },

        isEligible(player) {
            const isActiveInGoodStanding = player.status === 'good standing' &&
                player.playerStatus === 'active';
            const hasValidCIPP = player.cipp && new Date(player.cippExpiration) > new Date();

            return isActiveInGoodStanding && hasValidCIPP;
        }
    },
    computed: {
        filteredPlayers() {
            return this.players.filter(player => {
                // Show all active players
                return player;
            });
        }
    },
    async created() {
        await this.fetchEvents();
        await this.fetchMembers();
        await this.fetchAttendances();
        this.filterEvents();
    },
};
</script>


<style scoped>
.players-container {
    display: flex;
    justify-content: center;
}

.players {
    text-align: left;
    max-width: 600px;
    margin: auto;
}

.summary {
    padding-top: 20px;
    padding-bottom: 20px;
}

.eligible {
    color: green;
    font-weight: bold;
}

.ineligible {
    color: var(--text-color);
}

hr {
    border: none;
    height: 1px;
    background-color: var(--hr-color);
}
</style>
