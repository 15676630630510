<template>
  <div id="register-user-page">
    <h1>Verify Email</h1>
    <div class="logo">
      <img :src="logo" class="logo-image" />
    </div>
    <div class="registration-form">
      <p>To create a profile for this club member, you must verify the email address.
        <br />
        You will receive an email with further instructions.
      </p>

      <label><b>{{ this.clubName }}</b></label>
      <br />
      <label>{{ this.email }}</label>
      <br />
      <br />
      <div class="terms">
        <input type="checkbox" v-model="termsAccepted" id="terms">
        <label for="terms">I accept the </label><a :href="`${webUrl}/terms-conditions`" target="_blank"
          rel="noopener noreferrer">Terms and Conditions</a>
      </div>
      <br />
      <!-- Conditionally render the Verify button -->
      <button v-if="!buttonHidden" @click="verifyEmail">Verify</button>
      <br />
      <p v-if="infoMessage" class="info-message">{{ infoMessage }}</p>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
      <br />
      <button @click="goToLoginPage">Back To Sign In</button>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost } from '@/api';
import logo from '@/../images/crest.png';
const currentTermsVersion = process.env.VUE_APP_CURRENT_TERMS;

export default {
  data() {
    return {
      email: this.$route.query.email,
      clubId: this.$route.query.club,
      memberId: this.$route.query.member,
      clubName: "",
      logo: logo,
      termsAccepted: false,
      infoMessage: "",
      errorMessage: "",
      successMessage: "",
      buttonHidden: false, // This will control the visibility of the button
    };
  },
  async mounted() {
    this.clubName = await this.getClubName();
  },
  methods: {
    showMessage(message, messageType = "info") {
      this.successMessage = messageType === "success" ? message : "";
      this.infoMessage = messageType === "info" ? message : "";
      this.errorMessage = messageType === "error" ? message : "";

      if (messageType === "success") {
        this.buttonHidden = true; // Hide the button after success
      }
    },

    async verifyEmail() {
      if (!this.termsAccepted) {
        this.showMessage("You must accept the Terms and Conditions.", "error");
        return;
      }

      try {
        // CREATE TOKEN ON MEMBER
        const tokenResponse = await apiPost('auth/set-member-verification-token', { member: this.memberId });

        if (!tokenResponse.success) {
          this.showMessage("Failed to generate verification token.", "error");
          return;
        }

        // EMAIL TOKEN TO MEMBER
        const emailResponse = await apiPost('mail/verify-email', { email: this.email, member: this.memberId, token: tokenResponse.token });

        if (emailResponse.success) {
          this.showMessage("Email verification sent. Please check your email.", "success");
          this.buttonHidden = true; // Ensure the button is hidden here as well
        } else {
          this.showMessage("Failed to send verification email.", "error");
        }
      } catch (error) {
        this.showMessage("An error occurred during the verification process.", "error");
      }
    },

    async getClubName() {
      const response = await apiGet(`clubs/${this.clubId}`);
      return response.name;
    },
    goToLoginPage() {
      this.$router.push('/login-page');
    }
  },
  computed: {
    webUrl() {
      return process.env.VUE_APP_WEB_URL;
    },
    termsAcceptedVersion() {
      return this.termsAccepted ? currentTermsVersion : null;
    },
    termsAcceptedDate() {
      return this.termsAccepted ? new Date() : null;
    },
  },
};
</script>

<style>
.logo-image {
  max-width: 200px;
  height: auto;
}

.registration-form {
  text-align: center; /* Center the contents within the form */
}

.error-message, .info-message, .success-message {
  text-align: center;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  font-weight: bold;
}

.success-message {
  color: green;
}
</style>
