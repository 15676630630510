<template>
    <div class="fp-content">
        <div class="layout">
            <div>
                <button @click="backToEdit">Back to Lineup</button>
            </div>
<hr />
            <div v-if="this.roster" class="field">
                <table>
                    <tr>
                        <td></td>
                        <td>
                            <div class="cell-content">

                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.one) }}</div>
                                <div class="number">1</div>
                            </div>
                        </td>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.two) }}</div>
                                <div class="number">2</div>
                            </div>

                        </td>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.three) }}</div>
                                <div class="number">3</div>

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.four) }}</div>
                                <div class="number">4</div>
                            </div>
                        </td>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.five) }}</div>
                                <div class="number">5</div>
                            </div>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.six) }}</div>
                                <div class="number">6</div>
                            </div>
                        </td>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.eight) }}</div>
                                <div class="number">8</div>
                            </div>
                        </td>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.seven) }}</div>
                                <div class="number">7</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.nine) }}</div>
                                <div class="number">9</div>
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.ten) }}</div>
                                <div class="number">10</div>
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.twelve) }}</div>
                                <div class="number">12</div>
                            </div>
                        </td>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.thirteen) }}</div>
                                <div class="number">13</div>
                            </div>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.eleven) }}</div>
                                <div class="number">11</div>
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.fourteen) }}</div>
                                <div class="number">14</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <div class="cell-content">
                                <img class="jersey" :src="jersey" alt="jersey">

                                <div class="player">{{ formattedName(this.roster?.fifteen) }}</div>
                                <div class="number">15</div>
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
                <div class="subs">
                    <h3>Subs</h3>
                    <div class="sub" v-for="(sub, index) in this.roster.subs" :key="index">
                        {{ sub?.lastName }}
                    </div>
                </div>

            </div>

            <div class="positions">
                <div class="forwards">
                    <h3>Forwards:</h3>
                    <ol>
                        <li>Loosehead Prop</li>
                        <li>Hooker</li>
                        <li>Tighthead Prop</li>
                        <li>Lock (Second Row)</li>
                        <li>Lock (Second Row)</li>
                        <li>Blindside Flanker</li>
                        <li>Openside Flanker</li>
                        <li>Number Eight</li>
                    </ol>
                </div>
                <div class="backs">

                    <h3>Backs:</h3>
                    <ol start="9">
                        <li>Scrum-half</li>
                        <li>Fly-half</li>
                        <li>Left Wing</li>
                        <li>Inside Centre</li>
                        <li>Outside Centre</li>
                        <li>Right Wing</li>
                        <li>Fullback</li>
                    </ol>
                </div>

            </div>
        </div>

    </div>
</template>

<style scoped>
* {
    --jersey-size: 75px;
    --jersey-name-font-size: 8px;
    --jersey-number-font-size: 16px;
}

/* Desktop view */
@media (min-width: 769px) {
    * {
        --jersey-size: 110px;
        --jersey-name-font-size: 10px;
        --jersey-number-font-size: 24px;
    }
}

.fp-content {
    /* text-align: center;
    width: 70% */
}

.layout {
    /* display: flex; */
    /* flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
    width: 130%; */
}

.field {
    /* margin-top: 50px;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: center; */
}

.field table {
    border-collapse: collapse;
    /* margin: 0 auto; */
    /* margin-left: 0; */

}

.field table td {
    border: 0px solid #ffffff;
    /* padding: 0; */
    text-align: center;
    /* position: relative; */
    /* width: 100%; */
    /* Ensure the cell has defined dimensions */
    /* height: 100%; */
}

.cell-content {
    position: relative;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    /* width: 100%;
    height: 100%; */
}

.jersey {
    display: block;
    height: auto;
    /* width: 75px; */
    width: var(--jersey-size);
    /* margin: 0 -15%; */
    /* position: relative; */
    z-index: 1;
    /* keep image below text elements */
}

.jersey-text {}

.positions {
    text-align: left;
    display: block;
}

.player,
.number {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;


    font-family: 'Arial Black', 'Arial', sans-serif;
    color: white;
    /* font-weight: 800; */
    text-transform: uppercase;
    /* letter-spacing: 2px; */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    /* line-height: 1.2; */

}

.player {
    top: 25%;
    font-size: var(--jersey-name-font-size) !important;

}

.number {
    top: 45%;
    font-size: var(--jersey-number-font-size) !important;
}

.subs {
    margin-top: 50px;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px dotted #ffffff;

}

.sub {
    /* border-bottom: 1px dotted #ffffff; */
    padding-top: 5px;
    padding-bottom: 2px;
}
</style>

<script>
import { displayName } from '@/common';
import jersey from '@/../images/jersey.png';

export default {
    data() {
        return {
            isLoading: true,
            jersey: jersey,
            roster: null,


        };
    },
    methods: {
        displayName,
        formattedName(player) {
            return player?.lastName;
            // return this.displayName(player, { lastNameFirst: false, showNickname: true, showAlias: false });
        },
        async backToEdit() {
            this.$router.push({ path: 'rosters-manager' });
        },

    },
    async created() {
        this.roster = this.$store.state.currentUser.roster;
    }
};

</script>
