<template>
  <div id="enter-password">
    <h1>Enter Password</h1>

    <div>
      <div class="logo">
        <img :src="logo" alt="Logo" class="logo-image" />
      </div>
      <div class="password-container">
        <input class="password-input" ref="passwordInput" :type="passwordVisible ? 'text' : 'password'"
          v-model="password" placeholder="Enter password" @keydown.enter="attemptLogin" />
        <img v-if="!passwordVisible" :src="showPasswordImage" alt="Show Password" class="show-password-toggle"
          @click="passwordVisible = !passwordVisible" />
        <img v-else :src="hidePasswordImage" alt="Show Password" class="show-password-toggle"
          @click="passwordVisible = !passwordVisible" />
      </div>
      <br />
      <div class="trust-device-container">
        <input type="checkbox" id="trustDevice" v-model="trustDevice" />
        <label for="trustDevice">Trust this device</label>
      </div>
      <p v-if="trustDevice" class="disclaimer">
        By selecting "Trust this device," you acknowledge the risk of storing your login information on this device.
        Only enable this option on devices you own and control.
        This device will remain trusted until you log out manually or clear your browser's storage.
      </p>
      <br />
      <button @click="attemptLogin">Login</button>
      <br />
      <br />
      <router-link to="/forgot-password" class="forgot-password-link">Forgot Password?</router-link>
      <br />
      <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
      <p class="attempt-count" v-if="attemptCount">
        Attempt {{ attemptCount }} of 5
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import logo from '@/../images/crest.png';
import showPasswordImage from '@/../images/icons/show.svg';
import hidePasswordImage from '@/../images/icons/hide.svg';

export default {
  data() {
    return {
      password: '',
      errorMessage: '',
      attemptCount: 0,
      logo: logo,
      passwordVisible: false,
      showPasswordImage: showPasswordImage,
      hidePasswordImage: hidePasswordImage,
      trustDevice: false, 
    };
  },
  computed: {
    email() {
      return this.$route.query.email;
    },
  },
  methods: {
    ...mapActions(['login']),

    async attemptLogin() {
      try {
        await this.login({
          email: this.email,
          password: this.password,
          trustDevice: this.trustDevice, 
        });

        this.$router.push({ name: 'HomePage' });
      } catch (error) {
        console.error('Error during login:', error);
        if (error.response.status === 401) {
          this.errorMessage =
            'Wrong password. Please try again or click "Forgot Password" to reset it.';
          this.attemptCount += 1;
          if (this.attemptCount === 5) {
            // TODO: Lock the account
          }
        }
      }
    },
  },

  mounted() {
    this.$refs.passwordInput.focus();
  },
};
</script>

<style>
.error-message {
  color: red;
}

.attempt-count {
  color: #926740;
}

.logo-image {
  max-width: 350px;
  height: 235px;
  opacity: 0.8;
}

.password-container {
  align-items: center;
}

.show-password-toggle {
  cursor: pointer;
  height: 20px;
  margin-left: -20px;
  vertical-align: middle;
}

.trust-device-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
