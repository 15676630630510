<template>
  <div class="member-container">
    <div v-if="isLoading">Loading composite...</div>
    <div v-else>
      <h1>Club Members</h1>

      <div>
        <input class="search-member" type="text" v-model="search" placeholder="Search members" />
      </div>
      <div>
        <select v-model="selectedTeam">
          <option value="">All</option>
          <option v-for="team in teams" :key="team._id" :value="team._id">{{ team.name }}</option>
        </select>
      </div>
      <div class="pic-filter">
        <input id="filterProfilePicCheckbox" class="radio-filter" type="checkbox" v-model="filterProfilePic">
        <label for="filterProfilePicCheckbox">has photo</label>
      </div>

      <div class="members-grid">
        <div v-for="member in sortedMembers" :key="member._id" class="member-card">
          <router-link v-if="isAuthorized(['VIEW_MEMBERS'])"
            :to="{ name: 'MemberProfile', params: { memberId: member._id } }">
            <img :src="member.imageUrl" alt="Member Picture" class="member-picture" />
          </router-link>
          <img v-else :src="member.imageUrl" alt="Member Picture" class="member-picture" />
          <div class="member-name">
            {{ displayName(member, { lastNameFirst: false, showNickname: false, showAlias: true }) }}
            <div v-if="member.nickname" class="member-nickname">"{{ member.nickname }}"</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
  
<script>
import { apiGet } from '@/api';
import defaultProfileImageUrl from '@/../images/default-profile-pic.jpg';
import { mapGetters } from 'vuex';
import { displayName } from '@/common';

export default {
  data() {
    return {
      isLoading: true,
      members: [],
      teams: [],
      selectedTeam: "",
      search: '',
      filterProfilePic: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized']),

    sortedMembers() {
      let filteredMembers = this.members;

      if (this.filterProfilePic) {
        filteredMembers = filteredMembers.filter(member => member.imageUrl !== defaultProfileImageUrl);
      }

      if (this.selectedTeam) {
        filteredMembers = filteredMembers.filter(member => member.team?._id === this.selectedTeam);
      }


      const searchTerms = this.search.toLowerCase().split(' ');
      filteredMembers = filteredMembers.filter(member => {
        return searchTerms.every(searchTerm => {
          const name = this.displayName(member, { lastNameFirst: false, showNickname: true, showAlias: true }).toLowerCase();
          return name.includes(searchTerm);
        });
      });

      return filteredMembers.sort((a, b) => this.displayName(a).localeCompare(this.displayName(b)));
    },
  },
  methods: {
    displayName,
    async fetchMembers() {
      const membersResponse = await apiGet('members');
      this.members = await Promise.all(
        membersResponse.map(async member => {
          const imageUrl = await this.getProfileImageUrl(member);
          return { ...member, imageUrl };
        })
      );
    },
    async fetchTeams() {
      const teamsResponse = await apiGet('teams');
      this.teams = teamsResponse.teams;
    },
    async getProfileImageUrl(member) {

      try {
        if (member) {

          if (member.profileImageUrl) {
            const existsResponse = await apiGet(`members/getProfilePic/${member._id}`);
            if (existsResponse.exists) {
              const response = await apiGet(`members/getSignedUrl/${member._id}`);
              const imageResponse = await fetch(response.signedUrl);
              if (imageResponse.ok) {
                return response.signedUrl;
              }
            }

          }

        }
      }
      catch (error) {
        //console.error("Error fetching signed URL: ", error);
      }

      // If the member doesn't have a profile image URL or if there's an error in fetching the signed URL,
      // return the default image URL
      return defaultProfileImageUrl;
    },
  },
  async created() {
    await Promise.all([this.fetchMembers(), this.fetchTeams()]);
    this.isLoading = false;
  },
};
</script>
  
<style scoped>
.member-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.members-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1em;
}

.member-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em;
}

.member-picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.member-name {
  margin-top: 0.5em;
  text-align: center;
}

.radio-filter {
  vertical-align: middle;
}

.search-member {
  width: 160px;
}
</style>
