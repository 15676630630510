<template>
    <div v-if="isLoading">Loading payments...</div>
    <div v-else>
        <div>
            <h1>Payment Activity</h1>

            <!--quick search-->
            <input type="text" v-model="search" placeholder="Search members" />

            <div>
                <!--how to view-->
                <input type="checkbox" id="sumByMember" v-model="sumByMember">
                <label for="sumByMember">Sum By Member</label>
            </div>

            <!--designation-->
            <select v-model="selectedDesignation">
                <option value="">All</option>
                <option v-for="designation in paymentDesignations" :key="designation._id" :value="designation._id">
                    {{ designation.name }}
                </option>
            </select>
        </div>


        <div v-if="filteredPayments.length > 0">
            <div class="scrollable-section">
                <table>
                    <thead>
                        <tr v-if="this.sumByMember">
                            <th @click="sortTable('amount')" class="right-align">Paid</th>
                            <th @click="sortTable('amount')" class="right-align">Owed</th>
                            <th @click="sortTable('displayName')">Member</th>
                        </tr>
                        <tr v-else>
                            <th @click="sortTable('date')">Date</th>
                            <th @click="sortTable('amount')" class="right-align">Amount</th>
                            <th @click="sortTable('displayName')">Member</th>
                        </tr>
                    </thead>

                    <tbody>
                        <!-- Adjust the table rows to display data according to the selected viewMode -->
                        <tr class="click-row" v-for="payment in paginatedData" :key="payment._id"
                            @click="showPaymentDetails(payment)">
                            <template v-if="this.sumByMember">
                                <td class="right-align">${{ payment.amount }}</td>
                                <td class="right-align">${{ amountOwed(payment) }}</td>
                                <td>{{ displayName(payment.member) }}</td>
                                <td></td>
                            </template>
                            <template v-else>
                                <td>{{ this.$formatDate(payment.date, "yyyy-MM-dd") }}</td>
                                <td class="right-align">${{ payment.amount }}</td>
                                <td>{{ displayName(payment.member) }}</td>
                            </template>
                        </tr>
                    </tbody>

                </table>
            </div>
            <div v-if="numberOfPages > 1" class="pagination-controls">
                <button class="page-button" @click="currentPage = Math.max(currentPage - 1, 1)"
                    :disabled="currentPage === 1">Previous</button>
                <div class="page-details">Page {{ currentPage }} of {{ numberOfPages }}</div>
                <button class="page-button" @click="currentPage = Math.min(currentPage + 1, numberOfPages)"
                    :disabled="currentPage === numberOfPages">Next</button>
            </div>

        </div>
        <div v-else>
            <p>No payments have been made yet.</p>
        </div>

        <div v-if="dialogVisible" class="dialog" @click.self="closeDialog">
            <div class="dialog-content">
                <div class="content-item">
                    <img :src="closeIcon" alt="Close Icon" class="close-btn" @click.stop="closeDialog" />
                </div>

                <div class="content-item">
                    <input type="text" v-model="selectedPayment.displayName" placeholder="Member" readonly />
                </div>

                <div class="content-item">
                    <input type="date" v-model="selectedPayment.date" placeholder="Date"
                        :readonly="!isAuthorized(['MANAGE_PAYMENTS'])" />
                </div>

                <div class="content-item">
                    <input type="number" v-model="selectedPayment.amount" placeholder="Amount"
                        :readonly="!isAuthorized(['MANAGE_PAYMENTS'])" />
                </div>

                <div class="content-item">
                    <select v-model="selectedPayment.source" :disabled="!isAuthorized(['MANAGE_PAYMENTS'])">
                        <option value="">Select Source</option>
                        <option value="Venmo">Venmo</option>
                        <option value="Paypal">Paypal</option>
                        <option value="Cash">Cash</option>
                        <option value="Other">Other</option>
                        <option value="Adjustment">Adjustment</option>
                    </select>
                </div>

                <div class="content-item">
                    <select v-model="selectedPayment.designation._id" :disabled="!isAuthorized(['MANAGE_PAYMENTS'])">
                        <option value="">Select Designation</option>
                        <option v-for="designation in paymentDesignations" :key="designation._id"
                            :value="designation._id">
                            {{ designation.name }}
                        </option>
                    </select>
                </div>

                <!-- <div class="content-item" v-if="selectedPayment.designation.type == 'dues'">
                    <select v-model="selectedPayment.duesperiod" :disabled="!isAuthorized(['MANAGE_PAYMENTS'])">
                        <option value="">Select Dues Period</option>
                        <option value="full">Full</option>
                        <option value="fall">Fall</option>
                        <option value="spring">Spring</option>
                    </select>
                </div> -->

                <div class="content-item">
                    <input type="text" v-model="selectedPayment.notes" placeholder="Notes"
                        :readonly="!isAuthorized(['MANAGE_PAYMENTS'])" />
                </div>

                <div class="content-item">
                    <input type="text" v-model="selectedPayment.transaction" placeholder="Transaction no."
                        :readonly="!isAuthorized(['MANAGE_PAYMENTS'])" />
                </div>

                <div class="content-item">
                    <div class="entered-info" v-if="selectedPayment.enteredBy">
                        logged: {{ enteredByName }}
                        on {{ this.$formatDate(selectedPayment.dateEntered, "yyyy-MM-dd") }}
                    </div>
                </div>

                <div class="content-item">
                    <div class="entered-info" v-if="selectedPayment.updatedBy">
                        updated: {{ updatedByName }}
                        on {{ this.$formatDate(selectedPayment.dateUpdated, "yyyy-MM-dd") }}
                    </div>
                </div>

                <div v-if="isModified" class="content-item">
                    <button v-if="isAuthorized(['MANAGE_PAYMENTS'])" class="save-btn"
                        @click="savePayment(selectedPayment._id)">
                        <img :src="saveIcon" alt="Save Icon" />
                    </button>
                </div>

                <div v-else class="content-item">
                    <button class="save-btn" @click="closeDialog">
                        <img :src="doneIcon" alt="Done Icon" />
                    </button>
                </div>

                <div class="content-item">
                    <button v-if="isAuthorized(['MANAGE_PAYMENTS'])" class="delete-btn" @click="deletePayment">
                        <img :src="deleteIcon" alt="Delete Icon" />
                    </button>
                </div>

                <div class="content-item">
                    <div v-if="messageInfo" class="message-info">{{ this.messageInfo }}</div>
                    <div v-if="messageError" class="message-error">{{ this.messageError }}</div>
                </div>

            </div>
        </div>


    </div>
</template>

<script>
import { apiGet, apiPut, apiDelete } from '@/api';
import deleteIcon from '@/../images/icons/delete.svg';
import saveIcon from '@/../images/icons/save.svg';
import closeIcon from '@/../images/icons/close.svg';
import doneIcon from '@/../images/icons/done.svg';
import { mapGetters } from 'vuex'
import { displayName } from '@/common';


export default {
    data() {
        return {
            payments: [],
            isLoading: true,
            search: '',
            currentSort: 'date',
            currentSortDir: 'desc',
            selectedDesignation: "",
            currentPaymentDesignation: null,
            sumByMember: false,
            dialogVisible: false,
            selectedPayment: {},
            deleteIcon,
            saveIcon,
            closeIcon,
            doneIcon,
            initialPayment: null,
            messageError: "",
            messageInfo: "",
            currentPage: 1,
            itemsPerPage: 50,
            updatedByName: "",
            enteredByName: "",
        };
    },
    watch: {
        currentSort() {
            this.sortPayments();
        },
        currentSortDir() {
            this.sortPayments();
        },
        currentView(newValue) {
            this.viewMode = newValue;
        },
        selectedDesignation() {
            this.fetchPayments();
        },
        sumByMember() {
            this.fetchPayments();
        },
        selectedPayment: {
            deep: true,
            handler(newValue) {
                if (!this.initialPayment) {
                    this.initialPayment = { ...newValue };
                }
            },
        },
        async 'selectedPayment.updatedBy'(newUserId) {
            if (newUserId) {
                this.updatedByName = await this.getMemberNameFromUserId(newUserId);
            }
        },
        async 'selectedPayment.enteredBy'(newUserId) {
            if (newUserId) {
                this.enteredByName = await this.getMemberNameFromUserId(newUserId);
            }
        },
    },
    async created() {
        await this.fetchPaymentDesignations()
        await this.fetchPayments();

        this.isLoading = false;
        this.sortPayments();
    },
    methods: {
        closeDialog() {
            this.messageInfo = "";
            this.messageError = "";
            this.dialogVisible = false;
            this.initialPayment = null;
        },
        sortPayments() {
            if (this.sumByMember) {
                this.sortPaymentsSumByMember();
            } else {
                this.sortPaymentsDefault();
            }
        },
        sortPaymentsDefault() {
            this.payments.sort((a, b) => {
                let modifier = 1;
                if (this.currentSortDir === 'desc') modifier = -1;
                let aValue = this.currentSort.split('.').reduce((o, i) => o[i], a);
                let bValue = this.currentSort.split('.').reduce((o, i) => o[i], b);
                if (aValue < bValue) return -1 * modifier;
                if (aValue > bValue) return 1 * modifier;
                return 0;
            });
        },
        sortPaymentsSumByMember() {
            this.payments.sort((a, b) => {
                let modifier = 1;
                if (this.currentSortDir === 'desc') modifier = -1;
                let aValue = a[this.currentSort];
                let bValue = b[this.currentSort];
                if (aValue < bValue) return -1 * modifier;
                if (aValue > bValue) return 1 * modifier;
                return 0;
            });
        },
        sortTable(s) {
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'desc' ? 'asc' : 'desc';
            } else {
                this.currentSort = s;
                this.currentSortDir = 'desc';
            }
        },
        async fetchPayments() {
            try {
                const data = await this.getPayments();
                if (data.payments) {
                    this.payments = data.payments.map(payment => ({
                        ...payment,
                        displayName: this.displayName(payment.member),
                    }));
                    this.sortPayments();  // sort the payments after fetch
                } else {
                    console.error('Error fetching payments:', data.message);
                }
            } catch (error) {
                console.error('Error fetching payments:', error);
            }
        },

        async getPayments() {
            let url;
            if (this.sumByMember && this.selectedDesignation) {
                url = `payments/designation/${this.selectedDesignation}/summed/member`;
            }
            else if (this.sumByMember) {
                url = `payments/summed/member`;
            }
            else if (this.selectedDesignation) {
                url = `payments/designation/${this.selectedDesignation}`;
            }
            else {
                url = 'payments';
            }
            const data = await apiGet(url);
            return data;
        },
        getDesignationName(designationId) {
            return designationId;
        },
        async getUser(userId) {
            const response = await apiGet('users/' + userId);
            return response;

        },
        displayName,
        async getMemberNameFromUserId(userId) {
            const user = await this.getUser(userId);
            const member = user.member;
            let name = `${member.firstName} ${member.lastName}`;
            return name;
        },
        async fetchPaymentDesignations() {
            try {
                const response = await apiGet('paymentDesignations');
                if (response) {
                    this.paymentDesignations = response
                        .filter(designation => designation.status === 'active') // filter only active designations
                        .sort((a, b) => {
                            return new Date(b.date) - new Date(a.date);
                        });
                } else {
                    console.error("Error fetching payment designations:", response.message);
                }
            } catch (error) {
                console.error("Error fetching payment designations:", error);
            }
        },
        amountOwed(payment) {

            if (!this.selectedDesignation) {
                return "-";
            }
            let due;

            if (payment?.designation?.type === "trip") {
                due = payment.designation.tripAmount - payment.amount;
            }
            else if (payment?.designation?.type === "dues") {

                due = payment.designation.amount - payment.amount;

            }

            return due;
        },
        showPaymentDetails(payment) {
            if (!this.sumByMember) {
                this.selectedPayment = payment;
                this.selectedPayment.date = this.$formatDate(payment.date, "yyyy-MM-dd");
                this.dialogVisible = true;
                this.initialPayment = null;
            }
        },
        async savePayment(paymentId) {

            try {
                this.selectedPayment.dateUpdated = new Date();
                this.selectedPayment.updatedBy = this.$store.state.currentUser._id;

                await apiPut(`payments/${paymentId}`, this.selectedPayment);
                this.initialPayment = this.selectedPayment;

                this.messageInfo = "Payment saved successfully";
                this.messageError = "";
                this.$store.commit('clearCache');

            } catch (error) {
                this.messageInfo = "";
                this.messageError = error;
            }
        },
        async deletePayment() {
            if (confirm('Are you sure you want to delete this payment?')) {
                // DELETE request to the server
                await apiDelete(`payments/${this.selectedPayment._id}`);

                this.$store.commit('clearCache');
                this.payments = this.payments.filter(payment => payment._id !== this.selectedPayment._id);

                // Close the dialog
                this.closeDialog();
            }
        },
    },
    computed: {
        ...mapGetters(['isAuthorized']),
        isModified() {
            return JSON.stringify(this.initialPayment) !== JSON.stringify(this.selectedPayment);
        },
        uniqueDesignations() {
            // Compute unique payment designations
            return [...new Set(this.payments.map(payment => payment.designation.name))];
        },
        filteredPayments() {
            return this.payments
                .filter(payment =>
                    this.displayName(payment.member).toLowerCase().includes(this.search.toLowerCase())
                )
                .map(payment => ({
                    ...payment,
                    displayName: this.displayName(payment.member),
                }));
        },
        paginatedData() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredPayments.slice(start, end);
        },
        numberOfPages() {
            return Math.ceil(this.filteredPayments.length / this.itemsPerPage);
        },
    }
};
</script>

<style scoped>

/* Table Styles */
table {
    width: 100%;
}

table thead {
    position: sticky;
    top: 0;
    cursor: pointer;
}

table td {
    white-space: nowrap;
}

table tbody tr:nth-child(even) {
    /* Uncomment and adjust if needed */
    /* background-color: #f2f2f2; */
}

.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--alternate-color, #ffffff);
    padding: 20px;
    max-width: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: var(--color, #000000);
    position: relative; /* Ensure positioning for the close button */
}

.close-btn {
    position: absolute;
    top: 10px; /* Adjust top position */
    right: 10px; /* Adjust right position */
    width: 20px; /* Set a width */
    height: 20px; /* Set a height */
    cursor: pointer;
    z-index: 10; /* Ensure it's above other content */
}


/* General Content and Buttons */
.content-item {
    padding: 5px;
}

.center-align {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.click-row {
    cursor: pointer;
}

.message-info {
    color: green;
}

.message-error {
    color: red;
}

/* Pagination Controls */
.pagination-controls {
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-button {
    margin: 0 5px;
    width: 100px;
}

.page-details {
    padding: 0 5px;
}

.entered-info {
    font-size: 10px;
    word-wrap: nowrap;
}

</style>