import { apiGet } from '@/api'

export function displayName(member, options = {}) {
  if (!member) {
    return "no member";
  }
  const {
    lastNameFirst = true,
    showAlias = false,
    showNickname = false,
    maxlength = 50,
  } = options;

  let name;
  if (lastNameFirst) {
    // lastName, firstName
    name = `${member.lastName}, ${member.firstName}`;

    // lastName, firstName (alias)
    if (showAlias) {
      if (member.alias) name += ` (${member.alias})`;
    }

    // lastName, firstName (alias) "nickname"
    if (showNickname) {
      if (member.nickname) name += ` "${member.nickname}"`;
    }

  }
  else {
    // firstName
    name = `${member.firstName}`;

    // firstName (alias)
    if (showAlias) {
      if (member.alias) name += ` (${member.alias})`;
    }

    // firstName (alias) "nickname"
    if (showNickname) {
      if (member.nickname) name += ` "${member.nickname}"`;
    }

    // firstName (alias) "nickname" lastName
    name += ` ${member.lastName}`;

  }

  if (maxlength) {
    if (name.length > maxlength) {
      name = name.substring(0, maxlength) + "...";
    }
  }
  return name;
}

export function calculateTotalPaymentsForDesignation(payments, designationId) {
  const filteredPayments = [];
  payments.forEach(payment => {
    if (payment.designation._id === designationId) {
      filteredPayments.push(payment);
    }
  });
  return filteredPayments
    .reduce((total, payment) => {
      return total + payment.amount;
    }, 0);
}

export function computePaymentTotal(payments, designationId) {
  return payments.filter(payment => payment.designation._id === designationId)
    .reduce((total, payment) => total + payment.amount, 0);
}

export function calculateDueAmount(payments, designation) {
  if (isFullyPaid(payments, designation)) {
    return 0;
  }

  const paidAmount = calculateTotalPaymentsForDesignation(payments, designation._id);

  return parseFloat(parseFloat(designation.amount) - parseFloat(paidAmount));

}

export function isFullyPaid(payments, designation) {
  const paidAmount = calculateTotalPaymentsForDesignation(payments, designation._id);
  return parseFloat(paidAmount) >= parseFloat(designation.amount);
}

export async function getMemberStatus(member) {
  const designations = await apiGet('paymentDesignations');
  const paymentData = await apiGet(`payments/member/${member._id}`);
  const dues = designations.filter(designation => designation.type === 'dues');
  for (const due of dues) {
    const payments = paymentData.payments.filter(payment => payment.designation._id === due._id);
    if (isFullyPaid(payments, due)) {
      continue;
    }

    const dueAmount = calculateDueAmount(payments, due);
    if (dueAmount > 0) {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let deadline = new Date(due.deadline);
      deadline.setHours(0, 0, 0, 0);

      if (deadline < today) {
        return "delinquent";
      }
    }
  }
  return member.status;
}

export function cleanObject(obj) {
  const cleanObj = {};
  for (let propName in obj) {
    if (obj[propName] !== null && obj[propName] !== undefined) {
      if (typeof obj[propName] === 'object') {
        cleanObj[propName] = cleanObject(obj[propName]); // Recurse into nested objects
      } else {
        cleanObj[propName] = obj[propName];
      }
    }
  }
  return cleanObj;
}

