<template>
    <div>
        <h1>Under Construction</h1>
        <p>This product has been custom-built pro bono and is
            currently in its early deployment phase. As such, the occasional bug may arise - it appears you've found one of
            them. Lucky you!</p>
        <p>If you continue to see this page, try logging out, then log back in and try again.</p>
        <p>If the problem persists, kindly copy the error message below and send it via email to <a
                :href="emailLink">mafarmer+scrumapp@gmail.com</a> and I will investigate.</p>
        <p>Thank you for your understanding and patience,</p>
        <p>~farmer</p>
        <hr />
        <p>
            <strong>Error Message:</strong>
            <button @click="copyToClipboard">Copy to Clipboard</button>
        </p>
        <textarea readonly :value="fullErrorMsg" style="width: 100%; height: 235px;"></textarea>
    </div>
</template>

<script>
export default {
    props: ['errorMsg'],
    computed: {
        fullErrorMsg() {
            const timeStamp = new Date();
            const currentPage = this.$store.state.currentPage;
            const storeState = JSON.stringify(this.$store.state);
            const storeGetters = JSON.stringify(this.$store.getters);

            const error = `Error: ${this.errorMsg}\n`;
            const page = `Page: ${currentPage}\n`;
            const time = `Time: ${timeStamp.toLocaleString()}\n`;
            const state = `Store State: ${storeState}\n`;
            const getters = `Store Getters: ${storeGetters}\n`;

            return time + page + error + getters + state

            //  return `Error: ${this.errorMsg}\nPage: ${currentPage}\nTime: ${timeStamp.toLocaleString()}\nStore State: ${storeState}\nStore Getters: ${storeGetters}`;
        },
        emailLink() {
            const subject = 'Scrum App Custom Error';
            // const error = encodeURIComponent(this.fullErrorMsg);
            // const storeState = encodeURIComponent(JSON.stringify(this.$store.state));
            // const storeGetters = encodeURIComponent(JSON.stringify(this.$store.getters));
            // const localStorageData = encodeURIComponent(JSON.stringify(localStorage));
            // const sessionStorageData = encodeURIComponent(JSON.stringify(sessionStorage));
            // const page = encodeURIComponent(this.$route.name);
            // const timeStamp = encodeURIComponent(new Date().toLocaleString());

            const mailTo = `mailto:mafarmer+scrumapp@gmail.com?subject=${subject}`;

            return mailTo;
        }
    },
    methods: {
        copyToClipboard() {
            navigator.clipboard.writeText(this.fullErrorMsg)
                .then(() => {
                    alert('Content copied to clipboard!');
                })
                .catch(err => {
                    console.error('Could not copy text: ', err);
                });
        }
    }
}
</script>
