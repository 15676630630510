<template>
    <div class="match-manager-container">
        <h1>Match Manager (your team)</h1>
        <div class="calendar-events" v-if="events.length > 0">
            <table class="match-events">
                <thead>
                    <tr>
                        <th class="event-date">Date</th>
                        <th class="event-summary">Match</th>
                    </tr>
                </thead>
                <tbody v-if="!selectedEvent">
                    <tr class="event-row" @click="selectEvent()">
                        <td colspan="3"></td>
                    </tr>
                    <tr class="event-row" v-for="(event, index) in events" :key="index" @click="selectEvent(event)">
                        <td class="event-date" v-if="event.start.date">{{ this.$formatDate(event.start.date, "M/d") }}</td>
                        <td class="event-date" v-if="event.start.dateTime">{{ this.$formatDate(event.start.dateTime, "MM/dd HH:mm") }}</td>
                        <td class="event-summary">{{ event.summary.replace("Match:", "") }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr class="event-row" @click="selectEvent()">
                        <td colspan="3">
                            
                        </td>
                    </tr>
                    <tr class="event-row" @click="selectEvent()">
                        <td class="event-date" v-if="selectedEvent.start.date">{{ this.$formatDate(selectedEvent.start.date, "M/d") }}</td>
                        <td class="event-date" v-if="selectedEvent.start.dateTime">{{ this.$formatDate(selectedEvent.start.dateTime, "M/d HH:mm") }}</td>
                        <td>{{ selectedEvent.summary.replace("Match:", "") }}</td>
                    </tr>

                </tbody>
            </table>
        </div>
        <hr />
        <div class="match-manager" v-if="selectedEvent">
            <table class="match-info">
                <tr>
                    <td colspan="3"></td>
                </tr>

                <!--Scheduling-->
                <tr class="section-header" @click="showHideScheduling">
                    <td colspan="3">
                        <div class="section-header">Scheduling</div>
                    </td>
                </tr>
                <tr v-if="showScheduling">
                    <td>Team</td>
                    <td>
                        {{ this.$store.state.currentMember.team.name }}
                    </td>
                </tr>
                <tr v-if="showScheduling">
                    <td>Division</td>
                    <td>
                        <select v-model="this.match.division">
                            <option disabled :value=null>Select or Add New</option>
                            <option v-for="(option, index) in divisions" :key="index" :value="option">{{ option }}</option>
                        </select>
                        <img :src="addNewIcon" alt="Add New Division" class="add-new" @click="this.addNew('division')">
                    </td>
                </tr>
                <tr v-if="showScheduling">
                    <td>Opponent</td>
                    <td>
                        <select v-model="this.match.opponent">
                            <option disabled :value=null>Select or Add New</option>
                            <option v-for="(option, index) in opponents" :key="index" :value="option">{{ option }}</option>
                        </select>
                        <img :src="addNewIcon" alt="Add New Opponent" class="add-new" @click="this.addNew('opponent')">
                    </td>
                </tr>
                <tr v-if="showScheduling">
                    <td>Date</td>
                    <td>
                        <input type="date" :value="this.$formatDate(this.match.date, 'yyyy-MM-dd')"
                            @input="updateDate($event.target.value)" placeholder="Date" />
                    </td>
                </tr>
                <tr v-if="showScheduling">
                    <td>Time</td>
                    <td><input type="time" v-model="this.match.time" placeholder="Time" /></td>
                </tr>
                <tr v-if="showScheduling">
                    <td>Pitch</td>
                    <td>
                        <select v-model="this.match.pitch">
                            <option disabled :value=null>Select Pitch</option>
                            <option value="home">Home</option>
                            <option value="away">Away</option>
                        </select>
                    </td>
                </tr>
                <tr v-if="showScheduling && this.pitch == 'away'">
                    <td>Pitch Address</td>
                    <td><input type="text" v-model="this.match.location" placeholder="Enter Full Address" /></td>
                </tr>
                <tr v-if="showScheduling">
                    <td>Tournament</td>
                    <td>
                        <select v-model="this.match.tournament">
                            <option disabled :value=null>Select or Add New (optional)</option>
                            <option v-for="(option, index) in tournaments" :key="index" :value="option">{{ option }}
                            </option>
                        </select>
                        <img :src="addNewIcon" alt="Add New Tournament" class="add-new" @click="this.addNew('tournament')">
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <hr />
                    </td>
                </tr>

                <tr class="section-header" @click="showHideStrategy">
                    <td colspan="3">
                        <div class="section-header">Strategy</div>
                    </td>
                </tr>
                <tr v-if="showStrategy">
                    <td>Roster</td>
                    <td>
                        <select v-model="this.match.roster">
                            <option disabled :value=null>Select or Build New</option>
                            <option v-for="(option, index) in rosters" :key="index" :value="option">{{ option }}</option>
                        </select>
                        <img :src="rostersManageIcon" alt="Build New Roster" class="add-new" @click="this.addNewRoster">
                    </td>
                </tr>
                <tr v-if="showStrategy">
                    <td>Referee</td>
                    <td>
                        <select v-model="this.match.referee">
                            <option disabled :value=null>Select or Add New</option>
                            <option v-for="(option, index) in referees" :key="index" :value="option">{{ option }}</option>
                        </select>
                        <img :src="addNewIcon" alt="Add New Referee" class="add-new" @click="this.addNew('referee')">
                    </td>
                </tr>
                <tr v-if="showStrategy">
                    <td>Opponent Roster</td>
                    <td><textarea v-model="this.match.notes" placeholder="Enter Opponent's Roster"></textarea></td>
                </tr>
                <tr>
                    <td colspan="3">
                        <hr />
                    </td>
                </tr>

                <tr class="section-header" @click="showHideGamePlayed">
                    <td colspan="3">
                        <div class="section-header">Game Played</div>
                    </td>
                </tr>
                <tr v-if="showGamePlayed">
                    <td>Result</td>
                    <td>
                        <select v-model="this.match.referee">
                            <option disabled :value="null">Unplayed</option>
                            <option :value="win" selected>Win</option>
                            <option :value="loss" selected>Loss</option>
                            <option :value="tie" selected>Tie</option>

                        </select>
                    </td>
                </tr>
                <tr v-if="showGamePlayed">
                    <td>Our Score</td>
                    <td><input type="number" increment="1" v-model="this.match.ourScore" placeholder="0" /></td>
                </tr>
                <tr v-if="showGamePlayed">
                    <td>Our Scoring</td>
                    <td>
                        <table class="scoring-table">
                            <tr>
                                <td>T</td>
                                <td>C</td>
                                <td>PK</td>
                                <td>DG</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><input v-model="this.match.ourScoring.try" class="scoring-input" type="number" /></td>
                                <td><input v-model="this.match.ourScoring.conversion" class="scoring-input" type="number" />
                                </td>
                                <td><input v-model="this.match.ourScoring.penaltyKick" class="scoring-input"
                                        type="number" /></td>
                                <td><input v-model="this.match.ourScoring.dropGoal" class="scoring-input" type="number" />
                                </td>
                                <td>
                                    <div class="scoring-total">
                                        <div v-if="match.ourScoring">{{ totalScore }}</div>
                                        <div v-else>0</div>
                                    </div>
                                </td>
                            </tr>
                        </table>


                    </td>
                </tr>
                <tr v-if="showGamePlayed">
                    <td>Opponent Score</td>
                    <td><input type="text" v-model="this.match.opponentScore" placeholder="0" /></td>
                </tr>
                <tr v-if="showGamePlayed">
                    <td>Opponent Scoring</td>
                    <td>

                        <table class="scoring-table">
                            <tr>
                                <td>T</td>
                                <td>C</td>
                                <td>PK</td>
                                <td>DG</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><input v-model="this.match.opponentScoring.try" class="scoring-input" type="number" />
                                </td>
                                <td><input v-model="this.match.opponentScoring.conversion" class="scoring-input"
                                        type="number" /> </td>
                                <td><input v-model="this.match.opponentScoring.penaltyKick" class="scoring-input"
                                        type="number" /></td>
                                <td><input v-model="this.match.opponentScoring.dropGoal" class="scoring-input"
                                        type="number" /> </td>
                                <td>
                                    <div class="scoring-total">
                                        <div v-if="match.opponentScoring">{{ totalOpponentScore }}</div>
                                        <div v-else>0</div>
                                    </div>
                                </td>
                            </tr>
                        </table>

                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <hr />
                    </td>
                </tr>

                <tr class="section-header" @click="showHidePostGame">
                    <td colspan="3">
                        <div class="section-header">Post Game</div>
                    </td>
                </tr>
                <tr v-if="showPostGame">
                    <td>Player of the Match</td>
                    <td>
                        <select v-model="this.match.playerOfTheMatch">
                            <option :value="null" disabled>Select Player</option>
                            <option v-for="player in allPlayers" :key="player._id" :value="player._id">
                                {{ player.name }}
                            </option>
                        </select>
                    </td>
                </tr>
                <tr v-if="showPostGame">
                    <td>Media</td>
                    <td><input type="text" v-model="this.match.media" placeholder="(Youtube link)" /></td>
                </tr>
                <tr>
                    <td colspan="3">
                        <hr />
                    </td>
                </tr>


                <tr class="section-header" @click="showHideMisc">
                    <td colspan="3">
                        <div class="section-header">Misc</div>
                    </td>
                </tr>
                <tr v-if="showMisc">
                    <td>Notes</td>
                    <td><textarea v-model="this.match.notes" placeholder="Notes"></textarea></td>
                </tr>


            </table>
            <hr />
            <button @click="save">Save</button>
            <hr />
        </div>
    </div>
</template>

<style scoped>

.match-events {
    table-layout: fixed;
    width: 100%;
}

.event-date {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-right: 10px;
    text-align: right;

}

.event-time {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-right: 10px;

}

.event-summary {

    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-right: 10px;


}

.match-info {
    table-layout: fixed;
    width: 100%;
}

.match-info td {
    vertical-align: middle;
}

.match-info td:nth-child(1) {
    vertical-align: middle;
    text-align: right;
    padding-right: 10px;
    padding-top: 20px;
}

.match-info td:nth-child(2) {
    text-align: left;
    padding-left: 10px;
    padding-top: 20px;
}

.section-header {
    cursor: pointer;
    text-align: left;
    vertical-align: middle;
}

.match-manager-container {
    width: 100%;
}

.event-row {
    cursor: pointer;
}

.section-header {
    font-weight: bold;
    background-color: var(--alternate-color);
}

.add-new {
    cursor: pointer;
}

.scoring-input {
    width: 36px !important;
}

.scoring-table {
    border-spacing: 0;
    border-collapse: collapse;
}

.scoring-table tr td {
    padding: 0;
    margin: 0;
    text-align: center;
    vertical-align: middle;
}

.scoring-total {
    padding-left: 10px;
    font-weight: bold;
    font-size: 20px;
}

.match-manager {
    width: 100%
}

@media (min-width: 768px) {

    .match-info input,
    .match-info select,
    .match-info textarea {
        width: 100%;
    }
}
</style>

<script>
import { apiGet, apiGetNoCache, apiPost } from '@/api';
import { cleanObject } from '@/common';
import addNewIcon from '@/../images/icons/add.svg';
import rostersManageIcon from '@/../images/icons/rosters.svg';
import router from '@/router';


export default {
    data() {
        return {
            match: {
                team: null,
                division: null,
                tournament: null,
                opponent: null,
                date: null,
                time: null,
                pitch: null,
                location: null,
                roster: null,
                referee: null,
                notes: null,
                result: null,
                ourScore: 0,
                ourScoring: {
                    try: null,
                    conversion: null,
                    penaltyKick: null,
                    dropGoal: null,
                },
                opponentScore: 0,
                opponentScoring: {
                    try: null,
                    conversion: null,
                    penaltyKick: null,
                    dropGoal: null,
                },
                playerOfTheMatch: null,
                media: null,
            },

            addNewIcon: addNewIcon,
            rostersManageIcon: rostersManageIcon,

            initialData: null,

            events: [],
            selectedEvent: null,

            tournaments: [],
            referees: [],
            opponents: [],
            rosters: [],
            divisions: [],

            showScheduling: true,
            showStrategy: false,
            showGamePlayed: false,
            showPostGame: false,
            showMisc: false,
        };
    },
    methods: {

        showHideScheduling() {
            this.showScheduling = !this.showScheduling;
        },
        showHideStrategy() {
            this.showStrategy = !this.showStrategy;
        },
        showHideGamePlayed() {
            this.showGamePlayed = !this.showGamePlayed;
        },
        showHidePostGame() {
            this.showPostGame = !this.showPostGame;
        },
        showHideMisc() {
            this.showMisc = !this.showMisc;
        },

        updateDate(value) {
            this.match.date = this.$formatDate(value, 'yyyy-MM-dd');
        },
        selectEvent(event) {
            this.selectedEvent = event;
            const foundMatch = this.matches.find(match => match.eventId === this.selectedEvent?.id);
            this.match = Object.assign({}, this.match, foundMatch);
            this.backup = { ...this.match };
        },
        hasDataChanged() {
            return JSON.stringify(this.match) !== JSON.stringify(this.backup);
        },
        cleanObject,

        async save() {

            const savedMatch = await apiPost('matches', cleanObject(this.match));
            if (savedMatch.success) {
                //TODO: add and update successMessage, errorMessage, and infoMessage
            }
        },
        addNew(property) {
            let newOption = window.prompt("Please enter the new " + property);

            if (newOption) {
                // Check if the newOption already exists in the respective array. If not, add it.
                if (!this[property + 's'].includes(newOption)) {
                    this[property + 's'].push(newOption);

                    // Set the newly added option as the selected one
                    this.match[property] = newOption;
                } else {
                    alert("This " + property + " already exists.");
                }
            }
        },
        addNewRoster() {
            if (this.hasDataChanged()) {
                if (confirm("You have unsaved work. Do you want to save changes then go to Roster Builder?")) {
                    const body = {
                        tournament: this.tournament,
                        pitch: this.pitch,
                        roster: this.roster,
                        referee: this.referee,
                        opponent: this.opponent,
                        playerOfTheMatch: this.playerOfTheMatch,
                    };

                    apiPost('matches', body).then(() => {
                        router.push('/rosters-manager');
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            } else {
                router.push('/rosters-manager');
            }
        },
    },
    async mounted() {
        const calendarEvents = await apiGet('calendar/events');
        const team = this.$store.state.currentMember.team.name;

        this.events = calendarEvents.filter(event => {
            const summaryLowerCase = event.summary.toLowerCase();
            const teamLowerCase = team.toLowerCase();
            // The RegExp looks for a word boundary (\b) followed by the team name
            const regex = new RegExp("\\b" + teamLowerCase);
            return summaryLowerCase.startsWith('match:') && summaryLowerCase.search(regex) !== -1;
        });

        this.matches = await apiGetNoCache('matches')

        this.tournaments = [...new Set(this.matches.map(match => match.tournament))].sort();
        this.referees = [...new Set(this.matches.map(match => match.referee))].sort();
        this.divisions = [...new Set(this.matches.map(match => match.division))].sort();
        this.opponents = [...new Set(this.matches.map(match => match.opponent))].sort();

        this.rosters = await apiGetNoCache('rosters');

    },
    watch: {
        totalScore(newTotalScore) {
            this.match.ourScore = newTotalScore;
        },
        totalOpponentScore(newTotalScore) {
            this.match.opponentScore = newTotalScore;
        },

    },
    computed: {
        totalScore() {
            return (this.match.ourScoring.try || 0) * 5 +
                (this.match.ourScoring.conversion || 0) * 2 +
                (this.match.ourScoring.penaltyKick || 0) * 3 +
                (this.match.ourScoring.dropGoal || 0) * 3;
        },
        totalOpponentScore() {
            return (this.match.opponentScoring.try || 0) * 5 +
                (this.match.opponentScoring.conversion || 0) * 2 +
                (this.match.opponentScoring.penaltyKick || 0) * 3 +
                (this.match.opponentScoring.dropGoal || 0) * 3;
        },
        allPlayers() {
            if (!this.match.roster) {
                return [];
            }
            const positions = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen'];
            let players = positions.map(pos => this.match.roster[pos]).filter(Boolean);
            players = players.concat(this.match.roster.subs);
            return players;
        },
    },




};
</script>
