import { createRouter, createWebHistory } from "vue-router";
import EnterPassword from "./components/EnterPassword.vue";
import LoginPage from "./components/LoginPage.vue";
import NotAuthorized from "./components/NotAuthorized.vue";
import RegisterUser from "./components/RegisterUser.vue";
import RegisterMember from "./components/RegisterMember.vue";
import VerifyEmail from "./components/VerifyEmail.vue";
import VerifyAccount from "./components/VerifyAccount.vue";
import UserProfile from "./components/UserProfile.vue";
import MemberProfile from "./components/MemberProfile.vue";
import EditMember from "./components/EditMember.vue";
import ActivateUsers from "./components/ActivateUsers.vue";
import ForgotPassword from "./components/ForgotPassword.vue";
import ResetPassword from "./components/ResetPassword.vue";
import LogPayments from "./components/LogPayments.vue";
import AllMembers from "./components/AllMembers.vue";
import CalendarPage from "./components/CalendarPage.vue";
import MatchAvailability from "./components/MatchAvailability.vue";
import PracticeAvailability from "./components/PracticeAvailability.vue";
import MatchCalendar from "./components/MatchCalendar.vue";
import MakePayment from "./components/MakePayment.vue";
import ClubComposite from "./components/ClubComposite.vue";
import ClubFaqs from "./components/ClubFAQs.vue";
import ViewPayments from "./components/ViewPayments.vue";
import HomePage from "./components/HomePage.vue";
import MatchManager from "./components/MatchManager.vue";
import MatchHistory from "./components/MatchHistory.vue";
import RostersManager from "./components/RostersManager.vue";
import FieldPositions from "./components/FieldPositions.vue";
import ClubInfo from "./components/ClubInfo.vue";
import PaymentDesignations from "./components/PaymentDesignations.vue";
import TermsConditions from "./components/TermsConditions.vue";
import PrivacyPolicy from "./components/PrivacyPolicy.vue";
import AttendanceReport from "./components/AttendanceReport.vue";
import PracticeAttendance from "./components/PracticeAttendance.vue";

import ComingSoon from "./components/ComingSoon.vue";

import CustomError from "./components/CustomError.vue";

import store from './store';

const Init = {
  template: "<div></div>",
};

const routes = [
  {
    path: "/",
    name: "Init",
    component: Init,
    props: true,
    meta: {},
  },
  {
    path: "/not-authorized/:redirectTo",
    name: "NotAuthorized",
    component: NotAuthorized,
    props: true,
    meta: { title: 'Not Authorized' },
  },
  {
    path: "/login-page",
    name: "LoginPage",
    component: LoginPage,
    props: true,
    meta: { title: 'Log In' },
  },
  {
    path: "/enter-password",
    name: "EnterPassword",
    component: EnterPassword,
    props: true,
    meta: { title: 'Enter Password' },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { title: "Forgot Password" },
  },

  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: ResetPassword,
    props: true,
    meta: { title: 'Reset Password' },
  },

  {
    path: "/reset-password",
    name: "ResetPasswordNoToken",
    component: ResetPassword,
    props: true,
    meta: { title: 'Reset Password' },
  },

  {
    path: "/home-page",
    name: "HomePage",
    component: HomePage,
    props: true,
    meta: {
      title: 'Dashboard'
    },
  },
  {
    path: "/verify-account/:token/:memberId",
    name: "VerifyAccount",
    component: VerifyAccount,
    props: true,
    meta: {
      title: 'Verify Account',
    },
  },

  {
    path: "/register-user/:token",
    name: "RegisterUser",
    component: RegisterUser,
    props: true,
    meta: {
      title: 'Register'
    },
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    component: VerifyEmail,
    props: true,
    meta: {
      title: 'Verify'
    },
  },
  {
    path: "/user-profiles",
    name: "UserProfiles",
    component: UserProfile,
    props: true,
    meta: {
      title: 'User Profiles',
      requiresAuth: true,
      roles: ["MANAGE_USERS"],
    },
  },
  {
    path: "/activate-users",
    name: "ActivateUsers",
    component: ActivateUsers,
    meta: {
      title: 'Activate Profiles',
      requiresAuth: true,
      roles: ["MANAGE_USERS"],
    },
  },
  {
    path: "/register-member",
    name: "RegisterMember",
    component: RegisterMember,
    props: true,
    meta: {
      title: 'Register Member',
    },
  },
  {
    path: "/my-profile",
    name: "MyProfile",
    component: MemberProfile,
    meta: { title: 'My Profile' },
  },
  {
    path: "/member-profile/:memberId",
    name: "MemberProfile",
    component: MemberProfile,
    props: true,
    meta: {
      title: 'Member Profile',
      requiresAuth: true,
      roles: ["VIEW_MEMBERS", "MANAGE_MEMBERS"],
    },
  },
  {
    path: "/edit-me",
    name: "EditMe",
    component: EditMember,
    props: true,
    meta: {
      title: 'Edit My Profile',
    },
  },
  {
    path: "/edit-member/:memberId",
    name: "EditMember",
    component: EditMember,
    props: true,
    meta: {
      title: 'Edit Member',
      requiresAuth: true,
      roles: ["MANAGE_MEMBERS"],
    },
  },
  {
    path: "/all-members",
    name: "AllMembers",
    component: AllMembers,
    props: true,
    meta: {
      title: 'Club Members',
      requiresAuth: true,
      roles: ["VIEW_MEMBERS"],
    },
  },
  {
    path: "/club-composite",
    name: "ClubComposite",
    component: ClubComposite,
    props: true,
    meta: {
      title: 'Club Composite'
    },
  },
  {
    path: "/club-faqs",
    name: "ClubFaqs",
    component: ClubFaqs,
    props: true,
    meta: {
      title: 'Club FAQs'
    },
  },  
  {
    path: "/club-info",
    name: "ClubInfo",
    component: ClubInfo,
    meta: {
      title: 'Club Info'
    }
  },

  {
    path: "/log-payments",
    name: "LogPayments",
    component: LogPayments,
    meta: {
      title: 'Log Payments',
      requiresAuth: true,
      roles: ["MANAGE_PAYMENTS"],
    },
  },
  {
    path: "/make-payment",
    name: "MakePayment",
    component: MakePayment,
    meta: { title: 'Make a Payment' },
  },
  {
    path: "/view-payments",
    name: "ViewPayments",
    component: ViewPayments,
    meta: {
      title: 'Payments',
      requiresAuth: true,
      roles: ["VIEW_PAYMENTS", "MANAGE_PAYMENTS"],
    },
  },
  {
    path: "/calendar-page",
    name: "CalendarPage",
    component: CalendarPage,
    props: { eventType: 'clubevent', attendance: false },
    meta: { title: 'Calendar of Events' },
  },

  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: ComingSoon,
    meta: { title: 'New Features Coming Soon' },
  },

  {
    path: "/match-availability",
    name: "MatchAvailability",
    component: MatchAvailability,
    props: { eventType: 'match', attendance: true },
    meta: { title: 'Match Availability' },
  },

  {
    path: "/practice-availability",
    name: "PracticeAvailability",
    component: PracticeAvailability,
    props: { eventType: 'practice', attendance: true },
    meta: { title: 'Practice Availability' },
  },

  {
    path: "/match-calendar",
    name: "MatchCalendar",
    component: MatchCalendar,
    props: { eventType: 'match', attendance: false },
    meta: { title: 'Match Availability' },
  },

  {
    path: "/match-manager",
    name: "MatchManager",
    component: MatchManager,
    meta: {
      title: 'Match Manager',
      requiresAuth: true,
      roles: ["MANAGE_MATCHES"],
    },
  },

  {
    path: "/field-positions",
    name: "FieldPositions",
    component: FieldPositions,
    props: true,
    meta: {
      title: 'Field Positions',
      // requiresAuth: true,
      // roles: ["MANAGE_MATCHES"],
    },
  },

  {
    path: "/attendance-report",
    name: "AttendanceReport",
    component: AttendanceReport,
    meta: {
      title: 'Attendance Report',
      requiresAuth: true,
      roles: ["MANAGE_MATCHES"],
    },
  },
  {
    path: "/practice-attendance",
    name: "PracticeAttendance",
    component: PracticeAttendance,
    meta: {
      title: 'Practice Attendance',
      requiresAuth: true,
      roles: ["MANAGE_MATCHES"],
    },
  },


  {
    path: "/match-history",
    name: "MatchHistory",
    component: MatchHistory,
    meta: { title: 'Match History' },
  },

  // Rosters Manager
  {
    path: "/rosters-manager",
    name: "RostersManager",
    component: RostersManager,
    meta: {
      title: 'Rosters Manager',
      // requiresAuth: true,
      // roles: ["MANAGE_ROSTERS"],

    },
  },

  // Payment Designations
  {
    path: "/payment-designations",
    name: "PaymentDesignations",
    component: PaymentDesignations,
    meta: {
      title: 'Payment Designations',
      requiresAuth: true,
      roles: ["MANAGE_PAYMENTS"],
    },
  },

  // Terms and Conditions
  {
    path: "/terms-conditions",
    name: "TermsConditions",
    component: TermsConditions,
    meta: { title: 'Terms & Conditions' },
  },
  // Privacy Policy
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: { title: 'Privacy Policy' },
  },

  // Custom Error
  {
    path: '/custom-error/:errorMsg',
    name: 'CustomError',
    component: CustomError,
    props: true,
    meta: { title: 'error' },
  }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
  store,
  scrollBehavior() {
    return { top: 0 }
  }
});

router.beforeEach(async (to, from, next) => {
  // Ensure we're not already on the site defined by VUE_APP_WEB_URL
  if (window.location.origin !== process.env.VUE_APP_WEB_URL) {
    // If not, redirect to VUE_APP_WEB_URL
    window.location.href = process.env.VUE_APP_WEB_URL;
  }
  else {
    const isAuthenticated = store.getters.isAuthenticated;

    const openRoutes = [
      'EnterPassword',
      'VerifyEmail',
      'VerifyAccount',
      'ForgotPassword',
      'ResetPassword',
      'RegisterUser',
      'RegisterMember',
      'LoginPage',
      'TermsConditions',
      'PrivacyPolicy'
    ]; // Add any other route name that should be accessible without login

    document.title = 'SCRUM app | ' + to.meta.title;

    // If user tries to access login page while logged in, log them out
    if (to.name === 'LoginPage') {
      await store.dispatch('logout'); // This will log out the user
      next(); // Go to the login page
      return;
    }

    // Redirect to HomePage if already logged in and trying to access '/'
    if (isAuthenticated && (to.path === '/')) {
      next({ name: 'HomePage' });
      return;
    }

    // Always go to LoginPage if not authenticated, except for open routes
    if (!isAuthenticated && !openRoutes.includes(to.name)) {
      await store.dispatch('logout'); // This will log out the user
      next({ name: 'LoginPage' });
      return;
    }

    // check if route requires authorization
    if (to.meta.requiresAuth) {
      // check if user is authorized
      const isAuthorized = store.getters.isAuthorized(to.meta.roles);
      if (!isAuthorized) {
        next({ name: 'NotAuthorized', params: { redirectTo: to.fullPath } });
        return;
      }
    }

    store.commit('setCurrentPage', from.name);

    // Continue as normal for other cases
    next();
  }
});


export default router;
