<template>
  <div id="register-user-page">
    <h1>Register User</h1>
    <div class="logo">
      <img :src="logo" class="logo-image" />
    </div>
    <div class="registration-form">
      <p>To manage your profile you will need to create a password.
      </p>

      <label><b>{{ this.clubName }}</b></label>
      <br />
      <label>{{ this.email }}</label>
      <br />
      <br />
      <div class="password-container">
        <input ref="passwordInput" :type="passwordVisible ? 'text' : 'password'" v-model="password"
          placeholder="Enter password" @keydown.enter="attemptLogin" />
        <img :src="showPasswordImage" class="show-password-toggle" @click="passwordVisible = !passwordVisible" />
      </div>
      <br />
      <div class="password-container">
        <input :type="passwordVisible ? 'text' : 'password'" v-model="confirmPassword" placeholder="Confirm password"
          @keydown.enter="attemptLogin" />
      </div>
      <br />
      <div class="terms">
        <input type="checkbox" v-model="termsAccepted" id="terms">
        <label for="terms">I accept the </label><a :href="`${webUrl}/terms-conditions`" target="_blank"
          rel="noopener noreferrer">Terms and Conditions</a>
      </div>
      <br />
      <button @click="registerUser">Register</button>
      <br />
      <p v-if="infoMessage" class="info-message">{{ infoMessage }}</p>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
      <br />
      <button @click="goToLoginPage">Back To Sign In</button>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost } from '@/api';
import logo from '@/../images/crest.png';
import showPasswordImage from '@/../images/icons/show.svg';
const currentTermsVersion = process.env.VUE_APP_CURRENT_TERMS;

export default {
  data() {
    return {
      token: "",
      email: "",
      password: "",
      confirmPassword: "",
      clubId: process.env.VUE_APP_CLUB_ID,
      clubName: "",
      logo: logo,
      passwordVisible: false,
      showPasswordImage: showPasswordImage,
      termsAccepted: false,
      infoMessage: "",
      errorMessage: "",
      successMessage: "",
    };
  },
  async mounted() {
    this.token = this.$route.params.token;
    this.member = this.$route.query.member;
    const memberData = await apiPost(`auth/check-member`, { member: this.member });
    this.email = memberData.email;
    this.clubName = await this.getClubName();
  },
  methods: {
    async showMessage(message, messageType = "info") {
      if (messageType == "success") {
        this.successMessage = message;
        this.infoMessage = "";
        this.errorMessage = "";
      }
      if (messageType == "info") {
        this.successMessage = "";
        this.infoMessage = message;
        this.errorMessage = "";
      }
      if (messageType == "error") {
        this.successMessage = "";
        this.infoMessage = "";
        this.errorMessage = message;
      }

    },
    async registerUser() {
      if (!this.termsAccepted) {
        this.showMessage("You must accept the Terms and Conditions.", "error");
        return;
      }

      if (!this.email || !this.password || !this.confirmPassword) {
        this.showMessage("Email, password, and confirm password are required.", "error");
        return;
      }

      if (this.password !== this.confirmPassword) {
        this.showMessage("Password and confirm password do not match.", "error");
        return;
      }

      try {
        // Validate the token using the new endpoint
        const tokenValidation = await apiPost(`auth/verify-token`, { token: this.token, email: this.email });
        if (!tokenValidation.valid) {
          this.showMessage("Invalid or expired token.", "error");
          return;
        }

        const userData = await apiPost(`auth/check-user`, { email: this.email });

        if (userData.exists) {
          if (userData.status === 'pending') {
            this.showMessage("Your account is still pending for approval. You will be notified once activated.");
          } else if (userData.status === 'active') {
            this.showMessage("Your account is already active. You will be redirected to the login page.");
            setTimeout(() => {
              this.$router.push({ name: "LoginPage" });
            }, 5000);
          }
        } else {
          const memberData = await apiPost(`auth/check-member`, { member: this.member });
          const memberId = memberData.memberId;

          const response = await apiPost(`auth/register-user`, {
            memberId: memberId,
            email: this.email,
            password: this.password,
            status: "pending",
            termsAcceptedVersion: this.termsAcceptedVersion,
            termsAcceptedDate: this.termsAcceptedDate,
          });

          if (response.success) {
            await apiPost(`mail/register-user`, { email: this.email });
            this.showMessage("Registration was successful. Please wait for account activation.", "success");

            // Email admin
            await apiPost(`mail/email-support`, { subject: `pending registration`, message: `There is a new pending user registration request for ${this.email}.` });

            setTimeout(() => {
              this.$router.push({ name: "LoginPage" });
            }, 5000);
          } else {
            throw new Error("Registration failed");
          }
        }
      }
      catch (error) {
        console.error("Error during registration:", error);
        this.showMessage("An error occurred. Please try again.", "error");
      }
    },
    async getClubName() {
      const response = await apiGet(`clubs/${this.clubId}`);
      return response.name;
    },
    goToLoginPage() {
      this.$router.push('/login-page');
    }
  },
  computed: {
    webUrl() {
      return process.env.VUE_APP_WEB_URL;
    },
    termsAcceptedVersion() {
      return this.termsAccepted ? currentTermsVersion : null;
    },
    termsAcceptedDate() {
      return this.termsAccepted ? new Date() : null;
    },
  },
};
</script>

<style>
.logo-image {
  max-width: 200px;
  height: auto;
}

.show-password-toggle {
  cursor: pointer;
  height: 20px;
  margin-left: -20px;
  vertical-align: middle;
}

.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}

.success-message {
  color: green;
  text-align: center;
  margin-bottom: 20px;
}

.info-message {
  color: black;
  text-align: center;
  margin-bottom: 20px;
}
</style>