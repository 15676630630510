<template>
    <div v-if="isLoading">Loading member profile...</div>
    <div v-else>
        <div v-if="member">

            <div class="profile">
                <div class="profile-image-container">
                    <div @click="selectImage">
                        <cropper v-if="tempImageUrl" :src="tempImageUrl" class="profile-image" ref="cropper"
                            @newImage="resetCropArea" :stencil-props="{ aspectRatio: 1, aspectRatioPriority: true }" />
                        <img v-else :src="profileImageUrl" class="profile-image" />
                        <img v-if="!this.tempImageUrl" :src="photoIcon" class="select-photo" />
                    </div>
                    <br />
                    <button v-if="tempImageUrl" @click="uploadCroppedImage">Save Photo</button>
                    <div v-if="this.userExists">
                        <button v-if="isAuthorized(['MANAGE_USERS'])" @click="goToUser">User Profile</button>
                    </div>
                    <div v-else>
                        <button v-if="!member.dateInvited" @click="invite">Invite</button>
                        <p v-if="member.dateInvited">
                            Invited: {{ this.$formatDate(member.dateInvited, "MM/dd/yy HH:mm") }}
                        </p>
                    </div>

                </div>
                <div class="profile-info">
                    <h3>
                        {{ displayName(member, { lastNameFirst: false, showAlias: true, showNickname: true }) }}
                    </h3>

                    <div :class="getStatusClass(this.memberStatus)">
                        {{ this.memberStatus }}
                    </div>

                    <p>
                        {{ this.$formatDate(member.birthDate, "MM/dd/yyyy") }}
                        <br />
                        ({{ getAge(member.birthDate) }} yrs old)
                    </p>
                    <p>
                        <a :href="'mailto:' + member.email">{{ member.email }}</a>
                        <br />
                        <a :href="'tel:' + formatPhoneNumber(member.phoneNumber)">{{
                            formatPhoneNumber(member.phoneNumber)
                        }}</a>
                    </p>

                    <div class="cipp-info">
                        <div :class="getPlayerStatusClass(member.playerStatus)">
                            <img @click="goToEditMember" v-if="self || isAuthorized(['MANAGE_MEMBERS'])" :src="editIcon" class="edit-icon" alt="Edit Member" />

                            player status: {{ member.playerStatus ? member.playerStatus : "n/a" }}
                        </div>
                        <div class="cipp-number">
                            CIPP: {{ member.cipp ? member.cipp : 'n/a' }} {{ member.cippYear ? "(" + member.cippYear
                                + ")" : "" }}
                        </div>

                        <div v-if="member.cippExpiration"
                            :class="{ 'red-text': today > normalizedExpiry(member.cippExpiration), 'green-text': today <= normalizedExpiry(member.cippExpiration) }"
                            class="cipp-expiry">
                            expire{{ (today > normalizedExpiry(member.cippExpiration)) ? "d" : "s" }} on {{
                                this.$formatDate(member.cippExpiration, "MM/dd/yy") }}
                        </div>

                    </div>

                </div>
            </div>
            <hr />

            <div v-if="self || isAuthorized(['VIEW_PAYMENTS', 'MANAGE_PAYMENTS'])" class="payment-history">
                <div @click="showHidePayments" class="section-header">
                    Payment History
                    <img v-if="this.showPayments" :src="showImage" alt="Show">
                    <img v-if="!this.showPayments" :src="hideImage" alt="Hide">
                </div>
                <div v-if="this.showPayments">
                    <div v-if="payments.length > 0" class="payments">

                        <!--filter-->
                        <div>
                            <select v-model="filteredDesignation">
                                <option value="All">All</option>
                                <option v-for="designation in filteredPaymentDesignations" :key="designation._id"
                                    :value="designation._id">
                                    {{ designation.name }}
                                </option>
                            </select>
                            <button @click="goToMakePayment" class="pay-button">Pay</button>

                        </div>

                        <div v-if="filteredDesignation !== 'All'">
                            <div v-if="getPaymentDesignation(filteredDesignation).type === 'trip'">
                                <p>Paid
                                    ${{ computePaymentTotal(this.payments, filteredDesignation) }}
                                    of
                                    ${{ getPaymentDesignation(filteredDesignation).tripAmount }}.</p>
                                <p>Due
                                    ${{ ((getPaymentDesignation(filteredDesignation).tripAmount -
                                        computePaymentTotal(this.payments, filteredDesignation))) }}
                                </p>

                            </div>

                            <div v-else-if="getPaymentDesignation(filteredDesignation).type === 'dues'">
                                <p>Paid ${{ computePaymentTotal(this.payments, filteredDesignation) }}</p>
                                <div>
                                    <p>Due
                                        ${{ calculateDueAmount(this.payments, this.getPaymentDesignation(filteredDesignation)) }}
                                    </p>
                                    <p v-if="isFullyPaid(this.payments, this.getPaymentDesignation(filteredDesignation))">
                                        You are all paid up! Thank you.
                                    </p>
                                </div>

                            </div>

                            <div v-else-if="getPaymentDesignation(filteredDesignation).type === 'donation'">
                                <p>Paid ${{ calculateTotalPaymentsForDesignation(this.payments,
                                    this.getPaymentDesignation(filteredDesignation)) }}.</p>
                            </div>

                            <div v-else>
                                <p>${{ calculateTotalPaymentsForDesignation(this.payments,
                                    this.getPaymentDesignation(filteredDesignation)) }} total.</p>
                            </div>
                        </div>
                        <hr />
                        <div class="scrollable-section">

                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th class="right-align">Amount</th>
                                        <th>Designation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="payment in filteredPayments" :key="payment._id.$oid">
                                        <td>{{ this.$formatDate(payment.date, "yyyy-MM-dd") }}</td>
                                        <td class="right-align">${{ payment.amount }}</td>
                                        <td>{{ payment.designation.name }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div v-else>
                        <p>No payments history</p>
                    </div>
                </div>
            </div>

            <hr />
            <div v-if="self || isAuthorized(['MANAGE_MATCHES'])" class="match-history">
                <div @click="showHideMatches" class="section-header">
                    Match History
                    <img v-if="this.showMatches" :src="showImage" alt="Show">
                    <img v-if="!this.showMatches" :src="hideImage" alt="Hide">
                </div>
                <div v-if="this.showMatches" class="matches">
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Opponent</th>
                                <th>Score</th>
                                <th>Tries</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="4" style="text-align: center;">no match history</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import { apiGet, apiPost, apiPostFile, apiPut } from '@/api';
import defaultImageUrl from '@/../images/default-profile-pic.jpg';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import venmobutton from '@/../images/venmo.png';
import photoIcon from '@/../images/icons/photo.svg';
import editIcon from '@/../images/icons/edit.svg';
import { calculateTotalPaymentsForDesignation, computePaymentTotal, calculateDueAmount, isFullyPaid } from '@/common';
import { displayName, getMemberStatus } from '@/common';
import showImage from '@/../images/icons/show.svg';
import hideImage from '@/../images/icons/hide.svg';

export default {
    data() {
        return {
            showImage: showImage,
            hideImage: hideImage,
            showPayments: false,
            showMatches: false,
            userExists: false,
            isLoading: true,
            memberId: null,
            member: null,
            payments: [],
            enteredAmount: "",
            enteredNote: "dues from portal",
            isEditing: false,
            designations: [],
            selectedDesignation: "",
            error: null,
            filteredDesignation: 'All',
            paymentDesignations: [],
            profileImageUrl: defaultImageUrl,
            tempImageUrl: null,
            stencilComponent: {
                aspectRatio: 1,
            },
            venmobutton: venmobutton,
            photoIcon: photoIcon,
            editIcon: editIcon,
            self: false,
            memberStatus: "",
        };
    },
    components: {
        Cropper,
    },
    computed: {
        ...mapGetters(['isAuthorized']),
        filteredPayments() {
            if (this.filteredDesignation === 'All') {
                return this.payments;
            }
            return this.payments.filter(payment => payment.designation._id === this.filteredDesignation);
        },
        filteredPaymentDesignations() {
            return this.paymentDesignations.filter(designation => {
                return this.payments.some(payment => payment.designation._id === designation._id);
            });
        },
        today() {
            let now = new Date();
            now.setHours(0, 0, 0, 0);
            return now;
        },
    },
    methods: {
        showHidePayments() {
            this.showPayments = !this.showPayments;
        },
        showHideMatches() {
            this.showMatches = !this.showMatches;
        },
        getMemberStatus,
        getStatusClass(status) {
            if (status === 'good standing') {
                return 'green-text';
            } else if (status === 'sanctioned') {
                return 'red-text';
            } else if (status === 'delinquent') {
                return 'orange-text';
            } else {
                return '';
            }
        },
        getPlayerStatusClass(status) {
            if (status === 'active') {
                return 'green-text';
            } else if (status === 'inactive') {
                return '';
            } else if (status === 'ir') {
                return 'orange-text';
            } else {
                return 'orange-text';
            }
        },
        async checkUser() {
            const userData = await apiPost(`auth/check-user`, { email: this.member.email });
            this.userExists = userData.exists;
        },
        displayName,
        goToUser() {
            this.$router.push('/user-profiles');
        },
        normalizedExpiry(expiry) {
            let date = new Date(expiry);
            date.setHours(0, 0, 0, 0);
            return date;
        },
        goToMakePayment() {
            this.$router.push('/make-payment');
        },
        async invite() {
            if (!confirm('Send email invitation to create account?')) {
                return;
            }
            try {
                const mailResponse = await apiPost(`mail/register-member`, { email: this.member.email });
                if (mailResponse.success) {
                    await apiPut(`members/${this.member._id}`, {
                        dateInvited: new Date()
                    });
                    this.member.dateInvited = new Date();
                    this.$store.commit('clearCache');
                }
            } catch (error) {
                console.error(error);
            }
        },
        goToEditMember() {
            if (this.self) {
                this.$router.push('/edit-me');
            }
            else {
                this.$router.push('/edit-member/' + this.memberId);
            }
        },
        formatPhoneNumber(phoneNumber) {
            // Ensure the phone number is a string
            if (phoneNumber) {
                phoneNumber = phoneNumber.toString();

                // Check if phone number has 11 digits and starts with '1'
                if (phoneNumber.length === 11 && phoneNumber.startsWith('1')) {
                    return "+1 (" + phoneNumber.slice(1, 4) + ") " + phoneNumber.slice(4, 7) + "-" + phoneNumber.slice(7);
                }

                // Check if phone number has 10 digits
                else if (phoneNumber.length === 10) {
                    return "(" + phoneNumber.slice(0, 3) + ") " + phoneNumber.slice(3, 6) + "-" + phoneNumber.slice(6);
                }

                // If phone number does not have 10 or 11 digits, return it as is
                return phoneNumber;
            }
        },
        async fetchMemberById(memberId) {
            try {
                const member = await apiGet(`members/${memberId}`);
                if (member) {
                    this.member = member;
                    this.fetchPayments(this.member._id);
                } else {
                    this.error = 'Member not found';
                }
            } catch (error) {
                this.error = 'Error fetching member';
            }
        },
        selectImage() {
            if (this.tempImageUrl) {
                return;
            }

            let input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.onchange = e => {
                const file = e.target.files[0];
                this.tempImageUrl = URL.createObjectURL(file);
            };
            input.click();
            this.$store.commit('clearCache');
        },
        async uploadCroppedImage() {
            if (!this.tempImageUrl) {
                return;
            }

            const result = this.$refs.cropper.getResult();
            const canvas = result.canvas;

            if (canvas) {
                // Create a new canvas to resize the image
                const resizeCanvas = document.createElement('canvas');
                resizeCanvas.width = 200;
                resizeCanvas.height = 200;
                const ctx = resizeCanvas.getContext('2d');

                // Draw the original canvas onto the new one, effectively resizing the image
                ctx.drawImage(canvas, 0, 0, resizeCanvas.width, resizeCanvas.height);

                const formData = new FormData();
                resizeCanvas.toBlob(async blob => {
                    formData.append("file", blob, `${this.member._id}.jpg`);
                    formData.append("memberId", this.member._id);
                    let config = {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    };
                    await apiPostFile('members/uploadProfilePic', formData, config);

                    // reload image
                    this.profileImageUrl = await this.getImageUrl();
                    // reset tempImageUrl
                    this.tempImageUrl = null;
                }, "image/jpeg");
            }


        },
        async getImageUrl() {
            try {

                // Check if the image exists
                const existsResponse = await apiGet(`members/getProfilePic/${this.memberId}`);
                if (existsResponse.exists) {
                    const response = await apiGet(`members/getSignedUrl/${this.memberId}`);
                    const imageResponse = await fetch(response.signedUrl);
                    if (imageResponse.ok) {
                        return response.signedUrl;
                    }
                }
                else {
                    // If the image does not exist, use the default image URL
                    return defaultImageUrl;
                }
            }
            catch (error) {
                // If there's an error in fetching the existence or signed URL,
                // use the default image URL
                return defaultImageUrl;
            }
        },
        getAge(dateString) {
            const dob = new Date(dateString);
            const today = new Date();
            let age = today.getFullYear() - dob.getFullYear();
            const monthDifference = today.getMonth() - dob.getMonth();
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
                age--;
            }
            return age;
        },
        generateVenmoUrl() {
            const baseVenmoUrl = 'https://venmo.com/severn-river';
            const amount = this.enteredAmount;

            // Get the selected designation name
            const selectedDesignationObj = this.designations.find(d => d._id === this.selectedDesignation);
            const note = encodeURIComponent(selectedDesignationObj ? selectedDesignationObj.name : '');

            return `${baseVenmoUrl}?txn=pay&amount=${amount}&note=${note}`;
        },
        async fetchPayments(memberId) {
            try {
                const data = await apiGet(`payments/member/${memberId}`);
                if (data.payments) {
                    this.payments = data.payments;
                } else {
                    console.error('Error fetching payments:', data.message);
                }
            } catch (error) {
                console.error('Error fetching payments:', error);
            }
        },
        async fetchDesignations() {
            try {
                this.designations = await apiGet('paymentDesignations');
            } catch (error) {
                console.error('Error fetching designations:', error);
            }
        },
        handleRouteChange(memberId) {
            const loggedInMemberMemberId = this.loggedInMember;
            if (!memberId) {
                memberId = loggedInMemberMemberId;
            }
        },
        async fetchActivePaymentDesignations() {
            try {
                const data = await apiGet('paymentDesignations');
                this.paymentDesignations = data.filter(item => item.status === 'active');
            } catch (error) {
                console.error('Error fetching payment designations:', error);
            }
        },
        getPaymentDesignationType(id) {
            const designation = this.paymentDesignations.find(d => d._id === id);
            return designation ? designation.type : null;
        },
        getPaymentDesignation(id) {
            const designation = this.paymentDesignations.find(d => d._id === id.toString());

            if (!designation) {
                console.error(`No payment designation found for id: ${id}`);
                return { type: null, name: 'Unknown', amount: 0, tripAmount: 0 };
            }

            return designation;
        },
        // didMemberPayInFull() {
        //     return this.payments.some(
        //         payment => payment.duesperiod === 'full'
        //     );
        // },
        calculateTotalPaymentsForDesignation,
        computePaymentTotal,
        calculateDueAmount,
        isFullyPaid,

    },
    async created() {

        this.memberId = this.$route.params.memberId || this.$store.state.currentMember._id;
        this.self = this.memberId === this.$store.state.currentMember._id;

        const memberId = this.memberId;
        if (memberId) {
            await this.fetchMemberById(memberId);
            await this.fetchDesignations();
            await this.fetchActivePaymentDesignations();
        } else {
            this.error = "No member ID provided";
        }

        // load member's image when component is created
        this.profileImageUrl = await this.getImageUrl();
        this.isLoading = false;

        await this.checkUser();

        this.memberStatus = await this.getMemberStatus(this.member)

    },
};
</script>

<style scoped>
body,
html {
    overflow: hidden;
    height: 100%;
}

#member-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.pay-btn {
    width: 172px;
    height: 52px;
    padding: 0px;
}

.pay-btn-img {
    width: 85px;
    vertical-align: middle;
    height: 30px
}

.profile {
    display: flex;
    justify-content: center;
}

.profile-image-container {
    position: relative;
}

.profile-image-container .profile-image {
    width: 100%;
    max-height: 150px;
    max-width: 150px;
    object-fit: cover;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}


.line-container {
    display: block;
    justify-content: center;
    align-items: center;
}

.select-photo {
    position: absolute;
    top: 130px;
    right: 130px;
    opacity: 0.3;
    height: 40px;
    bottom: 40px;
}

.payment-input {
    margin-left: auto;
    margin-right: auto;
}

.input,
select {
    margin-right: 1px;
}

.enter-amount {
    width: 60px !important;
    text-align: center;
}

.pay-link {
    text-decoration: none;
}

.scrollable-section {
    max-height: 50vh;
    overflow-y: auto;
}

table {
    width: 100%;
}

table td {
    white-space: nowrap;
}

table tbody tr:nth-child(even) {
    background-color: var(--alternate-color);
}

.right-align {
    text-align: right;
    padding-right: 10px;
}

.payment-history {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.payment-history table {
    margin: auto;
}

.payment-history th,
.payment-history td {
    padding: 3px;
    white-space: nowrap;
}

.payment-history .right-align {
    text-align: right;
}

.payment-history .scrollable-section {
    overflow-x: auto;
}

.match-history {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.matches {
    width: 100%;
}

.matches table {
    /* width: 100%; */
    border-collapse: collapse;
    margin: auto;
}

.matches th,
.matches td {
    padding: 3px;
    white-space: nowrap;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.right-align {
    text-align: right;
}

.scrollable-section {
    overflow-x: auto;
}

.pay-button {
    width: 50px !important;
}

.edit-icon {
    cursor: pointer;
    width: 20px;
}

.red-text {
    color: red;
}

.green-text {
    color: green;
}

.orange-text {
    color: #926740;
}

.section-header {
    cursor: pointer;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
}
</style>

