<template>
    <div class="home-page">
        <div class="club-info">
            <h2>{{ club.name }}</h2>
            <img :src="logo" alt="Club Logo" class="club-logo" />
        </div>

        <hr />

        <div class="pinned-headline">
            <h2>
                <img :src="pinIcon" alt="Pin" class="pin-icon">
                {{ club.pinnedHeadline }}
            </h2>
        </div>

        <hr />

        <div class="quick-links">
            <div class="grid">
                <router-link to="/my-profile" class="grid-item">
                    <img :src="profileIcon" alt="Profile" class="grid-icon">
                    <div class="description">My Profile</div>
                </router-link>

                <router-link to="/calendar-page" class="grid-item">
                    <img :src="calendarIcon" alt="Calendar" class="grid-icon">
                    <div class="description">Calendar</div>
                </router-link>

                <router-link to="/make-payment" class="grid-item">
                    <img :src="creditCardIcon" alt="Pay Dues" class="grid-icon">
                    <div class="description">Pay<br />Dues</div>
                </router-link>
            </div>
            <div class="grid">
                <router-link to="/club-composite" class="grid-item">
                    <img :src="compositeIcon" alt="Club Members" class="grid-icon">
                    <div class="description">Club<br />Members</div>
                </router-link>

                <router-link to="/practice-availability" class="grid-item">
                    <img :src="practiceAvailIcon" alt="Practice Availability" class="grid-icon">
                    <div class="description">Practices ✔️</div>
                </router-link>

                <router-link to="/club-info" class="grid-item">
                    <img :src="clubIcon" alt="Matches" class="grid-icon">
                    <div class="description">My Club</div>
                </router-link>

            </div>
        </div>

        <hr />

        <div class="upcoming-events">
            <h3>Upcoming Events:</h3>
            <div v-for="event in weeklyEvents" :key="event.id">
                <p class="eventtitle">{{ event.title }}</p>
                <div class="when-container">
                    <p>{{ getDayOfWeek(event.start) }}, {{ this.$formatDate(event.start, "MM/dd/yyyy") }}</p>
                    <p>{{ this.$formatDate(event.start, "HH:mm") }} - {{ this.$formatDate(event.end, "HH:mm") }}</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { apiGet } from '@/api';
import logo from '@/../images/crest.png';
import compositeIcon from '@/../images/icons/composite.svg';
import calendarIcon from '@/../images/icons/calendar.svg';
import creditCardIcon from '@/../images/icons/credit_card.svg';
import practiceAvailIcon from '@/../images/icons/practice_avail.svg';
import clubIcon from '@/../images/icons/club.svg';
import pinIcon from '@/../images/icons/pin.svg';
import profileIcon from '@/../images/icons/profile.svg';

export default {
    data() {
        return {
            logo: logo,
            club: {},
            compositeIcon,
            calendarIcon,
            profileIcon,
            creditCardIcon,
            practiceAvailIcon,
            clubIcon,
            weeklyEvents: [],
            events: [],
            pinIcon,
        };
    },
    async mounted() {
        await this.getClubInfo();
        const response = await apiGet('calendar/events');
        this.events = response.map(event => {
            return {
                id: event.id,
                title: event.summary,
                start: new Date(event.start.dateTime || event.start.date),
                end: new Date(event.end.dateTime || event.end.date)
            };
        });
        this.updateWeeklyEvents();
    },
    methods: {
        async getClubInfo() {
            const response = await apiGet(`clubs/${this.$store.state.currentClubId}`);
            this.club = response;
        },
        async updateWeeklyEvents() {
            const start = new Date();
            const end = new Date();
            end.setDate(start.getDate() + 7);
            this.weeklyEvents = this.events.filter(event => {
                return event.start >= start && event.start < end;
            });
        },
        getDayOfWeek(dateStr) {
            const date = new Date(dateStr);
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            return days[date.getDay()];
        },
    }
};
</script>

<style scoped>
.club-logo {
    width: 200px;
    opacity: 0.9;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    box-sizing: border-box;
}

.grid-item {
    margin: 5px;
    text-align: center;
}

.grid-icon {
    width: 60px;
    height: auto;
    opacity: 0.8;
}

.eventtitle {
    font-weight: bold;
    margin: 5px 0;
}

.when-container {
    margin: 1px 0;
    padding: 2px 0;
}

.pin-icon {
    opacity: 0.8;
}
</style>
