import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vue3TouchEvents from 'vue3-touch-events'
import { format } from 'date-fns'
// import { utcToZonedTime } from 'date-fns-tz'

const VueGtag = require("vue-gtag");

const app = createApp(App)
app.use(Vue3TouchEvents)


app.mixin({
  methods: {
    notAuthorized(redirectTo) {
      this.$router.push({
        name: 'NotAuthorized',
        params: {
          redirectTo: redirectTo
        }
      });
    }
  }
});

app.use(VueGtag, {
  config: { id: "G-PWDZS0JWQD" }
});

app.config.globalProperties.$formatDate = function (dateStr, formatStr) {
  if (!dateStr) {
    return "";
  }

  // Convert the date string to a Date object
  const dateObj = new Date(dateStr);

  // Get timezone offset in minutes
  const timezoneOffsetMinutes = dateObj.getTimezoneOffset();

  // Convert timezone offset to milliseconds
  const timezoneOffsetMilliseconds = timezoneOffsetMinutes * 60000;

  // Adjust the date for timezone offset
  const time = dateObj.getTime();

  // if (time=="261100800000")
  if (!formatStr.includes("HH"))
  {
    dateObj.setTime(time + timezoneOffsetMilliseconds);
  }
  
  // Format the date
  return format(dateObj, formatStr);
}


app.use(router)
app.use(store)
app.mount('#app')

window.addEventListener('beforeunload', () => {
  // store.dispatch('clearAccessedEndpointsCache');
  store.dispatch('clearCache');
});