<template>
  <div v-if="isLoading">Loading pending users...</div>
  <div v-else>
    <div>
      <h1>Activate Users</h1>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Member Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!users.length">
            <td colspan="3" class="none">
              no pending users
            </td>
          </tr>
          <tr v-for="user in users" :key="user._id">
            <td>
              <img src="../../images/icons/thumb_up.svg" alt="Activate" @click="activateUser(user)"
                style="cursor:pointer; width: 20px;">
              <img src="../../images/icons/thumb_down.svg" alt="Reject" @click="rejectUser(user)"
                style="cursor:pointer; width: 20px;">
            </td>
            <td>{{ displayName(user.member) }}</td>
            <td>{{ user.email }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPut, apiPost, apiGetNoCache } from '@/api';
import store from '@/store';
import { displayName } from '@/common';

export default {
  data() {
    return {
      isLoading: true,
      users: [],
      selectedUser: null,
      dialogTop: 0,
      dialogLeft: 0,
    };
  },
  async created() {
    try {
      const response = await apiGetNoCache('users/pending');
      const pendingUsers = response;

      // get member for each user and merge it with user data
      const usersWithMembers = await Promise.all(pendingUsers.map(async (user) => {
        const member = await this.getMemberByEmail(user.email);
        return { ...user, member };
      }));

      this.users = usersWithMembers;
    } catch (error) {
      console.error(error);
    }
    finally {
      this.isLoading = false;
    }
  },
  methods: {
    displayName,
    async activateUser(user) {
      try {
        // Send the new status in the request body
        await apiPut('users/' + user._id, {
          status: "active",
        });

        await apiPut('members/' + user.member._id, {
          status: "good standing",
          team: user.member.requestedTeam,
        });

        // SENDMAIL
        await apiPost(`mail/user-activated`, { email: user.email });

        this.refreshUsers(); // Refresh users after activating user
      } catch (error) {
        console.error(error);
      }
    },

    async rejectUser(user) {
      try {
        // Send the new status in the request body
        await apiPut('users/' + user._id, {
          status: "rejected",
        });

        this.refreshUsers(); // Refresh users after rejecting user
      } catch (error) {
        console.error(error);
      }
    },


    async getMemberByEmail(email) {
      try {
        const response = await apiGet(`members/email/${email.toLowerCase()}`);
        if (response) {
          return response;
        } else {
          throw new Error(`No member found with email ${email}`);
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    },

    async refreshUsers() {
      try {

        store.commit('clearCacheForEndpoint', `users/pending`);
        const response = await apiGet('users/pending');

        // get member for each user and merge it with user data
        const usersWithMembers = await Promise.all(response.map(async (user) => {
          const member = await this.getMemberByEmail(user.email);
          return { ...user, member };
        }));

        this.users = usersWithMembers;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
table {
  /* width: 100%; */
  text-align: left;
}

.modal {
  position: absolute;
  /* background-color: white; */
  padding: 20px;
  z-index: 1000;
  border: 1px solid lightgrey;
}

.right-align {
  text-align: right;
  padding-right: 10px;
}

table tbody tr:nth-child(even) {
  /* background-color: #f2f2f2; */
}

table td {
  white-space: nowrap;
}

.none {
  text-align: center;
}
</style>
