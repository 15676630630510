<template>
  <div id="login-page">
    <h1>Login</h1>

    <div class="login-form">
      <div class="logo">
        <img :src="logo" alt="Logo" class="logo-image" />
      </div>
      <br />
      <br />
      <input ref="emailInput" class="email-input" type="email" v-model="email" placeholder="Enter your email address"
        @keydown.enter="checkEmail" autocomplete="email" />
      <br />
      <br />
      <button @click="checkEmail">Continue</button>
      <br />
      <div v-if="customMessage" class="custom-message">{{ customMessage }}</div>
    </div>
  </div>
</template>

<script>
import logo from '@/../images/logo-no-background.png';
import { apiPost } from '@/api';

export default {
  data() {
    return {
      email: "",
      member: "",
      customMessage: "",
      // clubs: [],
      selectedClub: process.env.VUE_APP_CLUB_ID,
      logo: logo,
    };
  },
  mounted() {
    if (this.$route.query.customMessage) {
      this.customMessage = this.$route.query.customMessage;
    }
    this.$nextTick(function () {
      this.$refs.emailInput.focus();
    })
  },
  async created() {
    try {
      // const response = await apiGet(`clubs`);
      // this.clubs = response.clubs;
    } catch (error) {
      console.error("Error fetching clubs:", error);
    }
    finally {
      this.isLoading = false;
    }
  },
  methods: {
    async checkEmail() {
      if (!this.validateEmail(this.email)) {
        alert("Invalid email format");
        return;
      }

      try {
        const userData = await apiPost(`auth/check-user`, { email: this.email });
        const memberData = await apiPost(`auth/check-member`, { email: this.email });

        this.member = memberData.memberId;

        if (!userData.exists && !memberData.exists) {
          this.handleNoUserNoMember();
        } else if (userData.exists && userData.status === 'active') {
          this.handleActiveUser();
        } else if (userData.exists && userData.status === 'pending') {
          this.handlePendingUser();
        } else if (userData.exists && userData.status !== 'active' && userData.status !== 'pending') {
          this.handleInactiveUser();
        } else if (!userData.exists && memberData.exists) {
          this.handleMemberNoUser();
        } else {
          throw new Error(`Unexpected user and member existence and status`);
        }
      } catch (error) {
        console.error("Error checking email:", error);
        alert("An error occurred. Please try again.  " + error);
      }
    },

    handleNoUserNoMember() {
      // No user and no member
      this.$router.push({ name: "RegisterMember" });
    },
    handlePendingUser() {
      // User status is pending
      this.customMessage = "Your account is still pending for approval. You will be notified once activated. If you feel this message is in error, please contact us.";
    },
    handleMemberNoUser() {
      console.log("handleMemberNoUser");
      // No user, but member found
      // this.$router.push({ name: "RegisterUser", query: { email: this.email, club: this.selectedClub } });
      this.$router.push({ name: "VerifyEmail", query: { email: this.email, member: this.member, club: this.selectedClub } });
    },
    handleActiveUser() {
      // User and member found
      this.$router.push({ name: "EnterPassword", query: { email: this.email } });
    },
    handleInactiveUser() {
      // User account not active
      this.customMessage = "Your account is not active. Please contact support.";
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>

<style scoped>
.logo-image {
  max-width: 350px;
  height: 195px;
  opacity: 0.8;
}
</style>