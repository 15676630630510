<template>
    <div class="club-info">
        <h1>Club Information</h1>

        <img :src="logo" alt="Club Logo" class="club-logo" />
        <h2>{{ club.name }}</h2>
        <p>{{ club.city }}, {{ club.state }}</p>

        <hr />

        <div class="grid">
            <router-link to="/club-composite" class="grid-item">
                <img :src="compositeIcon" alt="Club Members" class="grid-icon">
                <div class="description">Club<br />Members</div>
            </router-link>

            <router-link to="/calendar-page" class="grid-item">
                <img :src="calendarIcon" alt="Calendar" class="grid-icon">
                <div class="description">Calendar</div>
            </router-link>

        </div>

        <hr />

        <p>{{ club.pitchaddress }}</p>
        <div class="map-container">
            <a :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(club.pitchaddress)}`"
                target="_blank">
                <img :src="`https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(club.pitchaddress)}&zoom=15&size=300x300&key=AIzaSyDegs9xiK4xzQwjQQclyU5z0TdC0dNTlUM`"
                    alt="Map" />
            </a>
        </div>
    </div>
</template>


<script>
// import { apiGet } from "@/api";
import logo from '@/../images/crest.png';
import compositeIcon from '@/../images/icons/composite.svg';
import calendarIcon from '@/../images/icons/calendar.svg';

import profileIcon from '@/../images/icons/profile.svg';

export default {
    data() {
        return {
            logo: logo,
            club: {
                name: "Severn River Rugby Football Club",
                city: "Severna Park",
                state: "Maryland",
                pitchaddress: "832 Manhattan Beach Rd, Severna Park, MD 21146",
            },
            compositeIcon,
            calendarIcon,
            profileIcon,
        }
    },
    // methods: {
    // async fetchClubInfo() {
    // this.club = await apiGet(`clubs/${this.$store.state.currentClubId}`);
    // },
    // },
    // created() {
    //     this.fetchClubInfo();
    // },
};
</script>

<style scoped>


.club-logo {
    width: 200px;
    height: 200px;
}

.map-container {
    margin-top: 20px;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    box-sizing: border-box;
}

.grid-item {
    margin: 5px;
    text-align: center;
}

.grid-icon {
    width: 60px;
    height: auto;
    opacity: 0.8;
}
</style>

