<template>
    <div>
        <div v-if="isLoading">Loading users...</div>
        <div v-else>
            <h1>User Accounts</h1>
            <div v-if="!selectedUser">
                <input type="text" v-model="searchText" placeholder="Search User" @input="searchUser">
                <br />
                <select v-model="filteredRoles" multiple class="roles-select">
                    <option v-for="role in roles" :key="role" :value="role">{{ role }}</option>
                </select>
                <br />
                <button @click="showEmailName">
                    <span v-if="this.showEmail">show name</span>
                    <span v-else>show email</span>
                </button>


                <div class="users-table-container" v-if="filteredUsers.length > 0">
                    <table class="users-table">
                        <thead>
                            <tr class="header">
                                <th v-if="!this.showEmail" @click="sort('lastName')">Name</th>
                                <th v-if="this.showEmail" @click="sort('email')">Email</th>
                                <th @click="sort('status')">Status</th>
                                <th @click="sort('dateRegistered')">Date Registered</th>
                                <th @click="sort('lastLogin')">Last Login</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in filteredUsers" :key="user._id" @click="selectUser(user)" class="user-row">
                                <td v-if="!this.showEmail">{{ shorten(displayName(user.member), 30) }}</td>
                                <td v-if="this.showEmail">{{ shorten(user.email, 30) }}</td>
                                <td>{{ user.status }}</td>
                                <td>{{ this.$formatDate(user.dateRegistered, "MM/dd/yy") }}</td>
                                <td>{{ this.$formatDate(user.lastLogin, "MM/dd/yy") }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-else>
                <h3>{{ displayName(selectedUser.member) }}</h3>
                <hr />
                <div class="center-section">
                    <div class="form-group">
                        <p>{{ selectedUser.email }}
                            <select v-model="selectedUser.status">
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                                <option value="pending">Pending</option>
                                <option value="rejected">Rejected</option>
                            </select>
                        </p>
                    </div>
                    <div class="form-group">
                        <label>Notes:</label>
                        <textarea class="notes-textarea" v-model="selectedUser.notes"></textarea>
                    </div>
                </div>
                <hr />
                <div class="selectRoles">
                    <div v-for="role in roles" :key="role">
                        <input type="checkbox" :value="role" v-model="selectedUser.roles">
                        <label :for="role">{{ role }}</label>
                    </div>
                </div>
                <br />
                <button @click="updateUserProfile">Save</button>
                <!-- <button @click="deleteUserProfile">Delete</button> -->
                <button @click="backToUsers">Back to Users</button>
                <div v-if="message" class="message">{{ message }}</div>
                <br />
                <br />
            </div>

        </div>
    </div>
</template>


<script>
import { apiGet, apiGetNoCache, apiPut, apiDelete } from '@/api';
import { mapGetters } from 'vuex';
import { displayName } from '@/common';

export default {
    data() {
        return {
            filteredRoles: [],

            showEmail: false,
            isLoading: true,
            searchText: "",
            selectedUser: null,
            roles: [
                // "CAN_VIEW_OTHER_MEMBER",
                // "CAN_REGISTER_MEMBER",
                // "CAN_ACTIVATE_USER",
                // "CAN_VIEW_PAYMENTS",
                // "CAN_MANAGE_PAYMENTS",
                // "CAN_MANAGE_MATCHES",
                // "CAN_MANAGE_ROSTERS",
                // "CAN_MANAGE_USERS"
                "VIEW_MEMBERS",
                "VIEW_PAYMENTS",
                "MANAGE_MEMBERS",
                "MANAGE_PAYMENTS",
                "MANAGE_MATCHES",
                "MANAGE_ROSTERS",
                "MANAGE_USERS"
            ],
            users: [],
            // filteredUsers: [],
            message: null,
            sortKey: '',
            sortOrders: {
                lastName: 1,
                email: 1,
                status: 1,
                dateRegistered: 1,
                lastLogin: 1
            },
        };
    },
    async created() {
        try {
            const response = await apiGet('users');
            this.users = response;
            this.filterByRole();
            this.filteredUsers = response;
            if (!this.isAuthorized(['MANAGE_USERS'])) {
                const currentUser = this.users.find(user => user._id === this.currentUserId);
                if (currentUser) {
                    this.selectedUser = { ...currentUser };
                }

            }
            this.isLoading = false;
        } catch (error) {
            console.error("Error fetching data", error);
        }
    },
    methods: {
        filterByRole() {
            if (this.filteredRoles.length > 0) {
                this.filteredUsers = this.users.filter(user =>
                    this.filteredRoles.every(role => user.roles.includes(role))
                );
            }
            else {
                this.filteredUsers = this.users;
            }
        },
        showEmailName() {
            if (this.showEmail) {
                this.showEmail = false;
            }
            else {
                this.showEmail = true;
            }
        },
        searchUser() {
            this.searchText = this.searchText.trim();
        },
        selectUser(user) {
            this.selectedUser = { ...user };
            this.sanitizeUserRoles();
        },
        async deleteUserProfile() {
            try {
                await apiDelete('users/' + this.selectedUser._id);
                this.$store.commit('clearCache');
                this.message = 'User deleted successfully';
                await this.refreshUsers();
            } catch (error) {
                console.error("Error deleting user", error);
            }
        },
        backToUsers() {
            this.selectedUser = null;

        },
        async updateUserProfile() {
            try {
                this.sanitizeUserRoles();
                await apiPut('users/' + this.selectedUser._id, this.selectedUser);
                this.$store.commit('clearCache');
                this.message = 'User updated successfully';
                await this.refreshUsers();
            } catch (error) {
                console.error("Error updating user", error);
            }
        },
        displayName,
        shorten(value, length) {
            if (value.length > length) {
                return value.substring(0, length) + "...";
            } else {
                return value;
            }
        },
        async refreshUsers() {
            try {
                const response = await apiGetNoCache('users');
                this.users = response;
                this.filteredUsers = response;
                if (!this.isAuthorized(['MANAGE_USERS'])) {
                    const currentUser = this.users.find(user => user._id === this.currentUserId);
                    if (currentUser) {
                        this.selectedUser = { ...currentUser };
                    }
                }
            } catch (error) {
                console.error("Error refreshing data", error);
            }
        },
        sort(key) {
            this.sortKey = key;
            this.sortOrders[key] = this.sortOrders[key] * -1;
        },
        sanitizeUserRoles() {
            this.selectedUser.roles = this.selectedUser.roles.filter(role => this.roles.includes(role));
        },

    },
    computed: {
        ...mapGetters(['isAuthorized']),
        currentUserId() {
            return this.$store.state.currentUser._id;
        },
        filteredUsers() {
            // Start with the original list
            let result = [...this.users];

            // Apply search
            if (this.searchText) {
                const searchText = this.searchText.toLowerCase();
                result = result.filter(user => {
                    const member = user.member;
                    return member.firstName.toLowerCase().includes(searchText)
                        || member.lastName.toLowerCase().includes(searchText)
                        || (member.alias && member.alias.toLowerCase().includes(searchText))
                        || (member.nickname && member.nickname.toLowerCase().includes(searchText));
                });
            }

            // Apply role filter
            if (this.filteredRoles.length > 0) {
                result = result.filter(user =>
                    this.filteredRoles.every(role => user.roles.includes(role))
                );
            }

            // Apply sorting
            if (this.sortKey) {
                const order = this.sortOrders[this.sortKey] || 1;
                result.sort((a, b) => {
                    let aValue = this.sortKey === 'lastName' ? a.member[this.sortKey] : a[this.sortKey];
                    let bValue = this.sortKey === 'lastName' ? b.member[this.sortKey] : b[this.sortKey];

                    if (aValue === null && bValue !== null) return order === -1 ? -1 : 1;
                    if (aValue !== null && bValue === null) return order === -1 ? 1 : -1;

                    if (this.sortKey === 'dateRegistered' || this.sortKey === 'lastLogin') {
                        aValue = aValue ? new Date(aValue) : null;
                        bValue = bValue ? new Date(bValue) : null;
                    }

                    return (aValue === bValue ? 0 : aValue > bValue ? 1 : -1) * order;
                });
            }

            return result;
        },
    },





};
</script>
  
<style>
.users-table-container {
    overflow-y: auto;
    max-height: 100%;
    max-width: 100%;
}

.users-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
}

.users-table th,
.users-table td {
    border: 1px solid #ccc;
    padding: 3px;
    text-align: left;
}

.user-row {
    cursor: pointer;
}

.message {
    color: green;
    font-weight: bold;
}

.selectRoles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    width: fit-content;
    margin: 0 auto;
}

.notes-textarea {
    width: 200px;
}

.center-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
}

.form-group label {
    margin-right: 1em;
}

.refresh-icon {
    width: 15px
}

.header {
    cursor: pointer;
}

.roles-select {
    height: 120px;
}
</style>
