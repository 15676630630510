<template>
  <div class="forgotPasswordForm">
    <h1>Request Password Reset</h1>
    <p>
      If you have forgotten your password, you can request a token to reset it.
    </p>
    <hr />
    <h4>Instructions: </h4>
    <ol class="instructions">
      <li>Enter your email in the field below and click 'Submit'.</li>
      <li>Check your email for a message from us. Be sure to check your spam folder too.</li>
      <li>Click the link in the email. This will take you to a page where you can enter a new password.</li>
    </ol>
    <p class="note">
      <strong>Note:</strong> The password reset link is only valid for 1 hour after the email is sent. If it has been
      longer than an hour, you will need to request another password reset.
    </p>
    <hr />
    <form @submit.prevent="submitForm">
      <input placeholder="email address" type="email" id="email" v-model="email" required />
      <button type="submit">Send Email</button>
    </form>
    <p class="info-message" v-if="infoMessage">{{ infoMessage }}</p>
  </div>
</template>


<script>
import { apiPost } from '@/api';

export default {
  data() {
    return {
      email: '',
      infoMessage: null,
    };
  },
  methods: {
    async submitForm() {
      const response = await apiPost(`mail/forgot-password`, { email: this.email });
      if (response.success) {
        this.infoMessage = "Password Reset Email Sent. You can close this page.";
      } 
      else 
      {
        this.infoMessage = "Please try again. If you continue to get this message, please contact support.";
      }
    }
  }
};
</script>

<style>
.forgotPasswordForm {
  max-width: 500px;
  margin: auto;
}

.instructions {
  text-align: left;
}
</style>

  