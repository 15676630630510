<template>
    <div class="navigation-page">
        <div class="section-header">
            <p>{{ displayName(this.$store.state.currentMember, { lastNameFirst: false }) }}</p>
        </div>
        <ul class="list">
            <router-link to="/home-page" class="list-item">
                <img :src="homeIcon" alt="Home" class="list-icon">
                Home
            </router-link>
            <router-link to="/my-profile" class="list-item">
                <img :src="profileIcon" alt="My Profile" class="list-icon">
                My Profile
            </router-link>
        </ul>

        <ul class="list">
            <router-link to="/practice-availability" class="list-item">
                <img :src="practiceAvailIcon" alt="Set Practice Availability" class="list-icon">
                Set Practice Availability ✔️
            </router-link>
            <router-link to="/match-availability" class="list-item">
                <img :src="matchAvailIcon" alt="Set Practice Availability" class="list-icon">
                Set Match Availability ✔️
            </router-link>
        </ul>

        <ul class="list">
            <router-link to="/make-payment" class="list-item">
                <img :src="creditCardIcon" alt="Pay Dues" class="list-icon">
                Pay Dues
            </router-link>
        </ul>

        <div class="section-header">
            <p>Club</p>
        </div>
        <ul class="list">
            <router-link to="/club-info" class="list-item">
                <img :src="clubIcon" alt="Club information" class="list-icon">
                Club information
            </router-link>
            <router-link to="/calendar-page" class="list-item">
                <img :src="calendarIcon" alt="Calendar of Events" class="list-icon">
                Calendar of Events
            </router-link>
            <router-link to="/club-faqs" class="list-item">
                <img :src="faqIcon" alt="Club FAQs" class="list-icon">
                Club FAQs
            </router-link>            
        </ul>
        <ul class="list">
            <router-link to="/club-composite" class="list-item">
                <img :src="compositeIcon" alt="Members" class="list-icon">
                Members
            </router-link>

            <router-link to="" class="list-item">
                <img :src="officersIcon" alt="Officers" class="list-icon">
                Officers (coming soon)
            </router-link>

            <router-link v-if="isAuthorized(['MANAGE_MEMBERS'])" to="/register-member" class="list-item">
                <img :src="personAddIcon" alt="Register member" class="list-icon">
                Add Club Member<img :src="securedIcon" alt="Secured" class="secured-icon">
            </router-link>
        </ul>

        <div class="section-header">
            <p>Matches (your team)</p>
        </div>
        <ul class="list">

            <router-link to="/match-calendar" class="list-item">
                <img :src="calendarIcon" alt="Match Calendar" class="list-icon">
                Match Calendar
            </router-link>

            <router-link to="/match-history" class="list-item">
                <img :src="matchLogsIcon" alt="Match History" class="list-icon">
                Match History
            </router-link>

            <router-link to="/rosters-manager" class="list-item">
                <img :src="rostersManageIcon" alt="Roster Builder" class="list-icon">
                Roster Builder
            </router-link>

            <router-link v-if="isAuthorized(['MANAGE_MATCHES'])" to="/match-manager" class="list-item">
                <img :src="matchManageIcon" alt="Manage Matches" class="list-icon">
                Manage Matches<img :src="securedIcon" alt="Profile" class="secured-icon">
            </router-link>


        </ul>

        <div v-if="isAuthorized(['MANAGE_MATCHES'], false)" class="section-header">
            <p>Attendance (your team)
                <img :src="securedIcon" alt="Secured" class="secured-icon">
            </p>
        </div>
        <ul class="list">

            <router-link v-if="isAuthorized(['MANAGE_MATCHES'], false)" to="/attendance-report" class="list-item">
                <img :src="reportsIcon" alt="Attendance Reports" class="list-icon">
                Who's Coming? 
                <img :src="securedIcon" alt="Secured" class="secured-icon">
            </router-link>

            <router-link v-if="isAuthorized(['MANAGE_MATCHES'], false)" to="/practice-attendance" class="list-item">
                <img :src="attendanceIcon" alt="Practice Attendance" class="list-icon">
                Take Attendence 
                <img :src="securedIcon" alt="Secured" class="secured-icon">
            </router-link>

        </ul>

        <div v-if="isAuthorized(['VIEW_PAYMENTS', 'MANAGE_PAYMENTS'], false)" class="section-header">
            <p>Accounting
                <img :src="securedIcon" alt="Secured" class="secured-icon">
            </p>
        </div>
        <ul class="list">
            <router-link v-if="isAuthorized(['VIEW_PAYMENTS'])" to="/view-payments" class="list-item">
                <img :src="financeIcon" alt="Payment Activity" class="list-icon">
                View Payment Activity<img :src="securedIcon" alt="Profile" class="secured-icon">
            </router-link>

            <router-link v-if="isAuthorized(['MANAGE_PAYMENTS'])" to="/log-payments" class="list-item">
                <img :src="paymentsIcon" alt="Log Payments" class="list-icon">
                Manually Log Payments<img :src="securedIcon" alt="Profile" class="secured-icon">
            </router-link>

            <router-link v-if="isAuthorized(['MANAGE_PAYMENTS'])" to="/payment-designations" class="list-item">
                <img :src="paymentDesignationsIcon" alt="Payment Designations" class="list-icon">
                Fundraisers | Payment Funnels<img :src="securedIcon" alt="Profile" class="secured-icon">
            </router-link>

            <!-- <router-link v-if="isAuthorized(['MANAGE_PAYMENTS'])" to="/payment-sync" class="list-item">
                <img :src="paymentSyncIcon" alt="Payment Sync" class="list-icon">
                Sync Payment Data<img :src="securedIcon" alt="Profile" class="secured-icon">
            </router-link> -->
        </ul>

        <div class="section-header">
            <p>Scrum App Admin</p>
        </div>
        <ul class="list">
            <router-link v-if="isAuthorized(['MANAGE_USERS'])" to="/user-profiles" class="list-item">
                <img :src="userProfilesIcon" alt="User profiles" class="list-icon">
                Manage Accounts<img :src="securedIcon" alt="Secured" class="secured-icon">
            </router-link>
            <router-link v-if="isAuthorized(['MANAGE_USERS'])" to="/activate-users" class="list-item">
                <img :src="thumbsIcon" alt="Activate users" class="list-icon">
                Activate Pending Accounts<img :src="securedIcon" alt="Secured" class="secured-icon">
            </router-link>
        </ul>

        <div class="section-header">
            <p>Settings</p>
        </div>

        <ul class="list">
            <div @click="clearCache" class="list-item">
                <img :src="refreshIcon" alt="Refresh" class="list-item">
                Refresh Data
            </div>
            <div @click="toggleDarkMode">
                <div v-if="this.darkMode" class="list-item">
                    <img :src="lightModeIcon" alt="Light Mode" class="list-item">
                    Light Mode
                </div>
                <div v-else class="list-item">
                    <img :src="darkModeIcon" alt="Dark Mode" class="list-item">
                    Dark Mode
                </div>
            </div>
        </ul>
        <ul class="list">
            <router-link to="/reset-password" class="list-item">
                <img :src="passwordResetIcon" alt="Reset Password" class="list-icon">
                Reset Password
            </router-link>
            <div @click="logout" class="list-item">
                <img :src="logoutIcon" alt="Log Out" class="list-icon">
                Log Out
            </div>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import homeIcon from '@/../images/icons/home.svg';

import clubIcon from '@/../images/icons/club.svg';
import faqIcon from '@/../images/icons/faq.svg';
import compositeIcon from '@/../images/icons/composite.svg';
import calendarIcon from '@/../images/icons/calendar.svg';
import passwordResetIcon from '@/../images/icons/password_reset.svg';

import profileIcon from '@/../images/icons/profile.svg';
import practiceAvailIcon from '@/../images/icons/practice_avail.svg';
import matchAvailIcon from '@/../images/icons/match_avail.svg';
import creditCardIcon from '@/../images/icons/credit_card.svg';

import matchLogsIcon from '@/../images/icons/match_history.svg';
import matchRostersIcon from '@/../images/icons/rosters.svg';

import allMembersIcon from '@/../images/icons/allmembers.svg';
import personAddIcon from '@/../images/icons/person_add.svg';
import thumbsIcon from '@/../images/icons/thumbs.svg';
import userProfilesIcon from '@/../images/icons/user-profiles.svg';
import officersIcon from '@/../images/icons/officers.svg';

import financeIcon from '@/../images/icons/finance.svg';
import paymentsIcon from '@/../images/icons/payments.svg';
import paymentDesignationsIcon from '@/../images/icons/payment_designations.svg';
import paymentSyncIcon from '@/../images/icons/payment_sync.svg';


import matchManageIcon from '@/../images/icons/matches.svg';
import rostersManageIcon from '@/../images/icons/rosters.svg';

import logoutIcon from '@/../images/icons/logout.svg';

import securedIcon from '@/../images/icons/secured.svg';

import attendanceIcon from '@/../images/icons/attendance.svg';
import reportsIcon from '@/../images/icons/reports.svg';

import refreshIcon from '@/../images/icons/refresh.svg';
import darkModeIcon from '@/../images/icons/dark_mode.svg';
import lightModeIcon from '@/../images/icons/light_mode.svg';

import { displayName } from '@/common';

export default {
    data() {
        return {
            homeIcon,

            clubIcon,
            faqIcon,
            compositeIcon,
            calendarIcon,
            passwordResetIcon,

            profileIcon,
            practiceAvailIcon,
            matchAvailIcon,
            creditCardIcon,

            matchLogsIcon,
            matchRostersIcon,

            allMembersIcon,
            personAddIcon,
            thumbsIcon,
            userProfilesIcon,
            officersIcon,

            financeIcon,
            paymentsIcon,
            paymentDesignationsIcon,
            paymentSyncIcon,

            matchManageIcon,
            rostersManageIcon,

            attendanceIcon,
            reportsIcon,

            logoutIcon,
            securedIcon,
            darkModeIcon,
            lightModeIcon,

            refreshIcon,
        };
    },
    computed: {
        ...mapGetters(['darkMode', 'isAuthenticated', 'isAuthorized'])
    },
    methods: {
        toggleDarkMode() {
            this.$store.dispatch('toggleDarkMode');
        },
        displayName,
        clearCache() {
            this.$store.commit('clearCache');
            if (alert('reset successful')) {
                this.$router.push('/');
                this.$emit('close-menu');
            }
        },
        logout() {
            if (window.confirm("Are you sure you want to log out?")) {
                this.$router.push('/login-page');
            }
        },
    }
};
</script>

<style scoped>
.navigation-page {
    position: relative;
    /* width: 100%;
    height: 100%;
    max-width:360px;
    margin-left:376px; */
}

.list {
    list-style-type: none;
}

.list-item {
    display: flex;
    align-items: center;
    /* margin: 5px 0; */
    text-decoration: none;
    font-size: 18px;
    /* color: #2c3e50; */
    /* font-weight: bold; */
    cursor: pointer;
}

.list-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.section-header {
    /* display: flex; */
    /* margin-left: 5px; */
    /* font-size: 16px; */
    /* -webkit-margin-before: 1em; */
    /* margin-block-start: 1em; */
    /* -webkit-margin-after: 1em; */
    /* margin-block-end: 1em; */
    /* -webkit-margin-start: 0px; */
    /* margin-inline-start: 0px; */
    /* -webkit-margin-end: 0px; */
    /* margin-inline-end: 0px; */
    font-weight: bold;
    text-align: left;
    margin-left: 15px;
}

.secured-icon {
    opacity: 0.8;
    width: 15px;
}
</style>