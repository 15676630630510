<template>
  <div id="app">

    <div class="site-container" @touchstart="onTouchStart" @touchend="onTouchEnd">
      <header class="app-header">

        <div v-if="isAuthenticated && isMobile" class="header-home-container"
          @click.stop="showNavigation = !showNavigation">
          <img :src="showNavigation ? closeIcon : menuIcon" class="header-home-img" alt="home" />
        </div>

        <div v-if="isAuthenticated" class="header-logo-container">
          <img :src="headerLogo" class="header-logo-img" alt="Logo" />
        </div>
      </header>

      <div v-if="isLoading">Loading...</div>
      <div class="content-container">
        <div v-if="isAuthenticated && (isDesktop || (isMobile && showNavigation))" class="nav">
          <NavigationPage class="navigation-page" @close-menu="closeNavigation" />
        </div>
        <div class="content">
          <div v-if="!showNavigation">
            <router-view :key="$route.fullPath" />
          </div>
        </div>
      </div>

      <footer v-if="isAuthenticated && !showNavigation && !isLoading" class="app-footer">
        <div class="footer-product">
          <img :src="footerLogo" class="footer-logo-img" alt="Logo" />
          <div class="footer-slogan">
            Rugby Club Management Platform
          </div>
        </div>

        <div class="footer-links">
          <a href="mailto:support@scrumapp.club">Contact Us</a>
          •
          <a :href="`${webUrl}/terms-conditions`" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
          •
          <a :href="`${webUrl}/privacy-policy`" target="_blank" rel="noopener noreferrer">Privacy Policy</a>

        </div>

        <div class="footer-copyright">
          © 2023 Scrum App. All rights reserved.
        </div>
      </footer>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import NavigationPage from './components/NavigationPage.vue';

import homeIcon from '../images/icons/home.svg';
import menuIcon from '../images/icons/menu.svg';
import closeIcon from '../images/icons/close.svg';

import clubIcon from '../images/icons/club.svg';
import financeIcon from '../images/icons/finance.svg';
import profileIcon from '../images/icons/profile.svg';
import compositeIcon from '../images/icons/composite.svg';
import calendarIcon from '../images/icons/calendar.svg';
import creditCardIcon from '../images/icons/credit_card.svg';
import personAddIcon from '../images/icons/person_add.svg';
import thumbsIcon from '../images/icons/thumbs.svg';
import paymentsIcon from '../images/icons/payments.svg';
import allMembersIcon from '../images/icons/allmembers.svg';
import logoutIcon from '../images/icons/logout.svg';
import headerLogo from '@/../images/logo-header-no-background.png';

import footerLogo from '@/../images/logo-no-background.png';

export default {
  name: 'App',
  data() {
    return {
      isLoading: true,
      homeIcon: homeIcon,
      menuIcon: menuIcon,
      closeIcon: closeIcon,
      financeIcon: financeIcon,
      compositeIcon: compositeIcon,
      profileIcon: profileIcon,
      calendarIcon: calendarIcon,
      creditCardIcon: creditCardIcon,
      personAddIcon: personAddIcon,
      thumbsIcon: thumbsIcon,
      paymentsIcon: paymentsIcon,
      allMembersIcon: allMembersIcon,
      logoutIcon: logoutIcon,
      headerLogo: headerLogo,
      footerLogo: footerLogo,
      clubIcon: clubIcon,
      showNavigation: false,
      startX: 0,
      endX: 0,
      routesOrder: [
        "/home-page",
        "/my-profile",
        "/practice-availability",
        "/match-availability",
        "/make-payment",
        "/club-info",
        "/calendar-page",
        "/club-composite",
        // "/register-member",
        // "/user-profiles",
        // "/activate-users",
        "/match-calendar",
        "/match-history",
        // "/match-manager",
        // "/rosters-manager",
        // "/view-payments",
        // "/log-payments",
        // "/payment-designations"
      ],
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isAuthorized']),
    currentClub() {
      return this.$store.getters.currentClub;
    },
    isMobile() {
      // adjust this value according to your needs
      return window.innerWidth <= 768;
    },
    isDesktop() {
      return !this.isMobile;
    },
    webUrl() {
      return process.env.VUE_APP_WEB_URL;
    },
    darkMode() {
      return this.$store.getters.darkMode;
    },
  },
  watch: {
    // Watch for changes in the route
    $route: {
      immediate: true,
      handler() {
        this.isLoading = false;
        this.closeNavigation();
      },
    },
    darkMode(newVal) {
      if (newVal) {
        document.documentElement.classList.add('dark-theme');
      } else {
        document.documentElement.classList.remove('dark-theme');
      }
    },
  },
  methods: {
    logout() {
      if (window.confirm("Are you sure you want to log out?")) {
        this.$store.dispatch('logout');
        this.$router.push(`/login-page`);
      }
    },
    updateCSSVariables() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    closeNavigation() {
      this.showNavigation = false;
    },
    closeNavigationIfOpen() {
      if (this.showNavigation) {
        this.showNavigation = false;
      }
    },
    goToNextPage() {

      let currentRouteIndex = this.routesOrder.indexOf(this.$route.path);
      if (currentRouteIndex === -1) return;

      let nextRouteIndex = currentRouteIndex;

      if (this.isLastAuthorizedPage(this.routesOrder, currentRouteIndex)) {
        this.$router.push("/home-page");
        return;
      }
      do {
        nextRouteIndex = (nextRouteIndex + 1) % this.routesOrder.length;
        if (this.isAuthorizedForRoute(this.routesOrder[nextRouteIndex])) {
          this.$router.push(this.routesOrder[nextRouteIndex]);
          return;
        }
      }
      while (nextRouteIndex != currentRouteIndex);
    },
    goToPrevPage() {

      let currentRouteIndex = this.routesOrder.indexOf(this.$route.path);
      if (currentRouteIndex === -1) return;

      let prevRouteIndex = currentRouteIndex;

      do {
        prevRouteIndex = (prevRouteIndex - 1 + this.routesOrder.length) % this.routesOrder.length;
        if (this.isAuthorizedForRoute(this.routesOrder[prevRouteIndex])) {
          this.$router.push(this.routesOrder[prevRouteIndex]);
          return;
        }
      } while (prevRouteIndex != currentRouteIndex);

      // If we've looped through all pages and none were authorized, return to home page
      this.$router.push("/home-page");
    },

    isLastAuthorizedPage(startIndex) {
      let index = (startIndex + 1) % this.routesOrder.length;
      while (index != startIndex) {
        if (this.isAuthorizedForRoute(this.routesOrder[index])) {
          return false;
        }
        index = (index + 1) % this.routesOrder.length;
      }
      return true;
    },

    isAuthorizedForRoute(route) {
      switch (route) {
        case "/register-member":
          return this.isAuthorized(["MANAGE_MEMBERS"]);
        case "/user-profiles":
          return this.isAuthorized(["MANAGE_USERS"]);
        case "/activate-users":
          return this.isAuthorized(["MANAGE_USERS"]);
        case "/match-manager":
          return this.isAuthorized(["MANAGE_MATCHES"]);
        // case "/rosters-manager":
        //   return this.isAuthorized(["MANAGE_ROSTERS"]);
        case "/view-payments":
          return this.isAuthorized(["MANAGE_PAYMENTS", "VIEW_PAYMENTS"]);
        case "/log-payments":
          return this.isAuthorized(["MANAGE_PAYMENTS"]);
        case "/payment-designations":
          return this.isAuthorized(["MANAGE_PAYMENTS"]);
        default:
          return true;
      }
    },


    onTouchStart(event) {
      if (this.isAuthenticated && !this.isDesktop && !this.showNavigation) {
        this.startX = event.changedTouches[0].clientX;
        this.startY = event.changedTouches[0].clientY;
        this.endX = this.startX;  // Initialize endX here to ensure it's always reset to the start position
      }
    },
    onTouchEnd(event) {
      if (this.isAuthenticated && !this.isDesktop && !this.showNavigation) {
        this.endX = event.changedTouches[0].clientX;  // Update endX here to get the final position
        this.endY = event.changedTouches[0].clientY;  // Update endY here to get the final vertical position

        const deltaX = this.startX - this.endX;
        const deltaY = this.startY - this.endY;

        // If absolute vertical movement is larger than a threshold, bail out
        if (Math.abs(deltaY) > 100) {
          return;
        }

        if (deltaX > 100) {
          this.goToNextPage();
        } else if (deltaX < -100) {
          this.goToPrevPage();
        }

        // Reset startX, startY, endX, endY after swipe is detected
        this.startX = 0;
        this.startY = 0;
        this.endX = 0;
        this.endY = 0;
      }
    },



  },
  mounted() {
    this.updateCSSVariables();

    window.addEventListener('resize', this.updateCSSVariables);

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      this.$store.dispatch('toggleDarkMode', e.matches);
    });

    if (this.darkMode) {
      document.documentElement.classList.add('dark-theme');
    }
    else {
      document.documentElement.classList.remove('dark-theme');
    }

  },
  components: {
    NavigationPage
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateCSSVariables);
  },

};
</script>

<style>
* {
  border-radius: 7px;
  border-width: 1px;
  vertical-align: middle;
}

hr {
    border: none; 
    height: 1px; 
    background-color: #333; 
}

:root {
  --header-height: 60px;
  --background-color-buttons: #5c9a7e2e;
  --background-color-content: #F0F8FF;
  --background-color-header: #F0F8FF;
  --background-color-footer: #F0F8FF;
  --background-color-navigation: #18ad6014;
  --link-color: #2c3e50;
}

.dark-theme {
  --background-color-content: #141618;
  --background-color-header: #141618;
  --background-color-footer: #141618;
  --link-color: #6e7d8d;
  --color: #6e7d8d;
  --alternate-color: #232323;
  --fc-page-bg-color: #141618;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background-color: var(--background-color-content);
  color: var(--color);

}

a {
  text-decoration: none;
  font-size: 14px;
  color: var(--link-color);
  ;
}

input:not([type='checkbox']):not([type='radio']),
textarea {
  height: 30px;
  width: 200px;
  background-color: var(--background-color-content);
  color: var(--color);

}

select,
button {
  height: 35px;
  width: 205px;
  cursor: pointer;
  color: var(--color);
  background-color: var(--background-color-content);

}

button {
  background-color: var(--background-color-buttons);

}

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.app-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--header-height);
  background-color: var(--background-color-header);
  position: sticky;
  top: 0;
  z-index: 1000;
  border-radius: 0px;
}

.header-home-container {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

}

.header-home-img {
  width: 35px;
  height: auto;
  opacity: 0.8;
}

.header-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-logo-img {
  max-width: 250px;
  height: auto;
  padding: 0px;
}

.site-container {
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  display: flex;
  flex-direction: column;
}

.app-footer {
  font-size: 12px;
  background-color: var(--background-color-footer);
  bottom: 0;
  width: 100%;
  padding-top: 19px;
  padding-bottom: 19px;
}

.content {
  min-height: 500px;
}

.footer-links a {
  font-size: 12px
}

.footer-logo-img {
  width: 300px
}

/* Desktop view */
@media (min-width: 769px) {
  .content-container {
    flex-direction: row;
  }

  .content {
    flex-grow: 1;
  }

  .nav {
    width: 100%;
    max-width: 360px;
    min-width: 360px;
    background-color: var(--background-color-navigation);
  }

}

/* Mobile view */
@media (max-width: 768px) {
  .content-container {
    flex-direction: row;
  }

  .content {
    flex-grow: 1;
  }

  .nav {
    position: sticky;
    width: 100%;
    max-width: 360px;
    min-width: 360px;
    background-color: var(--background-color-navigation);
  }

}

</style>
