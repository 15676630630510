<template>
    <div>
        <div v-if="isLoading">Loading events...</div>
        <div v-else>
            <!-- Modal Component -->
            <div v-if="showModal" class="modal-overlay" @click="closeModal">
                <div class="modal" @click.stop>
                    <h2>{{ modalTitle }}</h2>
                    <p>{{ modalContent }}</p>
                    <button @click="closeModal">Close</button>
                </div>
            </div>
            <h1 v-if="this.eventType === 'match'">Match Schedule (your team)</h1>
            <h3 v-if="this.attendance">(please set your availability)</h3>

            <hr />

            <div class="calendar-container" v-if="showCalendar">
                <FullCalendar :options="calendarOptions" :class="{ 'no-scroll': showCalendar }" />
            </div>

            <p v-if="filteredEvents.length === 0">No events this month</p>
            <div v-for="event in filteredEvents" :key="event.id">
                <p class="eventtitle">{{ event.summary }}</p>
                <div class="when-container">
                    <p v-if="event.start.date">{{ getDayOfWeek(event.start.date) }}, {{ event.start.date }}</p>
                    <p v-if="event.start.dateTime">{{ getDayOfWeek(event.start.dateTime) }},
                        {{ this.$formatDate(event.start.dateTime, "MM/dd/yy") }}
                    </p>
                    <p v-if="event.start.dateTime">{{ this.$formatDate(event.start.dateTime, "HH:mm") }} - {{
                        this.$formatDate(event.end.dateTime, "HH:mm") }}
                    </p>

                    <p v-if="event.location">
                        <a :href="'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(event.location)"
                            target="_blank" rel="noopener">
                            {{ event.location }}
                        </a>
                    </p>
                </div>
                <div v-if="this.attendance" class="avail-container">
                    <img class="availIcon" alt="unavailable" :src="unavailableIcon"
                        :style="{ opacity: getOpacity(event.attendance, 'unavailable') }"
                        @click="setAttendance(event.id, 'unavailable')">
                    <img class="availIcon" alt="unlikely" :src="unlikelyIcon"
                        :style="{ opacity: getOpacity(event.attendance, 'unlikely') }"
                        @click="setAttendance(event.id, 'unlikely')">
                    <img class="availIcon" alt="likely" :src="likelyIcon"
                        :style="{ opacity: getOpacity(event.attendance, 'likely') }"
                        @click="setAttendance(event.id, 'likely')">
                    <img class="availIcon" alt="going" :src="goingIcon"
                        :style="{ opacity: getOpacity(event.attendance, 'going') }"
                        @click="setAttendance(event.id, 'going')">

                    <p :class="attendanceClass(event.attendance)">{{ event.attendance }}</p>
                </div>
                <hr />
            </div>
        </div>
    </div>
</template>

<script>
import { apiGet, apiPost } from '@/api';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import store from '@/store';

import unavailableIcon from '@/../images/icons/unavailable.svg';
import unlikelyIcon from '@/../images/icons/unlikely.svg';
import likelyIcon from '@/../images/icons/likely.svg';
import goingIcon from '@/../images/icons/going.svg';

export default {
    props: {
        eventType: {
            type: String,
            default: ''
        },
        attendance: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: true,
            events: [],
            teams: [],
            selectedTeam: '',
            calendarOptions: {
                plugins: [dayGridPlugin],
                initialView: 'dayGridMonth',
                titleFormat: { year: 'numeric', month: 'short' },
                events: [],
                height: 'auto',
                datesSet: this.handleDatesSet,
                eventClick: this.handleEventClick,
            },
            filteredEvents: [],
            unavailableIcon,
            unlikelyIcon,
            likelyIcon,
            goingIcon,
            showCalendar: true,
            currentViewDates: {},
            showModal: false,
            modalTitle: '',
            modalContent: '',
        };
    },
    async mounted() {
        this.isLoading = true;
        await this.fetchTeams();
        await this.fetchEvents();
        this.isLoading = false;
    },
    methods: {
        handleEventClick(info) {
            this.modalTitle = info.event.title;
            this.modalContent = 'Start: ' + info.event.start.toLocaleString();
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        async fetchTeams() {
            const teamsResponse = await apiGet('teams');
            this.teams = teamsResponse.teams;
        },

        async fetchEvents() {
            try {
                const memberId = store.state.currentMember._id;

                // Fetch events
                let events = await apiGet('calendar/events');

                // Clear cache for event attendances
                store.commit('clearCacheForEndpoint', `eventAttendances/member/${memberId}`);

                // Fetch attendance data for the current member
                let attendances = await apiGet(`eventAttendances/member/${memberId}`);  // Ensure correct cache key

                // Map attendance data to events
                this.events = events.map(event => {
                    const attendanceRecord = attendances.find(attendance => attendance.event === event.id && attendance.member === memberId);
                    return {
                        ...event,
                        attendance: attendanceRecord ? attendanceRecord.state : ''
                    };
                });

                this.filterEvents(); // Apply initial filtering after fetching events
            } catch (error) {
                console.error('Failed to fetch events or attendances:', error);
            }
        },

        handleDatesSet(info) {
            // Update the current visible month's start and end dates
            this.currentViewDates = {
                start: new Date(info.startStr),
                end: new Date(info.endStr)
            };
            this.filterEvents(); // Filter events when the calendar view changes
        },

        filterEvents() {
            if (!this.currentViewDates.start || !this.currentViewDates.end) return;

            const currentTeamId = this.$store.state.currentMember.team._id; // Get the current team ID from the store

            const filterKey = 'match';

            this.filteredEvents = this.events.filter(event => {
                const eventDate = new Date(event.start.dateTime || event.start.date);
                const isFiltered = event.summary.toLowerCase().includes(filterKey) ||
                    (event.description && event.description.toLowerCase().includes(filterKey));
                return event.team === currentTeamId && // Filter based on the current team
                    isFiltered && // Filter events that include filterKey
                    eventDate >= this.currentViewDates.start &&
                    eventDate < this.currentViewDates.end;
            });

            // Update the calendar display with filtered events
            this.calendarOptions.events = this.filteredEvents.map(event => ({
                id: event.id,
                title: event.summary,
                start: event.start.dateTime || event.start.date,
                end: event.end.dateTime || event.end.date
            }));
        },

        async setAttendance(eventId, state) {
            const memberId = store.state.currentMember._id;
            const body = {
                event: eventId,
                member: memberId,
                state: state
            };
            await apiPost(`eventAttendances`, body);
            let event = this.filteredEvents.find(event => event.id === eventId);
            if (event) {
                event.attendance = state;
            }

            // Update the store's cache directly without re-fetching
            const cacheKey = `eventAttendances/member/${memberId}`;
            let updatedAttendances = store.state.cache[cacheKey] || [];
            const existingAttendanceIndex = updatedAttendances.findIndex(a => a.event === eventId && a.member === memberId);

            if (existingAttendanceIndex !== -1) {
                updatedAttendances[existingAttendanceIndex].state = state;  // Update the existing attendance
            } else {
                updatedAttendances.push({ event: eventId, member: memberId, state: state });  // Add new attendance
            }

            store.commit('setCache', { key: cacheKey, value: updatedAttendances });
        },

        getDayOfWeek(dateStr) {
            const date = new Date(this.$formatDate(dateStr, "MM/dd/yyyy"));
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            return days[date.getDay()];
        },

        getOpacity(attendance, state) {
            return attendance === state ? 1 : 0.2;
        },

        attendanceClass(state) {
            return {
                'going': state === 'going',
                'likely': state === 'likely',
                'unlikely': state === 'unlikely',
                'unavailable': state === 'unavailable'
            };
        }
    },
    components: {
        FullCalendar
    }
};
</script>


<style>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    z-index: 1001;
}

.modal h2 {
    margin-top: 0;
}

button {
    margin-top: 10px;
    cursor: pointer;
}

.when-container>* {
    margin: 1px 0;
    padding: 2px 0;
    font-size: 16px;
}

.avail-container>* {
    margin: 1px 0;
    padding: 0px;
    font-size: 18px;
    cursor: pointer;
}

.calendar-container {
    padding: 5%;
}

.calendar-container button {
    width: 60px;
    height: 100%;
}

.availIcon {
    width: 40px;
}

.eventtitle {
    font-size: 20px;
    font-weight: bold;
    margin: 5px 0;
}

hr {
    margin: 5px 0;
}

.fc-daygrid-day-frame {
    background-color: var(--alternate-color);

}

.show-hide-calendar {
    cursor: pointer;
}
</style>