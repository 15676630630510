<template>
    <div class="unauthorized">
        <h1>Unauthorized</h1>
        <h3>You are not authorized to view this content.</h3>
    </div>
</template>
  
<script>
export default {
    name: 'NotAuthorized',
    props: ['redirectTo'],
    mounted() {
        setTimeout(() => {
            this.$router.push(this.redirectTo);
        }, 5000);
    },
};
</script>
  
<style scoped>
.unauthorized {
    text-align: center;
    margin-top: 2rem;
}
</style>
  