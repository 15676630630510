<template>
    <div>
        <h1>Match Results (your team)</h1>
        <div>
            <table>
                <tbody>
                    <tr v-for="(match, index) in matches" :key="index" @click="logMatchId(match._id)">
                        <td>{{ this.$formatDate(match.date, "MM/dd/yyyy") }}</td>
                        <td>{{ match.opponent }}</td>
                        <td>{{ match.result }}</td>
                        <td>{{ match.ourScore }}</td>
                        <td>{{ match.opponentScore }}</td>
                    </tr>
                    <tr v-if="matches.length <= 0">
                        <td colspan="5">no match history found</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { apiGet } from '@/api';

export default {
    data() {
        return {
            matches: [],
        };
    },
    methods: {
        logMatchId(id) {
            console.log(id);
        },
    },
    async mounted() {
        this.matches = await apiGet('matches');
        this.matches = []; // TODO: reseting temporarily until we create good match data
    },
};
</script>

<style scoped></style>

 
<style scoped>
.matches {
    text-align: center;
    display: inline-block;
}

.match-table {
    max-width: 200px;
    text-align: center;
}

.match-table table,
th,
td {
    border: 1px solid #11112a;
    border-collapse: collapse;
    padding: 10px;
    border-radius: 0px;

}
</style>