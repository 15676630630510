<template>
  <div id="verify-account-page">
    <h1>Verify Account</h1>
    <div class="logo">
      <img :src="logo" class="logo-image" />
    </div>
    <div class="verification-form">
      <p v-if="!isValidToken">Invalid or expired token. Please check your email or contact support.</p>
      <div v-else>
        <p>Please enter a new password for your account.</p>
        <div class="password-container">
          <input
            ref="passwordInput"
            :type="passwordVisible ? 'text' : 'password'"
            v-model="password"
            placeholder="Enter password"
            @keydown.enter="verifyAndSave"
          />
          <img
            :src="showPasswordImage"
            class="show-password-toggle"
            @click="passwordVisible = !passwordVisible"
          />
        </div>
        <br />
        <div class="password-container">
          <input
            :type="passwordVisible ? 'text' : 'password'"
            v-model="confirmPassword"
            placeholder="Confirm password"
            @keydown.enter="verifyAndSave"
          />
        </div>
        <br />
        <button @click="verifyAndSave">Save</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
      </div>
      <br />
      <button @click="goToLoginPage">Back To Sign In</button>
    </div>
  </div>
</template>

<script>
import { apiPost } from '@/api';
import logo from '@/../images/crest.png';
import showPasswordImage from '@/../images/icons/show.svg';

export default {
  data() {
    return {
      token: this.$route.params.token,
      memberId: this.$route.params.memberId,
      eamil: "",
      logo: logo,
      password: "",
      confirmPassword: "",
      passwordVisible: false,
      showPasswordImage: showPasswordImage,
      errorMessage: "",
      successMessage: "",
      isValidToken: false
    };
  },
  async mounted() {
    await this.verifyToken();
  },
  methods: {
    async showMessage(message, messageType = "info") {
      if (messageType === "success") {
        this.successMessage = message;
        this.infoMessage = "";
        this.errorMessage = "";
      } else if (messageType === "info") {
        this.successMessage = "";
        this.infoMessage = message;
        this.errorMessage = "";
      } else if (messageType === "error") {
        this.successMessage = "";
        this.infoMessage = "";
        this.errorMessage = message;
      }
    },
    async verifyToken() {
      try {
        const checkMatch = await apiPost('auth/check-member-verification-token', {
          member: this.memberId,
          token: this.token
        });
        this.isValidToken = checkMatch.match;

        if (this.isValidToken)
        {
          this.email = checkMatch.email;
        }

        if (!this.isValidToken) {
          this.showMessage("Invalid or expired token.", "error");
        }
      } catch (error) {
        this.isValidToken = false;
        this.showMessage("An error occurred while verifying the token.", "error");
      }
    },
    async verifyAndSave() {
      if (!this.password || !this.confirmPassword) {
        this.showMessage("Both password fields are required.", "error");
        return;
      }

      if (this.password !== this.confirmPassword) {
        this.showMessage("Passwords do not match.", "error");
        return;
      }

      try {


        const response = await apiPost('auth/register-user', {
          memberId: this.memberId,
          password: this.password,
          email: this.email,
          verified: true
        });

        if (response.success) {
          this.showMessage("Password set successfully. Redirecting to login...", "success");
          setTimeout(() => {
            this.$router.push('/login-page');
          }, 2000);
        } else {
          this.showMessage("Failed to set password. Please try again.", "error");
        }
      } catch (error) {
        this.showMessage("An error occurred. Please try again.", "error");
      }
    },
    goToLoginPage() {
      this.$router.push('/login-page');
    }
  }
};
</script>

<style>
.logo-image {
  max-width: 200px;
  height: auto;
}

.verification-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.show-password-toggle {
  cursor: pointer;
  height: 20px;
  margin-left: -30px;
  vertical-align: middle;
}

.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}

.success-message {
  color: green;
  text-align: center;
  margin-bottom: 20px;
}
</style>
