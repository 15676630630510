<template>
    <div>
        <div v-if="isLoading">Loading attedance reports...</div>
        <div v-else>
            <h1>Attendance Report (your team)</h1>
            <div>
                <div class="filter-container">
                    <select v-model="selectedType" required>
                        <option value="match">matches</option>
                        <option value="practice">practices</option>
                    </select>
                    <select v-if="selectedType == 'practice'" v-model="selectedPractice" required>
                        <option value="">select a practice</option>
                        <option v-for="practice in practices" :key="practice.id" :value="practice">
                            {{ this.$formatDate(practice.start.dateTime, "MM/dd/yyyy") ||
                                this.$formatDate(practice.start.date, "MM/dd/yyyy") }} -
                            {{ practice.summary }}
                        </option>
                    </select>
                    <select v-if="selectedType == 'match'" v-model="selectedMatch" required>
                        <option value="">select a match</option>
                        <option v-for="match in matches" :key="match.id" :value="match">
                            {{ this.$formatDate(match.start.dateTime, "MM/dd/yyyy") ||
                                this.$formatDate(match.start.date, "MM/dd/yyyy") }} -
                            {{ match.summary }}
                        </option>
                    </select>
                    <select v-model="selectedState" required>
                        <option value="">select attendance</option>
                        <option value="going">going</option>
                        <option value="likely">likely</option>
                        <option value="unlikely">unlikely</option>
                        <option value="unavailable">unavailable</option>
                    </select>


                </div>
                <div class="attendences-container" v-if="attendances.length > 0">
                    <table class="users-table">
                        <thead>
                            <tr class="header">
                                <th>Date</th>
                                <th>Event</th>
                                <th>Member</th>
                                <th>Attendance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="attendance in filteredAttendances" :key="attendance._id" class="attendance-row">
                                <td>{{ this.$formatDate(attendance.event.start.dateTime, "MM/dd/yyyy") ||
                                    this.$formatDate(attendance.event.start.date, "MM/dd/yyyy") }}</td>
                                <td>{{ attendance.event.summary }}</td>
                                <td>{{ formattedName(attendance.member) }}</td>
                                <td>{{ attendance.state }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
import { apiGet } from '@/api';
import { displayName } from '@/common';

export default {
    data() {
        return {

            attendances: [],
            members: [],
            teams: [],
            matches: [],
            practices: [],


            // filters
            selectedType: "match",
            selectedTeam: this.$store.state.currentMember.team,
            selectedState: "",
            selectedMatch: "",
            selectedPractice: ""



        };
    },
    methods: {
        displayName,
        formattedName(player) {
            return this.displayName(player, { lastNameFirst: false, showNickname: true, showAlias: false });
        },

        async fetchTeams() {
            const res = await apiGet('teams');
            this.teams = res.teams;
        },

        async fetchMembers() {
            this.members = await apiGet('members');
        },

        async fetchEvents() {
            let allEvents = await apiGet('calendar/events');

            this.events = allEvents.sort((a, b) => {
                const dateA = new Date(a.start.dateTime || a.start.date);
                const dateB = new Date(b.start.dateTime || b.start.date);

                if (isNaN(dateA)) {
                    return -1;
                }

                if (isNaN(dateB)) {
                    return 1;
                }

                return dateA - dateB;
            });
        },

        async fetchAttendances() {
    const res = await apiGet('eventAttendances');
    this.attendances = res.map(attendance => {
        const memberObject = this.members.find(member => member._id === attendance.member);
        const eventObject = this.events.find(event => event.id === attendance.event);

        if (!memberObject) {
            console.warn(`Member not found for attendance record: ${attendance._id}`);
            return null; // Skip this attendance record
        }

        if (!eventObject) {
            console.warn(`Event not found for attendance record: ${attendance._id}`);
            return null; // Skip this attendance record
        }

        return {
            ...attendance,
            member: memberObject,
            event: eventObject,
        };
    }).filter(attendance => attendance !== null); // filter out any null results
},

        filterEvents() {
            // this.matches = this.events.filter(event => event.type === "match");
            // this.practices = this.events.filter(event => event.type === "practice");

            // this.matches = this.events.filter(event => event.summary.toLowerCase().includes("match"));
            // this.practices = this.events.filter(event => event.summary.toLowerCase().includes("practice"));

            const attendedEventIds = this.attendances.map(a => a.event.id);

            this.matches = this.events.filter(event =>
                event.summary.toLowerCase().includes("match") &&
                attendedEventIds.includes(event.id)
            );
            this.practices = this.events.filter(event =>
                event.summary.toLowerCase().includes("practice") &&
                attendedEventIds.includes(event.id)
            );
        },


    },
    async created() {
        await this.fetchEvents();
        await this.fetchTeams();
        await this.fetchMembers();
        await this.fetchAttendances();
        this.filterEvents();

    },
    computed: {
        filteredAttendances() {
            return this.attendances.filter(attendance => {
                let isMatched = true;

                // Type filter
                if (this.selectedType) {
                    isMatched = isMatched && attendance.event.summary.toLowerCase().includes(this.selectedType);
                }

                // Team filter
                if (this.selectedTeam) {
                    isMatched = isMatched && attendance.member.team._id === this.selectedTeam._id;
                }

                // State filter
                if (this.selectedState) {
                    isMatched = isMatched && attendance.state === this.selectedState;
                }

                // Match filter
                if (this.selectedMatch) {
                    isMatched = isMatched && attendance.event.id === this.selectedMatch.id;
                }

                // Practice filter
                if (this.selectedPractice) {
                    isMatched = isMatched && attendance.event.id === this.selectedPractice.id;
                }

                return isMatched;
            });
        },
    },
};
</script>
