<template>
    <div>
        <h1>Coming Soon</h1>
        <p>
            This feature has not yet been fully implemented in the Scrum App.
        </p>
        <p>
            This is just a placeholder for some of the great things the Scrum App can provide to the club.
        </p>
        <p>
            Keep an eye out for release announcements and for new placeholders.
        </p>
    </div>
</template>