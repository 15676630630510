import axios from 'axios';
import store from './store';

const apiBaseUrl = process.env.VUE_APP_API_URL;

export async function apiGet(endpoint, params = {}) {
  const authToken = store.state.authToken;
  const clubId = store.state.currentClubId;

  if (store.state.cache[endpoint]) {
    return store.state.cache[endpoint];
  }

  const response = await axios.get(`${apiBaseUrl}/api/${endpoint}`, {
    headers: {
      'x-auth-token': authToken,
      'x-club-id': clubId
    },
    params: params
  });

  store.commit('setCache', { key: endpoint, value: response.data }); 
  // store.commit('addAccessedEndpoint', endpoint); 

  return response.data;
}

export async function apiGetNoCache(endpoint, params = {}) {
  const authToken = store.state.authToken;
  const clubId = store.state.currentClubId;

  const response = await axios.get(`${apiBaseUrl}/api/${endpoint}`, {
    headers: {
      'x-auth-token': authToken,
      'x-club-id': clubId
    },
    params: params
  });

  store.commit('setCache', { key: endpoint, value: response.data }); 
  return response.data;
}


export async function apiPost(endpoint, body) {
  const authToken = store.state.authToken;
  const clubId = store.state.currentClubId;

  const response = await axios.post(`${apiBaseUrl}/api/${endpoint}`, body, {
    headers: {
      'x-auth-token': authToken,
      'x-club-id': clubId
    },
  });
  return response.data;
}

export async function apiPostFile(endpoint, formData) {
  const authToken = store.state.authToken;
  const clubId = store.state.currentClubId;

  const response = await axios.post(`${apiBaseUrl}/api/${endpoint}`, formData, {
    headers: {
      'x-auth-token': authToken,
      'x-club-id': clubId,
      'Content-Type': 'multipart/form-data'
    },
  });

  return response.data;
}

export async function apiPut(endpoint, body) {
  const authToken = store.state.authToken;
  const clubId = store.state.currentClubId;

  const response = await axios.put(`${apiBaseUrl}/api/${endpoint}`, body, {
    headers: {
      'x-auth-token': authToken,
      'x-club-id': clubId
    },
  });
  return response.data;
}

export async function apiDelete(endpoint) {
  const authToken = store.state.authToken;
  const clubId = store.state.currentClubId;

  const response = await axios.delete(`${apiBaseUrl}/api/${endpoint}`, {
    headers: {
      'x-auth-token': authToken,
      'x-club-id': clubId
    },
  });
  return response.data;
}

export async function apiPatch(endpoint, data) {
  const response = await axios.patch(`${apiBaseUrl}/api/${endpoint}`, data);
  return response.data;
}
